import React, { useContext } from "react";
import { Tooltip, ButtonBase } from "@material-ui/core";
import GlobalContext from "../../../../hooks/GlobalContext";
import MeaningIcons from "./MeaningIcons";

export default function(props) {
    const { id, name, iconId } = props;
    const { pagingState } = useContext(GlobalContext);
    const {
        selectedPagingPersonId,
        selectedMeaningButtonId,
        setSelectedMeaningButtonId
    } = pagingState;
    const isSelected = selectedMeaningButtonId === id;

    return (
        <Tooltip title={name}>
            <ButtonBase
                onClick={() => {
                    if (id === selectedMeaningButtonId) {
                        setSelectedMeaningButtonId(null);
                    } else {
                        if (selectedPagingPersonId) {
                            setSelectedMeaningButtonId(id);
                        }
                    }
                }}
                size="small"
                style={{
                    backgroundColor: "#FFFFFF",
                    height: 40,
                    maxWidth: 150,
                    overflow: "hidden",
                    borderRadius: 10,
                    marginLeft: 5,
                    padding: 5,
                    border: isSelected
                        ? "2px solid black"
                        : "2px solid #DDE6EA"
                }}
            >
                <MeaningIcons iconId={iconId}/>
                <p style={{
                    margin: '0',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }}>{name}</p>
            </ButtonBase>
        </Tooltip>
    );
}
