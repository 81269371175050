import { createContext, useState, useRef } from "react";
import firebase from "../../../../../firebase/firebase";

export const docContext = createContext({
    data: null,
    subscribe: () => {},
    unsubscribe: () => {}
});

export const useDocListener = () => {
    const [data, setData] = useState(null);
    const [listenError, setListenError] = useState(null);
    const [loading, setLoading] = useState(false);
    const unsubscribeRef = useRef(null);

    const subscribe = path => {
        console.log(`subscribing to ${path}`);
        setLoading(true);
        setListenError(null);
        unsubscribe();
        unsubscribeRef.current = firebase
            .firestore()
            .doc(path)
            .onSnapshot(
                doc => {
                    setData(doc.data());
                    setLoading(false);
                },
                err => {
                    setLoading(false);
                    console.error(err);
                    setListenError(err);
                }
            );
    };

    const unsubscribe = () => {
        setData(null);
        unsubscribeRef.current && unsubscribeRef.current();
    };

    return { data, subscribe, unsubscribe, listenError, loading, setLoading };
};
