import { createMemoryHistory } from "history";

export default createMemoryHistory({
    initialEntries: [
        "/conversationList",
        "conversation",
        "createConversation",
        "nothing"
    ],
    initialIndex: 0
});
