import { createContext, useState, useRef } from "react";
import firebase from "../firebase";

export const devicesDocContext = createContext({
    devices: {},
    subscribe: () => {},
    unsubscribe: () => {}
});

export const useDevicesDoc = () => {
    const [devices, setDevices] = useState({});
    const [listenError, setListenError] = useState(null);
    const [initializing, setInitializing] = useState(true);
    const unsubscribeRef = useRef(null);

    const subscribe = officeId => {
        setListenError(null);
        unsubscribe();
        setDevices({})
        unsubscribeRef.current = firebase
            .firestore()
            .doc(`/offices/${officeId}/admin/devices/`)
            .onSnapshot(
                doc => {
                    const data = doc.data();
                    console.log('data devices onSnapshot', doc.data())
                    // setDevices(data.devices)
                    data && data.devices
                        ? setDevices(data.devices)
                        : setDevices({});
                },
                err => {
                    setListenError(err);
                }
            );
    };

    const unsubscribe = () => {
        unsubscribeRef.current && unsubscribeRef.current();
    };

    return {
        devices,
        subscribe,
        unsubscribe,
        listenError,
        initializing,
        setInitializing
    };
};
