import React, { useContext, useEffect, useState } from 'react'
import { isEmpty } from 'ramda';

import { makeStyles } from '@material-ui/core/styles';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import { IconButton } from '@material-ui/core';

import GlobalContext from "../../../hooks/GlobalContext";
import PagingEventHandler from "./PagingEventHandler";
import Persons from "./Persons/Persons";
import Meaning from "./Meaning/Meaning";
import Rooms from "./Rooms/Rooms";
import useMessaging from "../Messaging/useMessaging";

import getPatientTracking from "../../../firebase/httpsCallable/getPatientTracking";
import usePatientTracking from '../Tracker/usePatientTracking';

import flag from '../../../assets/flag.png'
import map2 from '../../../assets/map2.png'

const useStyles = makeStyles({
    root: {
        //   backgroundColor: 'red',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    boxTitle: {
        backgroundColor: '#9dcd2a',
        height: 50,
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        fontWeight: 'bold',
        fontSize: 18,
    },
    btnClose: {
        position: 'absolute',
        right: 10,
    },
    table: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        backgroundColor: 'blue',
    },
    boxTitle1: {
        display: 'flex',
        width: '98%',
        alignItems: 'center',
        marginTop: 20,
        marginBottom: 10,
    },
    text: {
        fontWeight: 600,
        fontSize: 22,
        margin: 0,
        padding: 0,
        // marginLeft: 20, 
        color: '#3A3E4B'
    },
    box: {
        width: '100%',
        minHeight: '50%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // backgroundColor: 'green'
    },
    boxMiniButtons: {
        width: '98%',
        height: 80,
        backgroundColor: '#F3F5FA',
        borderRadius: 10,
    },
    boxDisabled: {
        position: 'absolute',
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        height: '100%',
        width: '100%',
        zIndex: 9999
    },
    boxNot: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '1%',
        '& p': {
            margin: 0,
            padding: 0,
            fontWeight: 'bold',
            color: '#3A3E4B',
            fontSize: 19,
            textAlign: 'center'
        },
    },
});

export default () => {
    const {
        officeList,
        localStore,
        navigation,
        viewHeader,
        pagingDoc,
        pagingState,
        mainCtx
    } = useContext(GlobalContext);
    const { officeId } = mainCtx;
    const usersData = useMessaging();
    const dataTracking = usePatientTracking();
    const { selectedPagingPersonId, setStatePaging } = pagingState;
    // const persons = pagingDoc.persons;
    const persons = usersData.usersNewPaging;
    const rooms = dataTracking.roomsPaging;
    // const rooms = pagingDoc.rooms;
    const classes = useStyles();

    const [selected, setSelected] = useState({})

    // PagingEventHandler(pagingDoc);


    useEffect(() => {
        officeList.getOffices();
        viewHeader.setViewHeader(false)
        // getPatientTracking(officeId)
    }, [])

    const closePaging = () => {
        /* viewHeader.setViewHeader(true)
        navigation.toMainAppAdmin() */
        setStatePaging(false);
        viewHeader.setViewHeader(true)
        navigation.toHome()
    }

    return (
        <div className={classes.root}>
            <div className={classes.boxTitle}>
                PAGING
                <IconButton className={classes.btnClose} onClick={closePaging}>
                    <HighlightOffRoundedIcon style={{ color: 'white' }} />
                </IconButton>
            </div>
            {!isEmpty(persons) ? <>
                <Persons persons={persons} setSelected={setSelected} />
                <div className={classes.box} style={{ opacity: !selectedPagingPersonId ? '0.5' : 1, pointerEvents: !selectedPagingPersonId ? 'none' : '' }}>
                    {/* {!selectedPagingPersonId && (
                    <div className={classes.boxDisabled}/>
                )} */}
                    <div className={classes.boxTitle1}>
                        <img src={flag} style={{ width: 76, height: 76 }} />
                        <p className={classes.text}>Do you want to add a meaning to the page?</p>
                    </div>
                    <Meaning />
                    <div className={classes.boxTitle1}>
                        <img src={map2} style={{ width: 76, height: 76 }} />
                        <p className={classes.text}>Which room do you want to page to?</p>
                    </div>
                    <Rooms rooms={rooms} closePaging={closePaging} />
                    <div style={{ height: 30 }}></div>
                </div>
            </>
                :
                <div className={classes.boxNot}>
                    <p className='p-sub'> You do not have users created in the paging module in the portal</p>
                </div>
            }
        </div>
    )
}
