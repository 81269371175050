import React, { useState } from "react";
import firebase from "../../../firebase/firebase";
import {
    TextField,
    Button,
    Card,
    CardContent,
    Grid,
    CardHeader,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogActions,
    FormControl,
    Tabs, Tab, makeStyles, Box
} from "@material-ui/core";

import signInCode from "../../../firebase/httpsCallable/signInCode";
import { isEmpty } from "ramda";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

const useStyles = makeStyles({
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        margin: 0,
        wordBreak: 'break-all',
        fontFamily: 'SF Pro Display',
    },
    customTabs: {
        '& .MuiTab-root': {
            textTransform: 'none',
        },
        '& .MuiTabs-indicator': {
            height: 0,
            backgroundColor: 'transparent',
        }
    },
    customTab: {
        minWidth: 'fit-content',
        marginLeft: 20,
        marginRight: 20,
        borderRadius: 25,
        minHeight: 26,
        paddingTop: 0,
        paddingBottom: 0,
        // fontFamily: 'SF Pro Display',
    }
});

export default function (props) {

    const classes = useStyles();
    const [email, setEmail] = useState("");
    const [code, setCode] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function submitForm(e) {
        console.log("submitting");
        e.preventDefault();
        setLoading(true);
        firebase
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then(result => {
                setLoading(false);
            })
            .catch(err => {
                setError(err.message);
                setLoading(false);
            });
    }

    function handleSignInCode(e) {
        e.preventDefault();
        setLoading(true);
        signInCode({
            accessCode: code
        })
            .then((result) => {

                if (result && result.data && result.data.email && !isEmpty(result.data.email)) {
                    firebase
                        .auth()
                        .signInWithEmailAndPassword(result.data.email, 'a29kaWEqMTIzNDU2Nzgj')
                        .then(result => {
                            setLoading(false);
                        })
                        .catch(err => {
                            setError(err.message);
                            setLoading(false);
                        });
                } else {
                    setError(result.data.message);
                    setLoading(false);
                }
            })
            .catch(err => {
                setError(err.message);
                setLoading(false);
            });
    }

    return (
        <Grid
            container
            alignItems="center"
            alignContent="center"
            justifyContent="center"
            spacing={2}
            style={{ marginTop: "10px" }}
        >
            <Grid item xs={12} md={6} lg={6}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        className={classes.customTabs}
                    >
                        <Tab
                            value={0}
                            label="Sign In"
                            className={classes.customTab}
                            style={{
                                background: value === 0 ? '#197FE8' : '#F8F9FB',
                                color: value === 0 ? 'white' : '#979797'
                            }}
                        />
                        <Tab
                            value={1}
                            label="Sign In With Code"
                            className={classes.customTab}
                            style={{
                                background: value === 1 ? '#197FE8' : '#F8F9FB',
                                color: value === 1 ? 'white' : '#979797'
                            }}
                        />
                    </Tabs>
                </div>
                <Card align="center">
                    <TabPanel value={value} index={0}>
                        <CardHeader title="Sign In" />
                        <CardContent>
                            <form onSubmit={submitForm}>
                                <Grid
                                    container
                                    justifyContent="center"
                                    direction="column"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <Grid item>
                                        <FormControl>
                                            <TextField
                                                type="text"
                                                value={email}
                                                onChange={e => {
                                                    setEmail(e.target.value);
                                                }}
                                                label="email"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item>
                                        <FormControl>
                                            <TextField
                                                type="password"
                                                value={password}
                                                onChange={e => {
                                                    setPassword(e.target.value);
                                                }}
                                                label="password"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item>
                                        <FormControl>
                                            <Button
                                                size="small"
                                                variant="contained"
                                                type="submit"
                                            >
                                                Sign In
                                            </Button>
                                        </FormControl>
                                        {loading && <CircularProgress size={10} />}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            size="small"
                                            onClick={() => {
                                                setLoading(true);
                                                firebase
                                                    .auth()
                                                    .sendPasswordResetEmail(email)
                                                    .then(result => {
                                                        setLoading(false);
                                                        alert(
                                                            "password reset sent"
                                                        );
                                                    })
                                                    .catch(err => {
                                                        setError(err.message);
                                                        setLoading(false);
                                                    });
                                            }}
                                        >
                                            Forgot Password
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </CardContent>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <CardHeader title="Sign In with code" />
                        <CardContent>
                            <form onSubmit={handleSignInCode}>
                                <Grid
                                    container
                                    justifyContent="center"
                                    direction="column"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <Grid item>
                                        <FormControl>
                                            <TextField
                                                type="text"
                                                value={code}
                                                onChange={e => {
                                                    setCode(e.target.value);
                                                }}
                                                label="access code"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item>
                                        <FormControl>
                                            <Button
                                                size="small"
                                                variant="contained"
                                                type="submit"
                                            >
                                                Sign In
                                            </Button>
                                        </FormControl>
                                        {loading && <CircularProgress size={10} />}
                                    </Grid>
                                </Grid>
                            </form>
                        </CardContent>
                    </TabPanel>
                    <Dialog
                        open={Boolean(error)}
                        onClose={() => {
                            setError(null);
                        }}
                    >
                        <DialogContent>{error}</DialogContent>
                        <DialogActions>
                            <Button
                                size="small"
                                variant="contained"
                                onClick={() => {
                                    setError(null);
                                }}
                            >
                                ok
                            </Button>
                        </DialogActions>
                    </Dialog>

                </Card>
            </Grid>
        </Grid>

    );
}
