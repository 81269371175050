import React, { useState, useContext } from 'react';
import moment from "moment";
// import { isEmpty, isNil, view } from 'ramda';
// import { format, parseISO } from 'date-fns';

import GlobalContext from "../../../../../hooks/GlobalContext";
import PatientTrackingContext from "../../PatientTrackingContext";
import { VisitContext } from "../../VisitContext";

export default function (props) {
    const { textComponent, style } = props;
    // ----------------------------------------------------------
    const { mainCtx } = useContext(GlobalContext);
    const { officeId } = mainCtx;
    const { patientTrackingDoc } = useContext(PatientTrackingContext);
    const visit = useContext(VisitContext);

    const countFromTime = (() => {
        return patientTrackingDoc.visitSettings &&
            patientTrackingDoc.visitSettings.countFrom &&
            patientTrackingDoc.visitSettings.countFrom === "appointmentTime"
            ? visit.appointmentTime
            : visit.arrivalTime;
    })();

    function nSecToTime(s) {
        let seconds = s
        s = Math.abs(s)

        let t = [0, 0, 0]
        let r = s % 3600

        t[0] = Math.floor(s / 3600)
        t[1] = Math.floor(r / 60)
        r = r % 60
        t[2] = r

        // return (seconds < 0 ? "-" : "") + (t[0] < 10 ? "0" : "") + t[0]+":"+(t[1]<10?"0"+t[1]:t[1])+":"+(t[2]<10?"0"+t[2]:t[2])
        return (seconds < 0 ? "-" : "") + (t[0] === 0 ? "" : t[0] < 10 ? "0" : "") + (t[0] === 0 ? "" : t[0]) + (t[0] === 0 ? "" : ":") + (t[1] < 10 ? "0" + t[1] : t[1]) + ":" + (t[2] < 10 ? "0" + t[2] : t[2])
    }

    const timeString = Math.floor(
        (Date.now() - parseInt(countFromTime)) / 60000
    ).toString();
    const minutesRemainingString = Math.floor(
        (parseInt(visit.dueOutTime) - Date.now()) / 60000
    );
    // ----------------------------------------------------------
    var now = moment(new Date());//now
    // ----------------------------------------------------------
    // var elapsed = moment(new Date(parseInt(countFromTime)))
    // var elapsed2 = moment(now, "DD/MM/YYYY HH:mm:ss").diff(moment(elapsed, "DD/MM/YYYY HH:mm:ss"));
    // var elapsed3 = moment.duration(elapsed2);
    // var minutesElapsed = Math.floor(elapsed3.asHours()) === 0 ?  moment.utc(elapsed2).format(":mm") : Math.floor(elapsed3.asHours()) + moment.utc(elapsed2).format(":mm");
    var minutesElapsed = nSecToTime(timeString);
    // ----------------------------------------------------------
    // var remaining = moment(new Date(parseInt(visit.dueOutTime)))
    // var remaining2 = moment(remaining, "DD/MM/YYYY HH:mm:ss").diff(moment(now, "DD/MM/YYYY HH:mm:ss"));
    // var remaining3 = moment.duration(remaining2);
    // var minutesRemaining = Math.floor(remaining3.asHours()) === 0 ? moment.utc(remaining2).format(":mm") : Math.floor(remaining3.asHours()) + moment.utc(remaining2).format(":mm");
    var minutesRemaining = nSecToTime(minutesRemainingString)

    return (
        <React.Fragment>
            <div style={style}>
                {textComponent === "TimeElapsed" ? minutesElapsed : minutesRemaining}
            </div>
        </React.Fragment>
    );
}
