const rootFreq = 440;

export default {
    ding: [
        { duration: 1, rootFrequency: rootFreq / 1, frequencyRatio: 1 },
        { duration: 1, rootFrequency: rootFreq / 1, frequencyRatio: 3 / 2 },
        { duration: 1, rootFrequency: rootFreq / 1, frequencyRatio: 2 },
        { duration: 1, rootFrequency: rootFreq / 1, frequencyRatio: 3 / 2 },
        { duration: 1, rootFrequency: rootFreq / 1, frequencyRatio: 1 },
        { duration: 1, rootFrequency: rootFreq / 1, frequencyRatio: 3 / 2 },
        { duration: 1, rootFrequency: rootFreq / 1, frequencyRatio: 2 },
        { duration: 1, rootFrequency: rootFreq / 1, frequencyRatio: 3 / 2 }
    ],
    c1: [
        { duration: 1, rootFrequency: rootFreq / 4, frequencyRatio: 1 },
        { duration: 1, rootFrequency: rootFreq / 4, frequencyRatio: 1 },
        { duration: 1, rootFrequency: rootFreq / 4, frequencyRatio: 3 / 2 },
        { duration: 1, rootFrequency: rootFreq / 4, frequencyRatio: 1 },
        { duration: 1, rootFrequency: rootFreq / 4, frequencyRatio: 8 / 5 },
        { duration: 1, rootFrequency: rootFreq / 4, frequencyRatio: 1 },
        { duration: 1, rootFrequency: rootFreq / 4, frequencyRatio: 3 / 2 },
        { duration: 1, rootFrequency: rootFreq / 4, frequencyRatio: 1 }
    ],

    z1: [
        { duration: 1, rootFrequency: rootFreq / 2, frequencyRatio: 9 / 5 },
        { duration: 1, rootFrequency: rootFreq / 2, frequencyRatio: 5 / 3 },
        { duration: 1, rootFrequency: rootFreq / 2, frequencyRatio: 36 / 25 },
        { duration: 1, rootFrequency: rootFreq / 2, frequencyRatio: 1 },
        { duration: 1, rootFrequency: rootFreq / 2, frequencyRatio: 24 / 25 },
        { duration: 1, rootFrequency: rootFreq / 2, frequencyRatio: 3 / 2 },
        { duration: 1, rootFrequency: rootFreq / 2, frequencyRatio: 15 / 8 },
        { duration: 1, rootFrequency: rootFreq / 2, frequencyRatio: 12 / 5 }
    ],
    m1: [
        { duration: 1, rootFrequency: 671, frequencyRatio: 1 },
        { duration: 1, rootFrequency: 671, frequencyRatio: 6 / 5 },
        { duration: 1, rootFrequency: 671, frequencyRatio: 2 },
        { duration: 1, rootFrequency: 671, frequencyRatio: 8 / 5 },
        { duration: 1, rootFrequency: 671, frequencyRatio: 9 / 5 },
        { duration: 1, rootFrequency: 671, frequencyRatio: 12 / 5 }
        // { duration: 1, rootFrequency: rootFreq , frequencyRatio:   },
    ],
    m2: [
        { duration: 2 / 3, rootFrequency: 671, frequencyRatio: 2 },
        { duration: 2 / 3, rootFrequency: 671, frequencyRatio: 9 / 5 },
        { duration: 2 / 3, rootFrequency: 671, frequencyRatio: 2 },
        { duration: 2, rootFrequency: 671, frequencyRatio: 9 / 5 }
    ],
    ns1: [
        { duration: 2, rootFrequency: rootFreq, frequencyRatio: 1 },
        { duration: 2, rootFrequency: rootFreq, frequencyRatio: 3 / 2 },
        { duration: 1, rootFrequency: rootFreq, frequencyRatio: 3 / 2 },
        { duration: 1, rootFrequency: rootFreq, frequencyRatio: 4 / 3 },
        { duration: 1, rootFrequency: rootFreq, frequencyRatio: 5 / 4 },
        { duration: 1, rootFrequency: rootFreq, frequencyRatio: 12 / 11 },
        { duration: 2, rootFrequency: rootFreq, frequencyRatio: 1 }
    ],

    ns2: [
        { duration: 4, rootFrequency: rootFreq / 2, frequencyRatio: 1 },
        { duration: 4, rootFrequency: rootFreq / 2, frequencyRatio: 6 / 5 },
        { duration: 4, rootFrequency: rootFreq / 2, frequencyRatio: 3 / 2 },
        { duration: 4, rootFrequency: rootFreq / 2, frequencyRatio: 2 }
    ],
    ns2A: [
        { duration: 4, rootFrequency: rootFreq, frequencyRatio: 1 },
        { duration: 4, rootFrequency: rootFreq, frequencyRatio: 6 / 5 },
        { duration: 4 / 3, rootFrequency: rootFreq, frequencyRatio: 3 / 2 },
        { duration: 4 / 3, rootFrequency: rootFreq, frequencyRatio: 2 },
        {
            duration: 4 / 3,
            rootFrequency: rootFreq * 2,
            frequencyRatio: 6 / 5
        },
        { duration: 4, rootFrequency: rootFreq * 2, frequencyRatio: 1 }
    ],
    ns3: [
        { duration: 4, rootFrequency: rootFreq, frequencyRatio: 1 },
        { duration: 4, rootFrequency: rootFreq, frequencyRatio: 2 },
        { duration: 4, rootFrequency: rootFreq, frequencyRatio: 3 },
        { duration: 4, rootFrequency: rootFreq, frequencyRatio: 4 }
    ],
    ns4: [
        { duration: 1, rootFrequency: rootFreq, frequencyRatio: 1 },
        { duration: 1, rootFrequency: rootFreq, frequencyRatio: 3 / 2 },
        { duration: 1, rootFrequency: rootFreq, frequencyRatio: 2 },
        { duration: 1, rootFrequency: rootFreq, frequencyRatio: 1 }
    ],
    ns5: [
        { duration: 2 / 3, rootFrequency: rootFreq, frequencyRatio: 1 },
        { duration: 2 / 3, rootFrequency: rootFreq, frequencyRatio: 16 / 15 },
        { duration: 2 / 3, rootFrequency: rootFreq, frequencyRatio: 9 / 8 },
        { duration: 1, rootFrequency: rootFreq, frequencyRatio: 6 / 5 }
    ],
    ns6: [
        { duration: 1, rootFrequency: rootFreq, frequencyRatio: 1 },
        { duration: 1, rootFrequency: rootFreq, frequencyRatio: 2 },
        { duration: 2, rootFrequency: rootFreq, frequencyRatio: 3 / 2 }
    ],
    ns7: [
        { duration: 1, rootFrequency: rootFreq, frequencyRatio: 3 / 2 },
        { duration: 1, rootFrequency: rootFreq, frequencyRatio: 4 / 3 },
        { duration: 2, rootFrequency: rootFreq, frequencyRatio: 1 }
    ],
    ns8: [
        { duration: 1, rootFrequency: rootFreq, frequencyRatio: 1 },
        { duration: 1, rootFrequency: rootFreq, frequencyRatio: 16 / 15 },
        { duration: 1, rootFrequency: rootFreq, frequencyRatio: 2 },
        { duration: 1, rootFrequency: rootFreq, frequencyRatio: 3 / 2 }
    ],
    ns9: [
        { duration: 1, rootFrequency: rootFreq, frequencyRatio: 3 / 2 },
        { duration: 1, rootFrequency: rootFreq, frequencyRatio: 8 / 5 },
        { duration: 1, rootFrequency: rootFreq, frequencyRatio: 6 / 5 },
        { duration: 1, rootFrequency: rootFreq, frequencyRatio: 9 / 5 }
    ],
    ns10: [
        { duration: 1, rootFrequency: rootFreq, frequencyRatio: 2 },
        { duration: 1, rootFrequency: rootFreq, frequencyRatio: 4 / 3 },
        { duration: 1, rootFrequency: rootFreq, frequencyRatio: 8 / 5 },
        { duration: 1, rootFrequency: rootFreq, frequencyRatio: 2 },
        { duration: 1, rootFrequency: rootFreq, frequencyRatio: 18 / 8 }
    ],
    ns11: [
        { duration: 1, rootFrequency: rootFreq, frequencyRatio: 2 },
        { duration: 1, rootFrequency: rootFreq, frequencyRatio: 4 / 3 },
        { duration: 1, rootFrequency: rootFreq, frequencyRatio: 5 / 3 },
        { duration: 1, rootFrequency: rootFreq, frequencyRatio: 2 },
        { duration: 1, rootFrequency: rootFreq, frequencyRatio: 18 / 8 }
    ],
    ns12: [
        { duration: 1, rootFrequency: rootFreq, frequencyRatio: 1 },
        { duration: 1, rootFrequency: rootFreq, frequencyRatio: 9 / 8 },
        { duration: 1, rootFrequency: rootFreq, frequencyRatio: 5 / 4 },
        { duration: 1, rootFrequency: rootFreq, frequencyRatio: 4 / 3 },
        { duration: 1, rootFrequency: rootFreq, frequencyRatio: 3 / 2 },
        { duration: 1, rootFrequency: rootFreq, frequencyRatio: 5 / 3 }
        // { duration: 2, rootFrequency: rootFreq, frequencyRatio: 8 / 3 }
    ],
    ns13: [
        { duration: 2, rootFrequency: rootFreq, frequencyRatio: 1 },
        { duration: 2, rootFrequency: rootFreq, frequencyRatio: 9 / 8 },
        { duration: 1, rootFrequency: rootFreq, frequencyRatio: 5 / 4 },
        { duration: 1, rootFrequency: rootFreq, frequencyRatio: 3 / 2 }
        // { duration: 1, rootFrequency: rootFreq, frequencyRatio: 2 }
    ],
    ns14: [
        { duration: 2, rootFrequency: rootFreq, frequencyRatio: 1 },
        { duration: 2, rootFrequency: rootFreq, frequencyRatio: 9 / 8 },
        { duration: 2, rootFrequency: rootFreq, frequencyRatio: 5 / 4 },
        { duration: 2, rootFrequency: rootFreq, frequencyRatio: 9 / 8 }
    ],
    ns15: [
        { duration: 1, rootFrequency: rootFreq, frequencyRatio: 1 },
        { duration: 1, rootFrequency: rootFreq, frequencyRatio: 9 / 8 },
        { duration: 1, rootFrequency: rootFreq, frequencyRatio: 1 },
        { duration: 1, rootFrequency: rootFreq, frequencyRatio: 5 / 4 },
        { duration: 1, rootFrequency: rootFreq, frequencyRatio: 1 },
        { duration: 1, rootFrequency: rootFreq, frequencyRatio: 9 / 8 },
        { duration: 1, rootFrequency: rootFreq, frequencyRatio: 15 / 16 }
    ],
    ns16: [
        { duration: 2, rootFrequency: rootFreq, frequencyRatio: 3 / 4 },
        { duration: 2, rootFrequency: rootFreq, frequencyRatio: 1 },
        { duration: 2, rootFrequency: rootFreq, frequencyRatio: 5 / 4 },
        { duration: 2, rootFrequency: rootFreq, frequencyRatio: 3 / 2 }
    ],
    arp1: [
        { duration: 1, rootFrequency: rootFreq, frequencyRatio: 1 },
        { duration: 1, rootFrequency: rootFreq, frequencyRatio: 5 / 4 },
        { duration: 1, rootFrequency: rootFreq, frequencyRatio: 3 / 2 },
        { duration: 1, rootFrequency: rootFreq, frequencyRatio: 2 }
    ]
};
