import React, { useContext, useEffect, useRef } from "react";
import { playSoundEffect } from "../../../../../audio/audio";
import soundEffects from "../../../../../audio/soundEffects";
import GlobalContext from "../../../../../hooks/GlobalContext";

export default function(pagingDoc) {
    const { persons, events, loading } = pagingDoc;
    const { localStore } = useContext(GlobalContext);
    const eventIds = useRef([]);
    const latestEventTime = useRef(Date.now());
    /// This can be cleaned up and made less able to produce bugs

    useEffect(() => {
        const incomingEventIds = Object.keys(pagingDoc.events);

        if (loading) {
            eventIds.current = incomingEventIds;
            latestEventTime.current = Date.now();
            return;
        }

        ///get a list of events that havent been added
        const newEventIds = incomingEventIds.filter(incomingEventId => {
            return !eventIds.current.includes(incomingEventId);
        });

        newEventIds.forEach(eventId => {
            const event = events[eventId];
            if (parseInt(event.time) <= latestEventTime.current) {
                return;
            }
            let soundId = "s1";

            if (event && event.data && event.data.personId) {
                const personId = event.data.personId;
                const person = persons[personId];
                if (!person) {
                    return;
                }
                soundId = persons[personId].soundId;
            }
            if (!soundEffects[soundId]) {
                soundId = Object.keys(soundEffects)[0];
            }

            if (localStore.data.pagingSoundEnabled) {
                playSoundEffect(soundEffects[soundId]);
            }

            latestEventTime.current = Date.now();
            eventIds.current = Array.from(
                new Set([...eventIds.current, ...incomingEventIds])
            );
        });
    }, [pagingDoc.events]);

    return {};
}
