import React, { createRef, useEffect, useState, useContext } from 'react';
import Select2 from 'react-select';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';
import { isEmpty, isNil, view, update } from 'ramda';
import clsx from 'clsx';
import MomentUtils from "@date-io/moment";
import esLocale from "date-fns/locale/es";
import DateFnsUtils from '@date-io/date-fns';

import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import {
  IconButton, useMediaQuery, Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions, Paper, Slide, ButtonBase
} from '@material-ui/core';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import finger from '../../../../assets/finger.png'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    dialog: {
      '& .MuiDialog-container .MuiDialog-paperWidthSm': {
        borderRadius: 20,
        // overflowY: 'auto',
        // overflowX: 'hidden',
        maxWidth: 544,
        width: '100%',
        background: '#fff',
        maxHeight: '80%'
        // padding: '2.5%',
      },
      '& .MuiDialog-container .MuiDialog-paperWidthSm .MuiDialogContent-root': {
        overflowY: 'auto',
        overflowX: 'hidden',
        padding: '8px 20px'
      },
    },
    typeModal: {
      background: '#F86F46',
      color: '#fff',
      padding: '7px 24px',
      textAlign: 'center'
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(0),
      padding: '4px',

      '& svg': {
        width: '1.5em',
        height: '1.5em',
      }
    },
    text: {
      margin: 0,
      padding: 0,
      color: '#030303',
      textAlign: 'left',
      fontSize: 12,
    },
    btnAction: {
      padding: '8px 24px',
      border: '1px solid #cecaca',

      '& button': {

      },
      '& .btnSave': {
        background: '#F86F46',
        color: '#fff',
      }
    },

    boxTitle: {
      display: 'flex',
      width: '98%',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 20,
      marginBottom: 10,
    },
    textSub: {
      fontWeight: 400,
      fontSize: 12,
      margin: 0,
      padding: 0,
      color: '#3A3E4B'
    },
    textTitle: {
      fontWeight: 400,
      fontSize: 20,
      margin: 0,
      padding: 0,
      color: '#3A3E4B'
    },


    personStyle: {
      height: 50,
      color: 'black',
      backgroundColor: 'white',
      margin: 5,
      borderRadius: 10,
      padding: 2,

      '& p': {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          margin: 0,
          padding: 0,
          width: '100%',
          textAlign: 'center'
      }
  },
  boxNot: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '1%',
    '& p': {
        margin: 0,
        padding: 0,
        fontWeight: 'bold',
        color: '#3A3E4B',
        fontSize: 19,
        textAlign: 'center'
    },
},
  scrollCustom: {

    background: '#fff',
    overflowY: 'scroll',

    '&::-webkit-scrollbar': {
        backgroundColor: '#fff',
        width: 12,     /* Tamaño del scroll en vertical */
        height: 12,    /* Tamaño del scroll en horizontal */
        // display: 'none',  /* Ocultar scroll */
    },

    /* background of the scrollbar except button or resizer */
    '&::-webkit-scrollbar-track': { backgroundColor: '#fff' },

    '&::-webkit-scrollbar-track:hover': { backgroundColor: '#f4f4f4' },

    /* scrollbar itself */
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#babac0',
        borderRadius: 16,
        border: '4px solid #fff',
    },
    '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#a0a0a5',
        border: '4px solid #f4f4f4'
    },

    /* set button(top and bottom of the scrollbar) */
    '&::-webkit-scrollbar-button': { display: 'none' },
}

  })
);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function (props) {

  const { visible, toggle, users, handleSelectedUser } = props;
  // ----------------------------------------------------------
  const classes = useStyles();
  let inputEl = createRef();
  // ----------------------------------------------------------
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const smScreen = useMediaQuery("(max-width:600px)");
  const mdScreen = useMediaQuery("(max-width:960px)");
  const lgScreen = useMediaQuery('(max-width:1280px)');
  const xlScreen = useMediaQuery("(max-width:1920px)");
  // ----------------------------------------------------------
  const [userList, setUserList] = useState([])
  // ----------------------------------------------------------
  useEffect(() => {
    if (!isEmpty(users)) {
      setUserList(users)
    } else {
      setUserList([])
    }
  }, [users]);

  return (

    <Dialog
      fullScreen={fullScreen}
      open={visible}
      keepMounted
      // onClose={toggle}
      className={classes.dialog}
      // disableBackdropClick
      // disableEscapeKeyDown
      aria-labelledby="responsive-dialog-title"
      TransitionComponent={Transition}
    >
      <DialogTitle id="responsive-dialog-title" className={classes.typeModal}>Conversation
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={toggle}
          className={classes.closeButton}
        >
          <HighlightOffIcon />
        </IconButton>

      </DialogTitle>
      <DialogContent ref={(e) => (inputEl = e)} className={clsx(classes.scrollCustom, '')}>
        {/* <DialogContentText> </DialogContentText> */}
        { !isEmpty(userList) ? 
          <div className={classes.boxTitle}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src={finger} style={{ width: 33, height: 40 }} />
              <div style={{ marginLeft: 20 }}>
                {/* <div className={classes.textSub}>PINNED CHATS</div> */}
                <p className={classes.textTitle}>Who are you?</p>
              </div>
            </div>
          </div>
        : null}

        <div style={{ width: '100%' }}>
          { !isEmpty(userList) ? userList.map((user, index) => {
            return (
              <ButtonBase
                key={index}
                style={{
                  border: !isEmpty(user.bgColor) ? `2px solid ${user.bgColor}` : '2px solid #EBEFF9',
                  width: smScreen ? '30%' : mdScreen ? '30%' : '22%'
                }}
                component={Paper}
                className={classes.personStyle}
                onClick={handleSelectedUser(user)}
              >
                <p>{user.name}</p>
              </ButtonBase>
            );
          }) : 
            <div className={classes.boxNot}>
                  <p className='p-sub'> You do not have users created in the messaging module in the portal</p>
              </div>
            }
        </div>

      </DialogContent>
      {/* <DialogActions className={classes.btnAction}></DialogActions> */}
    </Dialog>

  );
}

