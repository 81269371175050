import React, { useRef, useEffect, useState } from "react";
import { isEmpty } from 'ramda';
import { Paper, Button, makeStyles } from "@material-ui/core";

import Message from "./Message";

const useStyles = makeStyles({
    root: {
    },
});

export default function (props) {
    
    const { messages, loadMore } = props;
    const ref = useRef();
    const classes = useStyles();
    const [stuckToBottom, setStuckToBottom] = useState(true);
    const showLoadMoreButton = (() => {
        if (messages.length < 1) {
            return false;
        }
        const earliestMessage = messages.reduce((acc, message) => {
            if (message.number < acc) {
                return message.number;
            }
            return acc;
        }, 9999999999999);

        if (earliestMessage > 1) {
            return true;
        }
        return false;
    })();

    useEffect(() => {
        if (stuckToBottom) {
            ref.current.scroll(
                0,
                ref.current.scrollHeight - ref.current.clientHeight
            );
        }
    }, [messages, stuckToBottom]);

    return (
        <Paper
            ref={ref}
            style={{ boxShadow: 'none', backgroundColor: 'none' }}
            onScroll={e => {
                if (
                    ref.current.scrollTop ===
                    ref.current.scrollHeight - ref.current.clientHeight
                ) {
                    setStuckToBottom(true);
                } else {
                    setStuckToBottom(false);
                }
            }}
        >
            {showLoadMoreButton && (
                <div style={{ width: "100%" }}>
                    <Button
                        fullWidth
                        size="small"
                        variant="contained"
                        onClick={() => {
                            loadMore();
                        }}
                    >
                        load more
                    </Button>
                </div>
            )}
            <div>
                {messages
                    .sort((a, b) => {
                        return parseInt(a.time) - parseInt(b.time);
                    })
                    .map(message => {
                        return (
                            <Message key={message.id} message={message}></Message>
                        );
                    })}
            </div>
        </Paper>
    );
}
