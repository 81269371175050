import React, { useEffect, useState, useRef, useContext } from "react";
import { playSoundEffect } from "../../../audio/audio";
import soundEffects from "../../../audio/soundEffects";
import GlobalContext from "../../../hooks/GlobalContext";

export default function(visits, loading) {
    const visitIds = useRef([]);
    const { localStore } = useContext(GlobalContext);

    useEffect(() => {
        const incomingVisitIds = visits.map(visit => {
            return visit.id;
        });

        if (loading) {
            visitIds.current = incomingVisitIds;
            return;
        }

        if (!AContainsB(visitIds.current, incomingVisitIds)) {
            if (localStore.data.patientTrackingSoundEnabled) {
                playSoundEffect(soundEffects.s0);
            }
            ///this array can get huge

            visitIds.current = Array.from(
                new Set([...visitIds.current, ...incomingVisitIds])
            );
        }
    }, [visits]);

    return {};
}

function AContainsB(a, b) {
    return !b.some(element => {
        return !a.includes(element);
    });
}
