import React, { useContext } from "react";
import MessagingContext from "./MessagingContext";
import { Avatar, Tooltip } from "@material-ui/core";

export default function(props) {
    const { style, participantIds, avatarStyle } = props;
    const messaging = useContext(MessagingContext);

    return (
        <div style={{ overflowX: "hidden", whiteSpace: "nowrap", ...style }}>
            {participantIds.length > 1 && `(${participantIds.length})`}
            {participantIds.length < 1 && `(0)`}
            {participantIds.map(userId => {
                const user = messaging.getUserById(userId);
                const name = user && user.name ? user.name : userId;
                return (
                    <Tooltip
                        disabled
                        disableFocusListener
                        key={user && user.id ? user.id : userId}
                        title={name}
                    >
                        <Avatar
                            style={{
                                backgroundColor: "black",
                                textAlign: "center",
                                fontSize: "10px",
                                width: "15px",
                                height: "15px",
                                display: "inline-flex",
                                ...avatarStyle
                            }}
                        >
                            {name[0]}
                        </Avatar>
                    </Tooltip>
                );
            })}
        </div>
    );
}
