import { createContext, useState } from "react";

export const PagingStateContext = createContext({});

export default function usePagingState() {
    const [selectedPagingPersonId, setSelectedPagingPersonId] = useState(null);
    const [selectedMeaningButtonId, setSelectedMeaningButtonId] = useState(
        null
    );
    const [statePaging, setStatePaging] = useState(true);

    return {
        selectedPagingPersonId,
        setSelectedPagingPersonId,
        selectedMeaningButtonId,
        setSelectedMeaningButtonId,
        statePaging,
        setStatePaging
    };
}
