import React, { createContext } from "react";

export default createContext({
    users: [],
    conversations: [],
    selectedUserId: null,
    selectUserId: () => {},
    selectedConversationId: null,
    selectConversationId: () => {},
    getUserById: () => {},
    getConversationById: () => {},
    messagingDocListener: {},
    conversationQueryListener: {}
});
