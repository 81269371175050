import firebase from "../firebase";

export default function(body) {

    return firebase.functions().httpsCallable("allFunctions")({
        funcRole: "officeUser",
        funcName: "sessionDevice",
        data: body
    });
}
