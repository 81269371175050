import React from "react";
import AssignmentIcon from "@material-ui/icons/Assignment";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import LocalAirportIcon from "@material-ui/icons/LocalAirport";
import VisibilityIcon from "@material-ui/icons/Visibility";
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import AccessibleIcon from "@material-ui/icons/Accessible";
import GradeIcon from "@material-ui/icons/Grade";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import SearchIcon from "@material-ui/icons/Search";
import BuildIcon from "@material-ui/icons/Build";
import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";
import FaceIcon from "@material-ui/icons/Face";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import PanToolIcon from "@material-ui/icons/PanTool";

const icons = {
    none: null,
    Assignment: <AssignmentIcon></AssignmentIcon>,
    DoneOutline: <DoneOutlineIcon></DoneOutlineIcon>,
    FastFood: <FastfoodIcon></FastfoodIcon>,
    LocalAirport: <LocalAirportIcon></LocalAirportIcon>,
    Visibility: <VisibilityIcon></VisibilityIcon>,
    WorkOutline: <WorkOutlineIcon></WorkOutlineIcon>,
    WatchLater: <WatchLaterIcon></WatchLaterIcon>,
    Accessible: <AccessibleIcon></AccessibleIcon>,
    Grade: <GradeIcon></GradeIcon>,
    HelpOutline: <HelpOutlineIcon></HelpOutlineIcon>,
    Search: <SearchIcon></SearchIcon>,
    Build: <BuildIcon></BuildIcon>,
    AssignmentLate: <AssignmentLateIcon></AssignmentLateIcon>,
    Face: <FaceIcon></FaceIcon>,
    HourglassEmpty: <HourglassEmptyIcon></HourglassEmptyIcon>,
    PanTool: <PanToolIcon></PanToolIcon>
};

export default function(props) {
    const { iconId } = props;
    if (!icons[iconId]) {
        return icons["none"];
    } else {
        return icons[iconId];
    }
}
