import React, { createRef, useEffect, useState, useContext } from 'react';
import Select2 from 'react-select';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';
import { isEmpty, isNil, view } from 'ramda';
import clsx from 'clsx';
import MomentUtils from "@date-io/moment";
import esLocale from "date-fns/locale/es";
import DateFnsUtils from '@date-io/date-fns';

import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from "@material-ui/pickers";
import { makeStyles, createStyles, Theme, useTheme } from '@material-ui/core/styles';
import {
  Button, IconButton, Snackbar, useMediaQuery, Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions, CircularProgress,
  TextField, Grid, NoSsr, MenuItem, Typography, Paper, InputLabel, Select, FormControl
} from '@material-ui/core';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import { theme, colors } from '../../../../../theme/theme';
import CustomTextField from '../../../../CustomTextField';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    dialog: {
      '& .MuiDialog-container .MuiDialog-paperWidthSm': {
        borderRadius: 20,
        // overflowY: 'auto',
        // overflowX: 'hidden',
        maxWidth: 544,
        width: '100%',
        background: '#fff',
        maxHeight: '40%'
        // padding: '2.5%',
      },
      '& .MuiDialog-container .MuiDialog-paperWidthSm .MuiDialogContent-root': {
        overflowY: 'auto',
        overflowX: 'hidden',
        padding: 24
      },
    },
    typeModal: {
      background: '#197FE8',
      color: '#fff',
      padding: '7px 24px',
      textAlign: 'center'
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(0),
      padding: '4px',

      '& svg': {
        width: '1.5em',
        height: '1.5em',
      }
    },
    boxTextField: {
      width: '100%',
      display: 'flex',
      flexFlow: 'row wrap'
    },
    text: {
      margin: 0,
      padding: 0,
      color: '#030303',
      textAlign: 'left',
      fontSize: 12,
    },
    textP: {
      margin: 0,
      padding: 0,
      color: '#030303',
      textAlign: 'left',
      fontSize: 15,
    },
    btnAction: {
      justifyContent: 'space-between',
      padding: '8px 24px',
      border: '1px solid #cecaca',

      '& button': {

      }
    },
    boxCustomSchedule: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    boxOccurr: {
      // backgroundColor: 'red',
      width: '100%',
      height: 50,
      marginRight: 10,
      '& p': {
        padding: 0,
        marginTop: -3,
        position: 'absolute',
        fontSize: 12,
      }
    },
    selected: {
      padding: 0,
      margin: 0,
      // marginTop: 15,
      width: '100%',
      height: 40,
      backgroundColor: "#f5f5f5",

    },
    scrollCustom: {

      background: '#fff',
      overflowY: 'scroll',

      '&::-webkit-scrollbar': {
          backgroundColor: '#fff',
          width: 12,     /* Tamaño del scroll en vertical */
          height: 12,    /* Tamaño del scroll en horizontal */
          // display: 'none',  /* Ocultar scroll */
      },

      /* background of the scrollbar except button or resizer */
      '&::-webkit-scrollbar-track': { backgroundColor: '#fff' },

      '&::-webkit-scrollbar-track:hover': { backgroundColor: '#f4f4f4' },

      /* scrollbar itself */
      '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#babac0',
          borderRadius: 16,
          border: '4px solid #fff',
      },
      '&::-webkit-scrollbar-thumb:hover': {
          backgroundColor: '#a0a0a5',
          border: '4px solid #f4f4f4'
      },

      /* set button(top and bottom of the scrollbar) */
      '&::-webkit-scrollbar-button': { display: 'none' },
  }

  })
);


export default function DialogVisit(props) {

  const { visible, toggle, handleSave, loading, setLoading, form, formError, orderedrooms, orderedStages, onChangeText } = props;
  // ----------------------------------------------------------
  const classes = useStyles();
  let inputEl = createRef();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  // ----------------------------------------------------------
  useEffect(() => {
    if (visible) {
      document.body.style = "padding:0; overflow-y: hidden;";
      setLoading(false)
    } else {
      document.body.style = "";
    }
  }, [visible])

  return (

    <Dialog
      fullScreen={fullScreen}
      open={visible}
      onClose={toggle}
      className={classes.dialog}
      // disableBackdropClick
      // disableEscapeKeyDown
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title" className={classes.typeModal}>UPDATE
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={toggle}
          className={classes.closeButton}
        >
          <HighlightOffIcon />
        </IconButton>

      </DialogTitle>
      <DialogContent ref={(e) => (inputEl = e)} className={clsx(classes.scrollCustom, '')}>
        {/* <DialogContentText>Let Google help apps determine location.</DialogContentText> */}
        <div style={{ marginBottom: 15 }}>
          <p className={classes.text}>NAME</p>
          <p className={classes.textP}>{form.patientName} {form.lastName}</p>
        </div>
        <Grid className={clsx(classes.boxTextField, '')} container spacing={1}>
          <Grid item xs={12} sm={6} >
            <p className={classes.text}>Stage</p>
            <div className={classes.boxCustomSchedule}>
              <div className={classes.boxOccurr}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={form.stage}
                  variant="outlined"
                  className={classes.selected}
                  onChange={onChangeText('stage')}>
                  {
                    orderedStages.map(stag => {
                      return (
                        <MenuItem
                          key={stag.id}
                          value={stag.id}
                        >
                          {stag.name}
                        </MenuItem>
                      );
                    })
                  }
                </Select>
              </div>
            </div>

            {formError.stage ? <div style={{ color: '#FF4848', textAlign: 'right', fontWeight: 500, margin: '4px 14px 14px 0', fontSize: '0.75rem' }}>required</div> : null}
          </Grid>
          <Grid item xs={12} sm={6} >
            <p className={classes.text}>Room</p>
            <div className={classes.boxCustomSchedule}>
              <div className={classes.boxOccurr}>
                <Select
                  labelId="room-simple-select-label"
                  id="room-simple-select"
                  value={form.room}
                  variant="outlined"
                  className={classes.selected}
                  onChange={onChangeText('room')}>
                  {
                    orderedrooms.map(roo => {
                      return (
                        <MenuItem
                          key={roo.id}
                          value={roo.id}
                        >
                          {roo.name}
                        </MenuItem>
                      );
                    })
                  }
                </Select>
              </div>
            </div>
            {formError.room ? <div style={{ color: '#FF4848', textAlign: 'right', fontWeight: 500, margin: '4px 14px 14px 0', fontSize: '0.75rem' }}>required</div> : null}
          </Grid>

        </Grid>
      </DialogContent>
      <DialogActions className={classes.btnAction}>
        <Button onClick={toggle}>
          CANCEL
        </Button>
        {!loading && (
          <Button onClick={handleSave} color="primary">
            SAVE
          </Button>
        )}
        {loading && (
          <CircularProgress></CircularProgress>
        )}
      </DialogActions>
    </Dialog >

  );
}

