import React, { useState, useEffect, useContext, useRef } from "react";
// import getOfficesMeta from "../../../firebase/httpsCallable/getOfficesMeta";
import {
    ListItem,
    ListItemText,
    List,
    CircularProgress,
    CardContent,
    CardActions,
    Button,
    Card,
    CardHeader, Dialog, DialogContent, DialogActions
} from "@material-ui/core";
import GlobalContext from "../../../hooks/GlobalContext";
import createOrUpdateDevice from "../../../firebase/httpsCallable/createOrUpdateDevice";
import { isNil, isEmpty } from "ramda";

import firebase from "../../../firebase/firebase";

export default function (props) {
    const {
        officeList,
        localStore,
        licensesDoc,
        mainCtx,
        navigation,
        authCtx
    } = useContext(GlobalContext);
    const {
        loading,
        error,
        offices,
        initializing,
        setInitializing
    } = officeList;

    const [errorDv, setErrorDV] = useState(null);

    useEffect(() => {
        officeList.getOffices();

        localStore.update(
            localStore.storageKeys.messageVisitEnabled,
            false
        );
    }, []);

    useEffect(() => {
        async function fetch() {
            if (authCtx.guestUser && offices.length > 0 && offices.length === 1 ) {
                console.log('*** saveDevice')
                saveDevice(offices[0].officeId)
            }
        }
        fetch();
    }, [authCtx.guestUser, offices]);

    const matchingOffice = offices.find(office => {
        return office.officeId === localStore.data.selectedOfficeId;
    });

    if (matchingOffice && initializing) {
        officeList.setInitializing(false);
        licensesDoc.setInitializing(true);
        mainCtx.setOfficeId(localStore.data.selectedOfficeId);

        // navigation.toLicenseSelect(localStore.data.selectedOfficeId);
        navigation.toMainApp(localStore.data.selectedOfficeId);
    }

    const saveDevice = (officeId) => {

        if (!isNil(window.device) && !isEmpty(window.device) && !authCtx.isSuperAdmin) {
            console.log('device info', window.device)
            const body = {
                officeId,
                hostname: window.device.hostname,
                platform: window.device.platform,
                platformDetail: window.device.distro,
                user: window.device.user,
            }
            createOrUpdateDevice(body).then(result => {
                if (result && result.data && result.data.status && result.data.status === 201) {
                    setErrorDV(result.data.message);
                } else {
                    console.log('check if user is logged in ===', Boolean(firebase.auth().currentUser))
                    if (result && result.data && result.data && Boolean(firebase.auth().currentUser)) {
                        localStore.update(
                            localStore.storageKeys.selectedOfficeId,
                            officeId
                        );
                        licensesDoc.setInitializing(true);
                        mainCtx.setOfficeId(officeId);
                        navigation.toMainApp(officeId);
                    }
                }
            }).catch(error => {
                console.error('Error saveDevice:', error);
            });
        } else {
            console.log(`it's a browser`);
            localStore.update(
                localStore.storageKeys.selectedOfficeId,
                officeId
            );
            licensesDoc.setInitializing(true);
            mainCtx.setOfficeId(officeId);
            navigation.toMainApp(officeId);
        }

    }

    if (loading) {
        return <CircularProgress size={30} />;
    }
    if (error) {
        return (
            <Card>
                <CardHeader title="Error" />
                <CardContent>{error}</CardContent>
                <CardActions>
                    <Button
                        variant="contained"
                        onClick={() => {
                            officeList.getOffices();
                        }}
                    >
                        Retry
                    </Button>
                </CardActions>
            </Card>
        );
    }

    return (
        <React.Fragment>
            {authCtx.guestUser && (
                <CircularProgress size={30} />
            )}
            {!authCtx.guestUser && (
                <List>
                    {offices
                        .sort((a, b) => {
                            const nameA = a.name;
                            const nameB = b.name;
                            if (nameA > nameB) {
                                return 1;
                            }
                            if (nameA < nameB) {
                                return -1;
                            }
                            return 0;
                        })
                        .map(office => {
                            return (
                                <OfficeListItem office={office} key={office.name} saveDevice={saveDevice}>
                                    {office.name}
                                </OfficeListItem>
                            );
                        })}
                </List>
            )}
            {errorDv != null && Boolean(errorDv) && (
                <Dialog
                    open={Boolean(errorDv)}
                    onClose={() => {
                        setErrorDV(null);
                    }}
                >
                    <DialogContent>{errorDv}</DialogContent>
                    <DialogActions>
                        <Button
                            size="small"
                            variant="contained"
                            onClick={() => {
                                setErrorDV(null);
                            }}
                        >
                            ok
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </React.Fragment>
    );
}

function OfficeListItem(props) {
    const office = props.office;
    return (
        <ListItem
            button
            divider
            onClick={() => {
                // localStore.update(
                //     localStore.storageKeys.selectedOfficeId,
                //     office.officeId
                // );
                props.saveDevice(office.officeId)
                // licensesDoc.setInitializing(true);
                // mainCtx.setOfficeId(office.officeId);
                // navigation.toMainApp(office.officeId);
                // -----------------------------------
                // navigation.toLicenseSelect(office.officeId);
            }}
        >
            <ListItemText primary={office.name} />
        </ListItem>
    );
}
