import { isNil } from "ramda";
import React, { useContext, useEffect, useRef } from "react";
import { playSoundEffect } from "../../../audio/audio";
import soundEffects from "../../../audio/soundEffects";
import GlobalContext from "../../../hooks/GlobalContext";

export default function (pagingDoc) {
    const { persons, events, loading, loadingOther, users } = pagingDoc;
    const { localStore } = useContext(GlobalContext);
    const eventIds = useRef([]);
    const latestEventTime = useRef(Date.now());
    /// This can be cleaned up and made less able to produce bugs

    useEffect(() => {

        if (!isNil(pagingDoc.events)) {

            const incomingEventIds = Object.keys(pagingDoc.events);

            if (loading) {
                eventIds.current = incomingEventIds;
                latestEventTime.current = Date.now();
                return;
            }

            ///get a list of events that havent been added
            const newEventIds = incomingEventIds.filter(incomingEventId => {
                return !eventIds.current.includes(incomingEventId);
            });

            // newEventIds.forEach(eventId => {
            if (newEventIds.length > 0 && loadingOther) {
                const event = events[newEventIds[0]];
                if (parseInt(event.time) <= latestEventTime.current || parseInt(event.time) >= latestEventTime.current) {

                    let soundId = "s1";
                    if (event && event.data && event.data.personId) {
                        const personId = event.data.personId;
                        const person = users.filter(e => e.id === `${personId}`);
                        if (!person) {
                            return;
                        }
                        soundId = person && person[0] ? person[0].soundId : 's1';
                    }
                    if (!soundEffects[soundId]) {
                        soundId = Object.keys(soundEffects)[0];
                    }

                    if (localStore.data.pagingSoundEnabled) {
                        playSoundEffect(soundEffects[soundId]);
                    }

                    latestEventTime.current = Date.now();
                    eventIds.current = Array.from(
                        new Set([...eventIds.current, ...incomingEventIds])
                    );
                }
            }
            // });
        }
    }, [pagingDoc.events]);

    return {};
}
