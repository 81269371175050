import React, { createRef, useEffect, useState, useContext } from 'react';
import Select2 from 'react-select';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';
import { isEmpty, isNil, view, update } from 'ramda';
import clsx from 'clsx';
import MomentUtils from "@date-io/moment";
import esLocale from "date-fns/locale/es";
import DateFnsUtils from '@date-io/date-fns';

import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import {
  IconButton, useMediaQuery, Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions, Paper, Slide, ButtonBase, Button,
  Tooltip
} from '@material-ui/core';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import GroupIcon from '@material-ui/icons/Group';
import AddIcon from '@material-ui/icons/Add';
import plane_color1 from '../../../../assets/plane_color1.png'
import song from '../../../../assets/song.png'

import MessagingContext from "../MessagingContext";

import PersonTalk from "./PersonTalk";


const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    dialog: {
      '& .MuiDialog-container .MuiDialog-paperWidthSm': {
        borderRadius: 20,
        // overflowY: 'auto',
        // overflowX: 'hidden',
        maxWidth: 544,
        width: '100%',
        background: '#fff',
        maxHeight: '80%'
        // padding: '2.5%',
      },
      '& .MuiDialog-container .MuiDialog-paperWidthSm .MuiDialogContent-root': {
        overflowY: 'auto',
        overflowX: 'hidden',
        padding: '8px 20px'
      },
    },
    typeModal: {
      background: '#F86F46',
      color: '#fff',
      padding: '7px 24px',
      textAlign: 'center'
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(0),
      padding: '4px',

      '& svg': {
        width: '1.5em',
        height: '1.5em',
      }
    },
    text: {
      margin: 0,
      padding: 0,
      color: '#030303',
      textAlign: 'left',
      fontSize: 12,
    },
    btnAction: {
      padding: '8px 24px',
      border: '1px solid #cecaca',

      '& button': {

      },
      '& .btnSave': {
        background: '#F86F46',
        color: '#fff',
      }
    },

    boxTitle: {
      display: 'flex',
      width: '98%',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 20,
      marginBottom: 10,
    },
    textSub: {
      fontWeight: 400,
      fontSize: 12,
      margin: 0,
      padding: 0,
      color: '#3A3E4B'
    },
    textTitle: {
      fontWeight: 400,
      fontSize: 20,
      margin: 0,
      padding: 0,
      color: '#3A3E4B'
    },


    personStyle: {
      height: 50,
      color: 'black',
      backgroundColor: 'white',
      margin: 5,
      borderRadius: 10,
      padding: 2,

      '& p': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        margin: 0,
        padding: 0,
        width: '100%',
        textAlign: 'center'
      }
    },
    btnActive: {
      backgroundColor: '#F46B2A',
      marginRight: 5,
      marginLeft: 5,
      color: 'white',
      borderRadius: 8,

      '&:hover': {
        backgroundColor: '#F46B2A',
      }
    },
    btnGroup: {
      height: 50,
      border: `2px solid #F46B2A`,
      color: 'black',
      backgroundColor: 'white',
      borderRadius: 10,
      padding: 2,

      '&:hover': {
        border: '3px solid #f86f46',
        backgroundColor: '#fff',
      },

      '& p': {
        width: '100%',
        margin: 0,
        padding: 0,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        textAlign: 'left',
        marginRight: 10,
        color: '#F46B2A'
      }
    },
    backButton: {
      position: 'absolute',
      left: theme.spacing(1),
      top: 8,
      padding: '4px',

      '& svg': {
        width: '1em',
        height: '1em',
      }
    },
    scrollCustom: {

      background: '#fff',
      overflowY: 'scroll',
  
      '&::-webkit-scrollbar': {
          backgroundColor: '#fff',
          width: 12,     /* Tamaño del scroll en vertical */
          height: 12,    /* Tamaño del scroll en horizontal */
          // display: 'none',  /* Ocultar scroll */
      },
  
      /* background of the scrollbar except button or resizer */
      '&::-webkit-scrollbar-track': { backgroundColor: '#fff' },
  
      '&::-webkit-scrollbar-track:hover': { backgroundColor: '#f4f4f4' },
  
      /* scrollbar itself */
      '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#babac0',
          borderRadius: 16,
          border: '4px solid #fff',
      },
      '&::-webkit-scrollbar-thumb:hover': {
          backgroundColor: '#a0a0a5',
          border: '4px solid #f4f4f4'
      },
  
      /* set button(top and bottom of the scrollbar) */
      '&::-webkit-scrollbar-button': { display: 'none' },
  }


  })
);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function (props) {

  const { visible, toggle, back, userSelectOne, handleSelectedUserTwo, handleSelectedGroup, disabledButtom, handleCreateGroup } = props;
  // ----------------------------------------------------------
  const { conversations, selectedUserId, users } = useContext(MessagingContext);
  // ----------------------------------------------------------
  const classes = useStyles();
  let inputEl = createRef();
  // ----------------------------------------------------------
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const smScreen = useMediaQuery("(max-width:600px)");
  const mdScreen = useMediaQuery("(max-width:960px)");
  const lgScreen = useMediaQuery('(max-width:1280px)');
  const xlScreen = useMediaQuery("(max-width:1920px)");
  // ----------------------------------------------------------
  const [groupData, setGroupData] = useState([])
  const [usersOther, setUsersOther] = useState([])
  const [type, setType] = useState(false)
  // ----------------------------------------------------------

  useEffect(() => {
    if (!isEmpty(conversations) && !isEmpty(userSelectOne)) {

      let group = [];
      conversations.map((conversation) => {
        const resul = {
          ...conversation,
          userIds: conversation.userIds.filter(userId => { return userId !== `${selectedUserId}` })
        }
        if (resul.name || resul.userIds.length > 1) {
          group = group.concat(resul)
          setGroupData(group)
        }
      })
    }

    if (!isEmpty(users)) {
      const filterUser = users.filter((e) => e.id !== userSelectOne['id']);
      setUsersOther(filterUser.map((e) => ({ ...e, selected: false, })));
    }

    return () => {
      setUsersOther([])
    }

  }, [conversations, userSelectOne]);

  // ----------------------------------------------------------
  const changeType = () => setType(!type)

  return (

    <Dialog
      fullScreen={fullScreen}
      open={visible}
      keepMounted
      // onClose={toggle}
      className={classes.dialog}
      // disableBackdropClick
      // disableEscapeKeyDown
      aria-labelledby="responsive-dialog-title"
      TransitionComponent={Transition}
    >
      <DialogTitle id="responsive-dialog-title" className={classes.typeModal}>
        <IconButton className={classes.backButton} onClick={back}>
            <ArrowBackIosRoundedIcon style={{ color: 'white' }} />
        </IconButton>
        <p style={{ margin: 0, fontWeight: !isEmpty(userSelectOne) ? 'normal' : 'bold' }}><span style={{ fontWeight: 'bold' }}>{!isEmpty(userSelectOne) ? userSelectOne.name : ''}</span> Conversation</p>
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={toggle}
          className={classes.closeButton}
        >
          <HighlightOffIcon />
        </IconButton>

      </DialogTitle>
      <DialogContent ref={(e) => (inputEl = e)} className={clsx(classes.scrollCustom, '')}>
        {/* <DialogContentText> </DialogContentText> */}
        <div className={classes.boxTitle}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            { type ? <img src={song} style={{ width: 33, height: 40 }} /> : <img src={plane_color1} style={{ width: 33, height: 40 }} /> }
            <div style={{ marginLeft: 8 }}>
              {/* <div className={classes.textSub}>PINNED CHATS</div> */}
              <p className={classes.textTitle}>Who do you want to talk to?</p>
            </div>
          </div>
        </div>
        <div style={{ textAlign: 'center', marginBottom: 20 }}>
          <Button variant={!type ? 'contained' : 'text'} className={!type ? classes.btnActive : classes.btnInactive} onClick={changeType}>
            USERS
          </Button>
          <Button variant={type ? 'contained' : 'text'} className={type ? classes.btnActive : classes.btnInactive} onClick={changeType}>
            GROUPS
          </Button>
        </div>

        <div style={{ width: '100%' }}>

          {(!type) ? (
           !isEmpty(usersOther) ? usersOther.map((user, index) => {
              return (
                <ButtonBase
                  key={index}
                  style={{
                    border: !isEmpty(user.bgColor) ? `2px solid ${user.bgColor}` : '2px solid #EBEFF9',
                    width: smScreen ? '30%' : mdScreen ? '30%' : '22%'
                  }}
                  component={Paper}
                  className={classes.personStyle}
                  onClick={handleSelectedUserTwo(user)}
                  disabled={disabledButtom}
                >
                  <p>{user.name}</p>
                </ButtonBase>
              );
            }) : null
          )
            :
            <div style={{ width: '100%', margin: '2%' }}>
              <div style={{ margin: 5, textAlign: 'center' }}>
                <Button className={classes.btnGroup} onClick={handleCreateGroup(groupData)} style={{ width: smScreen ? '100%' : '98%' }} >
                  <AddIcon style={{ color: '#F46B2A', width: 34, height: 34 }} />
                  <p>Create new Group</p>
                </Button>
              </div>
              <div style={{ textAlign: 'center' }}>
                { !isEmpty(groupData) ? groupData.map((item, index) => {
                  return (

                    <ButtonBase
                      key={index}
                      component={Paper}
                      className={classes.personStyle}
                      style={{ width: smScreen ? '98%' : mdScreen ? '96%' : '98%' }}
                      onClick={handleSelectedGroup(item)}
                    >
                      <GroupIcon style={{ color: '#3A3E4B', margin: 10 }} />
                      <div style={{ width: '30%' }}>
                      <Tooltip
                          key={index}
                          title={item.name ? item.name : `Group Id ${item.id}` }>
                          <p style={{
                            // width: '100%',
                            padding: 0,
                            margin: 0,
                            color: "#3A3E4B",
                            fontWeight: 600,
                            fontSize: 15,
                            marginRight: 8,
                            overflowX: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            textAlign: 'left'
                          }}>
                            {item.name || `Group Id ${item.id}`}
                          </p>
                        </Tooltip>
                      </div>
                      <PersonTalk
                        participantIds={item.userIds.filter(
                          userId => {
                            return userId !== selectedUserId;
                          }
                        )}
                        index={index}
                        color='#3A3E4B'
                      />
                    </ButtonBase>

                  );
                }) : null }
              </div>
            </div>


          }

        </div>

      </DialogContent>
      {/* <DialogActions className={classes.btnAction}></DialogActions> */}
    </Dialog>

  );
}

