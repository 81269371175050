import firebase from "../firebase";

export default function({
    officeId,
    keepPeople,
    licenseId
}) {
    return firebase.functions().httpsCallable("allFunctions")({
        funcRole: "messagingUser",
        funcName: "createKeepPeople",
        data: {
            officeId,
            keepPeople,
            licenseId,
        }
    });
}
