import React, { createRef, useEffect, useState, useContext } from 'react';
import Select2 from 'react-select';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';
import { isEmpty, isNil, view, update } from 'ramda';
import clsx from 'clsx';
import MomentUtils from "@date-io/moment";
import esLocale from "date-fns/locale/es";
import DateFnsUtils from '@date-io/date-fns';

import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import {
  IconButton, useMediaQuery, Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions, Paper, Slide, ButtonBase
} from '@material-ui/core';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import finger from '../../../../assets/finger.png'

import GlobalContext from "../../../../hooks/GlobalContext";
import MessagingContext from "../MessagingContext";
import useMessageRepository from "../AllChat/useMessageRepository";

import MessageComposer from "../AllChat/MessageComposer";
import MessageWindow from "../AllChat/MessageWindow";
import PersonChat from "./PersonChat";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    dialog: {
      '& .MuiDialog-container .MuiDialog-paperWidthSm': {
        borderRadius: 20,
        // overflowY: 'auto',
        // overflowX: 'hidden',
        maxWidth: 544,
        width: '100%',
        background: '#fff',
        maxHeight: '80%',
        minHeight: '70%'
        // padding: '2.5%',
      },
      '& .MuiDialog-container .MuiDialog-paperWidthSm .MuiDialogContent-root': {
        overflowY: 'auto',
        overflowX: 'hidden',
      },
    },
    typeModal: {
      background: '#F86F46',
      color: '#fff',
      padding: '7px 24px',
      textAlign: 'center'
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(0),
      padding: '4px',

      '& svg': {
        width: '1.5em',
        height: '1.5em',
      }
    },
    text: {
      margin: 0,
      padding: 0,
      color: '#030303',
      textAlign: 'left',
      fontSize: 12,
    },
    btnAction: {
      padding: 0,

      '& button': {

      },
      '& .btnSave': {
        background: '#F86F46',
        color: '#fff',
      }
    },

    boxTitle: {
      display: 'flex',
      width: '98%',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 20,
      marginBottom: 10,
    },
    textSub: {
      fontWeight: 400,
      fontSize: 12,
      margin: 0,
      padding: 0,
      color: '#3A3E4B'
    },
    textTitle: {
      fontWeight: 400,
      fontSize: 20,
      margin: 0,
      padding: 0,
      color: '#3A3E4B'
    },

    backButton: {
      position: 'absolute',
      left: theme.spacing(1),
      top: 8,
      padding: '4px',

      '& svg': {
        width: '1em',
        height: '1em',
      }
    },

    scrollCustom: {

      background: '#fff',
      overflowY: 'scroll',

      '&::-webkit-scrollbar': {
          backgroundColor: '#fff',
          width: 12,     /* Tamaño del scroll en vertical */
          height: 12,    /* Tamaño del scroll en horizontal */
          // display: 'none',  /* Ocultar scroll */
      },

      /* background of the scrollbar except button or resizer */
      '&::-webkit-scrollbar-track': { backgroundColor: '#fff' },

      '&::-webkit-scrollbar-track:hover': { backgroundColor: '#f4f4f4' },

      /* scrollbar itself */
      '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#babac0',
          borderRadius: 16,
          border: '4px solid #fff',
      },
      '&::-webkit-scrollbar-thumb:hover': {
          backgroundColor: '#a0a0a5',
          border: '4px solid #f4f4f4'
      },

      /* set button(top and bottom of the scrollbar) */
      '&::-webkit-scrollbar-button': { display: 'none' },
  }

  })
);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function (props) {

  const { visible, toggle, back, userSelectOne, userSelectTwo, usersGroup, groupIdCreate, groupIdName } = props;
  // ----------------------------------------------------------
  const { localStore } = useContext(GlobalContext);
  const { conversations, selectedUserId } = useContext(MessagingContext);
  const messaging = useContext(MessagingContext);
  const messageRepository = useMessageRepository();
  // ----------------------------------------------------------
  const classes = useStyles();
  let inputEl = createRef();
  // ----------------------------------------------------------
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const smScreen = useMediaQuery("(max-width:600px)");
  const mdScreen = useMediaQuery("(max-width:960px)");
  const lgScreen = useMediaQuery('(max-width:1280px)');
  const xlScreen = useMediaQuery("(max-width:1920px)");
  // ----------------------------------------------------------
  const [newlyGroup, setNewlyGroup] = useState({})
  // ----------------------------------------------------------
  useEffect(() => {
    if (!isEmpty(groupIdCreate)) {
      const filterGroup = conversations.filter((e) => e.id === groupIdCreate);
      if (!isEmpty(filterGroup)) {
        messaging.selectConversationId(filterGroup[0].id);
        // When a new group is created, its id is sent
        setNewlyGroup(filterGroup[0]);
      }
    }

    // clear the chat user data when launching the app
    localStore.update(
      localStore.storageKeys.selectedMessagingChat,
      {}
    );

  }, [groupIdCreate]);
  // ----------------------------------------------------------

  return (

    <Dialog
      fullScreen={fullScreen}
      open={visible}
      keepMounted
      // onClose={toggle}
      className={classes.dialog}
      // disableBackdropClick
      // disableEscapeKeyDown
      aria-labelledby="responsive-dialog-title"
      TransitionComponent={Transition}
    >
      <DialogTitle id="responsive-dialog-title" className={classes.typeModal}>
        <IconButton className={classes.backButton} onClick={back}>
          <ArrowBackIosRoundedIcon style={{ color: 'white' }} />
        </IconButton>
        {/* ------------------------------------------------------ */}
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {!isEmpty(usersGroup) && isEmpty(newlyGroup) ?
            <p style={{ margin: 0, fontWeight: !isEmpty(usersGroup) ? 'normal' : 'bold' }}><span style={{ fontWeight: 'bold' }}>
              {usersGroup.name ? usersGroup.name : 'Unnamed'}</span>
            </p>
            :
            !isEmpty(userSelectTwo) ?
              <p style={{ margin: 0, fontWeight: !isEmpty(userSelectTwo) ? 'normal' : 'bold' }}><span style={{ fontWeight: 'bold' }}>
                {userSelectTwo.name ? userSelectTwo.name : 'Unnamed'}</span>
              </p>
              :
              !isEmpty(newlyGroup) ?
                <p style={{ margin: 0, fontWeight: !isEmpty(newlyGroup) ? 'normal' : 'bold' }}>
                  {/* <span style={{ fontWeight: 'bold' }}>{newlyGroup.name ? newlyGroup.name : 'Unnamed'}</span> */}
                  <span style={{ fontWeight: 'bold' }}>{groupIdName ? groupIdName : 'Unnamed'}</span>
                </p>
                : null
          }
          &nbsp;Conversation
        </div>
        {/* ------------------------------------------------------ */}
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={toggle}
          className={classes.closeButton}
        >
          <HighlightOffIcon />
        </IconButton>

      </DialogTitle>
      <DialogContentText style={{ margin: 0, border: '1px solid rgba(0, 0, 0, 0.23)' }}>

        {!isEmpty(usersGroup) && isEmpty(newlyGroup) ?
          <div style={{ display: 'flex', justifyContent: 'flex-start', padding: 10 }}>
            <PersonChat
              participantIds={usersGroup.userIds.filter(
                userId => {
                  return userId !== selectedUserId;
                }
              )}
              index={props.index}
              color='#fff'
            />
          </div>
          :
          !isEmpty(userSelectTwo) ?
            <div style={{ display: 'flex', justifyContent: 'center', padding: '10px 0' }}>
              <p
                style={{
                  backgroundColor: userSelectTwo.bgColor ? userSelectTwo.bgColor : '#bdbdbd',
                  fontSize: 10,
                  width: smScreen ? "80%" : '40%',
                  textAlign: 'center',
                  borderRadius: 8,
                  // textTransform: 'capitalize',
                  padding: 6,
                  color: '#fff',
                  margin: 0
                }}>
                {userSelectTwo.name ? userSelectTwo.name : 'Unnamed'}
              </p>
            </div>
            :
            !isEmpty(newlyGroup) ?
              <div style={{ display: 'flex', justifyContent: 'flex-start', padding: 10 }}>
                <PersonChat
                  participantIds={newlyGroup.userIds.filter(
                    userId => {
                      return userId !== selectedUserId;
                    }
                  )}
                  index={props.index}
                  color='#fff'
                />
              </div>
              : null
        }
      </DialogContentText>
      <DialogContent ref={(e) => (inputEl = e)} style={{ padding: 0, backgroundColor: '#F7F7FB' }} className={clsx(classes.scrollCustom, '')}>

        <MessageWindow
          loadMore={messageRepository.getMessages}
          messages={messageRepository.messages}
        ></MessageWindow>

      </DialogContent>
      <DialogActions className={classes.btnAction}>
        <MessageComposer userPrincipal={userSelectOne}></MessageComposer>
      </DialogActions>
    </Dialog >

  );
}

