import React, { createRef, useEffect, useState, useContext } from 'react';
import Select2 from 'react-select';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';
import { isEmpty, isNil, view, update } from 'ramda';
import clsx from 'clsx';
import moment from 'moment';
import MomentUtils from "@date-io/moment";
import esLocale from "date-fns/locale/es";
import DateFnsUtils from '@date-io/date-fns';

import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from "@material-ui/pickers";
import { makeStyles, createStyles, Theme, useTheme } from '@material-ui/core/styles';
import {
  Button, IconButton, Snackbar, useMediaQuery, Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions, CircularProgress,
  TextField, Grid, NoSsr, MenuItem, Typography, Paper, Slide, Select
} from '@material-ui/core';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import { theme, colors } from '../../../../theme/theme';
import CustomTextField from '../../../CustomTextField';

import GlobalContext from "../../../../hooks/GlobalContext";
import PatientTrackingContext from "../PatientTrackingContext";
import createVisit from "../../../../firebase/httpsCallable/createVisit";
import moveToroom from "../../../../firebase/httpsCallable/moveToRoom";
import addColumnTracking from "../../../../firebase/httpsCallable/addColumnTracking";
import getPatientTracking from "../../../../firebase/httpsCallable/getPatientTracking";
import { ignoreNextOnError } from '@sentry/browser/dist/helpers';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    dialog: {
      '& .MuiDialog-container .MuiDialog-paperWidthSm': {
        borderRadius: 20,
        // overflowY: 'auto',
        // overflowX: 'hidden',
        maxWidth: 544,
        width: '100%',
        background: '#fff',
        maxHeight: '80%'
        // padding: '2.5%',
      },
      '& .MuiDialog-container .MuiDialog-paperWidthSm .MuiDialogContent-root': {
        overflowY: 'auto',
        overflowX: 'hidden',
      },
    },
    typeModal: {
      background: '#197FE8',
      color: '#fff',
      padding: '7px 24px',
      textAlign: 'center'
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(0),
      padding: '4px',

      '& svg': {
        width: '1.5em',
        height: '1.5em',
      }
    },
    boxTextField: {
      width: '100%',
      display: 'flex',
      flexFlow: 'row wrap'
    },
    customTextField: {
      width: '100%',
      '& .MuiOutlinedInput-root': {
        borderRadius: 6,
        height: 56,
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.info.main,
          borderWidth: 1,
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.dark.main,
          // borderColor: '#DDE6EA',
          borderWidth: 1,
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.primary.main,
          borderWidth: 1,
        },
        '&.Mui-error .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.error.main,
          borderWidth: 1,
        },
        '&.Mui-disabled': {
          background: theme.palette.mute.main,
          color: colors.general.text,
        },

      },
      '& .MuiOutlinedInput-input': {
        fontWeight: 400,
        fontSize: 16,
        '&.Mui-disabled': {
          color: colors.general.text
        }
      },
      '& .MuiOutlinedInput-root': {
        backgroundColor: '#f5f5f5',
      },

      '& .MuiFormHelperText-root': {
        color: theme.palette.error.main,
        marginTop: 4,
        fontWeight: 500,
        textAlign: 'right',
      },
      '& .MuiFormLabel-root': {
        color: colors.general.text,
        fontWeight: 400,
        fontSize: 16,
        '&.Mui-focused': {
          color: theme.palette.primary.main,
          fontWeight: 500,
        },
        '&.Mui-error': {
          color: theme.palette.error.main,
          fontWeight: 500,
        },
      },
      '&:hover .MuiFormLabel-root': {
        color: theme.palette.dark.main
      },
    },
    inputTimeCustom: {
      display: 'flex',
      // marginTop: '10px',
      marginBottom: '8px',
      marginRight: '8px',

      '& .MuiFormLabel-root': {
        fontSize: '14px',
        top: '-4px',
        fontWeight: 'bold',
        color: '#000',
      },
      '& .MuiFormLabel-filled': {
        top: '0px',
      },
      '& .MuiInputBase-root': {
        paddingLeft: 0,
        backgroundColor: '#f5f5f5',
      },
      '& .MuiInputBase-input': {
        padding: '14px 16px',
        fontSize: ' 14px',
      },
      '& .Mui-disabled': {
        // background: '#f1f1f1',
        // color: '#000'
      },
    },
    selectCustom: {
      '& .MuiMenuItem-root': {
        fontSize: '14px',
        textTransform: 'uppercase',
      },
      '& .MuiFormLabel-root': {
        fontWeight: 'bold',
        color: '#000',
      },

    },
    selectRequired: {
      fontSize: '0.75rem',
      color: '#f44336',
      marginLeft: 14,
      marginRight: 14,
      marginTop: 4,
    },
    text: {
      margin: 0,
      padding: 0,
      color: '#030303',
      textAlign: 'left',
      fontSize: 12,
    },
    btnAction: {
      justifyContent: 'space-between',
      padding: '8px 24px',
      border: '1px solid #cecaca',

      '& button': {

      }
    },

    scrollCustom: {

      background: '#fff',
      overflowY: 'scroll',

      '&::-webkit-scrollbar': {
        backgroundColor: '#fff',
        width: 12,     /* Tamaño del scroll en vertical */
        height: 12,    /* Tamaño del scroll en horizontal */
        // display: 'none',  /* Ocultar scroll */
      },

      /* background of the scrollbar except button or resizer */
      '&::-webkit-scrollbar-track': { backgroundColor: '#fff' },

      '&::-webkit-scrollbar-track:hover': { backgroundColor: '#f4f4f4' },

      /* scrollbar itself */
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#babac0',
        borderRadius: 16,
        border: '4px solid #fff',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#a0a0a5',
        border: '4px solid #f4f4f4'
      },

      /* set button(top and bottom of the scrollbar) */
      '&::-webkit-scrollbar-button': { display: 'none' },
    },

    boxCustomSchedule: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    boxOccurr: {
      // backgroundColor: 'red',
      width: '100%',
      // height: 50,
      '& p': {
        padding: 0,
        marginTop: -3,
        position: 'absolute',
        fontSize: 12,
      }
    },
    selected: {
      padding: 0,
      margin: 0,
      // marginTop: 15,
      width: '100%',
      height: 40,
      backgroundColor: "#f5f5f5",

    },
    textSelect: {
      margin: 0,
      padding: 0,
      color: '#030303',
      textAlign: 'left',
      fontSize: 12,
    },
    inputCustomTime: {
      display: 'flex',
      // marginTop: '8px',
      // marginBottom: '14px',
      // marginRight: '8px',

      '& .MuiFormLabel-root': {
        fontSize: '14px',
        top: '-4px',
        fontWeight: 'bold',
        color: '#000'
      },
      '& .MuiFormLabel-filled': {
        top: '0px',
      },
      '& .MuiInputBase-input': {
        padding: '14px',
        fontSize: ' 14px',
        backgroundColor: '#f5f5f5'
      },
    },

  })
);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogVisit(props) {

  const { visible, toggle } = props;
  const { mainCtx } = useContext(GlobalContext);
  const { patientTrackingDoc, rooms, columnLayout, visitLate } = useContext(PatientTrackingContext);
  const { defaults, visitSettings } = patientTrackingDoc;
  const { officeId } = mainCtx;
  // ----------------------------------------------------------
  let timestampNow = moment(new Date()).format("MM-DD-YYYY");
  // var date = moment('2016-10-11 18:06:03').tz('Europe/Paris').format();
  // var dateTimestamp = moment(new Date()).format("x")
  // var dateTimestamp = moment(new Date()).valueOf()
  // ----------------------------------------------------------
  const classes = useStyles();
  let inputEl = createRef();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [loading, setLoading] = useState(false);
  const [invalidDate, setInvalidDate] = useState(false);
  const [errorPatient, setErrorPatient] = useState(null);
  // ----------------------------------------------------------
  const [form, setForm] = useState({
    patientName: '',
    lastName: '',
    room: defaults && defaults.roomId ? defaults.roomId : '',
    // stage: '',
    // arrivalTime: Date.now(),
    // appointmentTime: Date.now(),
    arrivalTime: moment(new Date()).format("MM-DD-YYYY hh:mm a"),
    appointmentTime: moment(new Date()).format("MM-DD-YYYY hh:mm a"),
    appointmentLength: defaults && defaults.appointmentLength ? defaults.appointmentLength : 60,
    // dueOutTime: null,
  });
  const [formError, setFormError] = useState({
    patientName: false,
    lastName: false,
    room: false,
    arrivalTime: false,
    appointmentTime: false,
    appointmentLength: false,
  });
  // ----------------------------------------------------------
  useEffect(() => {
    if (visible) {
      document.body.style = "padding:0; overflow-y: hidden;";
      setLoading(false)
    } else {
      document.body.style = "";
    }
  }, [visible])
  // ----------------------------------------------------------
  const customStyle = {
    // option: (base, state) => ({ ...base, backgroundColor: "red" }),
    // singleValue: styles => ({ ...styles, color: 'white' }),
    menu: base => ({
      ...base,
      zIndex: 99
    }),
    // menuList: base => ({...base, color: 'white'}),
    control: styles => ({
      ...styles,
      borderColor: formError.room ? '#f44336' : '#cccccc',
      minHeight: 45,
      backgroundColor: '#f5f5f5'
    }),
  };
  // ----------------------------------------------------------

  const orderedrooms = Object.keys(rooms)
    .sort((idA, idB) => {
      const nameA = rooms[idA];
      const nameB = rooms[idB];
      if (nameA.listPosition > nameB.listPosition) {
        return 1;
      }
      if (nameA.listPosition < nameB.listPosition) {
          return -1;
      }
      return 0;
    })
    .map(id => {
      return rooms[id];
    });

  const countTime = (() => {
    if (visitSettings && visitSettings.countFrom) {
      return visitSettings.countFrom === "appointmentTime"
        ? moment(form.appointmentTime, 'MM-DD-YYYY hh:mm a').valueOf()
        : moment(form.arrivalTime, 'MM-DD-YYYY hh:mm a').valueOf();
    } else {
      return  moment(form.appointmentTime, 'MM-DD-YYYY hh:mm a').valueOf();
    }
  })();
  // ----------------------------------------------------------
  const onChangeText = (event) => setForm({ ...form, [event.target.name]: event.target.value });

  const onChangeTextRoom = (name) => (event) => {
    setForm({ ...form, [name]: `${event.target.value}` })
  };

  const handleChangeTime = (name) => (event) => {

    if(name === 'arrivalTime' ){

      if (moment(`${timestampNow} ${event.target.value}`, 'MM-DD-YYYY hh:mm a').valueOf() > Date.now()) {
        setInvalidDate(true)
        setForm({ ...form, [name]: moment(`${timestampNow} ${event.target.value}`).format('MM-DD-YYYY hh:mm a') });
      } else {
        setInvalidDate(false)
        setForm({ ...form, [name]: moment(`${timestampNow} ${event.target.value}`).format('MM-DD-YYYY hh:mm a') });
      }

    }else {
      setForm({ ...form, [name]: moment(`${timestampNow} ${event.target.value}`).format('MM-DD-YYYY hh:mm a') });
    }
  }
  // ----------------------------------------------------------
  // const handleChangeDate = (name) => (event) => {
  //   if (!isNil(event)) {
  //     setForm({ ...form, [name]: event.valueOf() });
  //   } else {
  //     setForm({ ...form, [name]: null });
  //   }
  // }
  // ----------------------------------------------------------
  // const onInputChange = (newValue) => {
  //   if (!isEmpty(newValue)) {
  //     setForm({ ...form, room: newValue })
  //   } else {
  //     setForm({ ...form, room: '' })
  //   }
  // }
  // ----------------------------------------------------------
  // const option = orderedrooms ? orderedrooms.map((room, index) => ({
  //   value: room,
  //   label: room.name
  // })) : [];
  // ----------------------------------------------------------
  const handleSave = () => {

    if (isEmpty(form.patientName) || isEmpty(form.lastName) || isEmpty(form.room) || isNil(form.arrivalTime)
      || isNil(form.appointmentTime) || isNil(form.appointmentLength) || invalidDate) {

      setFormError({
        patientName: !form.patientName,
        lastName: !form.lastName,
        room: !form.room,
        arrivalTime: !form.arrivalTime,
        appointmentTime: !form.appointmentTime,
        appointmentLength: !form.appointmentLength,
      });

    } else {

      setFormError({
        patientName: false,
        lastName: false,
        room: false,
        arrivalTime: false,
        appointmentTime: false,
        appointmentLength: false,
      });
      setLoading(true);

      let lateArrival = '';
      let lateColor = '';
      if(visitLate && visitLate.lateArrivals && visitLate.lateArrivals === 'yes'){

        const time1 = moment(form.arrivalTime, 'MM-DD-YYYY hh:mm a').valueOf();
        const time2 = moment(form.appointmentTime, 'MM-DD-YYYY hh:mm a').valueOf();
        if( time1 > time2 ){
          lateArrival = time1
          lateColor = '#df3c3c'
        }else{
          lateArrival = time2
        }

      }else{
        lateArrival =  moment(form.appointmentTime, 'MM-DD-YYYY hh:mm a').valueOf();
      }

        createVisit({
          ...form,
          arrivalTime: moment(form.arrivalTime, 'MM-DD-YYYY hh:mm a').valueOf(),
          appointmentTime: lateArrival,
          officeId: officeId,
          dueOutTime: lateArrival + form.appointmentLength * 60000,
          lateColor
        })
          .then(result => {

            if (result && result.data && result.data.status && result.data.status === 201) {
              setErrorPatient(result.data.message);
              setLoading(false);
            } else {
              moveToroom({
                officeId: officeId,
                visitId: result.data.id,
                roomId: form.room,
              })
                .then(() => {
                  setLoading(false);
                  toggle();
                })
                .catch(err => {
                  setLoading(false);
                });
            }
  
          })
          .catch(err => {
            toggle();
            setLoading(false);
          });

    }

  }

  return (
    <div>
       <Dialog
                open={Boolean(errorPatient)}
                onClose={() => {
                    setErrorPatient(null);
                }}
            >
                <DialogContent>{errorPatient}</DialogContent>
                <DialogActions>
                    <Button
                        size="small"
                        variant="contained"
                        onClick={() => {
                          setErrorPatient(null);
                        }}
                    >
                        ok
                    </Button>
                </DialogActions>
            </Dialog>
  
    <Dialog
      fullScreen={fullScreen}
      open={visible}
      keepMounted
      onClose={toggle}
      className={classes.dialog}
      // disableBackdropClick
      // disableEscapeKeyDown
      aria-labelledby="responsive-dialog-title"
      TransitionComponent={Transition}
    >
      <DialogTitle id="responsive-dialog-title" className={classes.typeModal}>NEW PATIENT
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={toggle}
          className={classes.closeButton}
        >
          <HighlightOffIcon />
        </IconButton>

      </DialogTitle>
      <DialogContent ref={(e) => (inputEl = e)} className={clsx(classes.scrollCustom, '')}>
        {/* <DialogContentText>Let Google help apps determine location.</DialogContentText> */}

        <Grid className={clsx(classes.boxTextField, '')} container spacing={1}>
          <Grid item xs={12} sm={6}>
            <CustomTextField
              // label="Name"
              // placeholder="Name"
              labelText="Name"
              variant="outlined" // outlined, filled, 
              className={classes.customTextField}
              mt={0}
              mb={0}
              mr={5}
              textColor="#030303"
              name="patientName"
              id="name"
              value={form.patientName}
              error={formError.patientName}
              helperText="required"
              // required={true}
              onChangeText={onChangeText}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTextField
              // label="Name"
              // placeholder="Name"
              labelText="Last Name"
              variant="outlined" // outlined, filled, 
              className={classes.customTextField}
              mt={0}
              mb={0}
              mr={5}
              textColor="#030303"
              name="lastName"
              id="lastName"
              value={form.lastName}
              error={formError.lastName}
              helperText="required"
              // required={true}
              onChangeText={onChangeText}
            />
          </Grid>
          <Grid item xs={12} sm={12} >
            {/* <p className={classes.text}>Room</p>
            <NoSsr>
              <Select2
                styles={customStyle}
                // classes={classes}
                className={clsx(classes.selectCustom, '')}
                inputId="react-select-room"
                placeholder={""}
                // noOptionsMessage={() => 'Sin resultados'}
                options={option}
                value={form.room}
                // defaultValue={}
                // onInputChange={onInputChangeAsync}
                onChange={onInputChange}
                // isLoading={loading}
                // isClearable
              // isDisabled={}
              // defaultMenuIsOpen
              />
            </NoSsr>
            {formError.room ? <div style={{ color: '#FF4848', textAlign: 'right', fontWeight: 500, margin: '4px 14px 14px 0', fontSize: '0.75rem' }}>required</div> : null} */}

            <p className={classes.textSelect}>Room</p>
            <div className={classes.boxCustomSchedule}>
              <div className={classes.boxOccurr}>
                <Select
                  labelId="room-simple-select-label"
                  id="room-simple-select"
                  value={form.room}
                  variant="outlined"
                  className={classes.selected}
                  onChange={onChangeTextRoom('room')}>
                  {
                    orderedrooms.map(roo => {
                      return (
                        <MenuItem
                          key={roo.id}
                          value={roo.id}
                        >
                          {roo.name}
                        </MenuItem>
                      );
                    })
                  }
                </Select>
              </div>
            </div>
            {formError.room ? <div style={{ color: '#FF4848', textAlign: 'right', fontWeight: 500, margin: '4px 14px 14px 0', fontSize: '0.75rem' }}>required</div> : null}
          </Grid>
          <Grid item xs={12} sm={6}>
            <p className={classes.text}>Arrival Time</p>
            <TextField
              className={classes.inputCustomTime}
              type="time"
              // value={new Date(form.arrivalTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
              value={moment(form.arrivalTime.toString(), 'MM-DD-YYYY hh:mm a').format('HH:mm')}
              onChange={handleChangeTime('arrivalTime')}
              variant="outlined"
              fullWidth
              autoComplete='off'
              error={formError.arrivalTime || invalidDate}
              InputLabelProps={{
                shrink: false,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
            // defaultValue="07:30"
            // id="time"
            // label="Alarm clock"
            />
            {formError.arrivalTime ? <div style={{ color: '#FF4848', textAlign: 'right', fontWeight: 500, margin: '4px 14px 14px 0', fontSize: '0.75rem' }}>required</div> : invalidDate ?
              <div style={{ color: '#FF4848', textAlign: 'right', fontWeight: 500, margin: '4px 14px 14px 0', fontSize: '0.75rem' }}>Future time invalid</div>
              : null}
          </Grid>
          <Grid item xs={12} sm={6}>
            <p className={classes.text}>Appointment Time</p>
            <TextField
              className={classes.inputCustomTime}
              type="time"
              // value={new Date(form.appointmentTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
              value={moment(form.appointmentTime.toString(), 'MM-DD-YYYY hh:mm a').format('HH:mm')}
              onChange={handleChangeTime('appointmentTime')}
              variant="outlined"
              fullWidth
              autoComplete='off'
              error={formError.appointmentTime}

              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
            // defaultValue="07:30"
            // id="time"
            // label="Alarm clock"
            />
            {formError.appointmentTime ? <div style={{ color: '#FF4848', textAlign: 'right', fontWeight: 500, margin: '4px 14px 14px 0', fontSize: '0.75rem' }}>required</div> : null}
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              labelText="Appointment Length (Minutes)"
              variant="outlined" // outlined, filled, 
              className={classes.customTextField}
              id="appointment-length picker"
              mt={0}
              mb={0}
              mr={5}
              textColor="#030303"
              type="number"
              name="appointmentLength"
              value={form.appointmentLength}
              error={formError.appointmentLength}
              helperText="required"
              // required={true}
              onChangeText={onChangeText}
              inputProps={{ min: 0 }}
            />
          </Grid>
          {/* <MuiPickersUtilsProvider utils={MomentUtils}>
             <Grid item xs={12} sm={6}>
              <p className={classes.text}>Arrival Time</p>
              <KeyboardTimePicker
                className={classes.inputTimeCustom}
                id="arrival-time picker"
                // label="ARRIVAL TIME"
                value={form.arrivalTime}
                onChange={handleChangeDate('arrivalTime')}
                fullWidth
                ampm={true}
                helperText={formError.arrivalTime ? "required" : 'default time'}
                inputVariant="outlined"
                disabled
                // format="yyyy-MM-dd"
                // disableFuture={true}
                // InputAdornmentProps={{ position: "start" }}
                // required
                error={formError.arrivalTime}
              /> 
          </MuiPickersUtilsProvider> */}
          <Grid item xs={12}>
            <p className={classes.text}>Due Out Time</p>
            <TextField
              className={classes.inputCustomTime}
              type="time"
              // value={new Date(countTime + form.appointmentLength * 60000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
              value={moment(countTime + form.appointmentLength * 60000).format('HH:mm')}
              onChange={e => {
                const time = e ? moment(`${timestampNow} ${e.target.value}`, 'MM-DD-YYYY hh:mm a').valueOf() : Date.now();
                setForm({ ...form, appointmentLength: Math.floor((time - countTime) / 60000) })
              }}
              variant="outlined"
              fullWidth
              autoComplete='off'
              error={formError.appointmentLength}
            // defaultValue="07:30"
            // id="time"
            // label="Alarm clock"
            />
            {formError.appointmentLength ? <div style={{ color: '#FF4848', textAlign: 'right', fontWeight: 500, margin: '4px 14px 14px 0', fontSize: '0.75rem' }}>required</div> : null}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.btnAction}>
        <Button onClick={toggle}>
          CANCEL
        </Button>
        {!loading && (
          <Button onClick={handleSave} color="primary">
            SAVE
          </Button>
        )}
        {loading && (
          <CircularProgress></CircularProgress>
        )}
      </DialogActions>
    </Dialog>
    </div>
  );
}

