import React from "react";
import AssignmentIcon from "@material-ui/icons/Assignment";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import LocalAirportIcon from "@material-ui/icons/LocalAirport";
import VisibilityIcon from "@material-ui/icons/Visibility";
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import AccessibleIcon from "@material-ui/icons/Accessible";
import GradeIcon from "@material-ui/icons/Grade";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import SearchIcon from "@material-ui/icons/Search";
import BuildIcon from "@material-ui/icons/Build";
import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";
import FaceIcon from "@material-ui/icons/Face";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import PanToolIcon from "@material-ui/icons/PanTool";

const icons = {
    none: null,
    Assignment: <AssignmentIcon style={{ fontSize: 20 }} />,
    DoneOutline: <DoneOutlineIcon style={{ fontSize: 20 }} />,
    FastFood: <FastfoodIcon style={{ fontSize: 20 }} />,
    LocalAirport: <LocalAirportIcon style={{ fontSize: 20 }} />,
    Visibility: <VisibilityIcon style={{ fontSize: 20 }} />,
    WorkOutline: <WorkOutlineIcon style={{ fontSize: 20 }} />,
    WatchLater: <WatchLaterIcon style={{ fontSize: 20 }} />,
    Accessible: <AccessibleIcon style={{ fontSize: 20 }} />,
    Grade: <GradeIcon style={{ fontSize: 20 }} />,
    HelpOutline: <HelpOutlineIcon style={{ fontSize: 20 }} />,
    Search: <SearchIcon style={{ fontSize: 20 }} />,
    Build: <BuildIcon style={{ fontSize: 20 }} />,
    AssignmentLate: <AssignmentLateIcon style={{ fontSize: 20 }} />,
    Face: <FaceIcon style={{ fontSize: 20 }} />,
    HourglassEmpty: <HourglassEmptyIcon style={{ fontSize: 20 }} />,
    PanTool: <PanToolIcon style={{ fontSize: 20 }} />
};

export default function(props) {
    const { iconId } = props;
    if (!icons[iconId]) {
        return icons["none"];
    } else {
        return icons[iconId];
    }
}
