import firebase from "../firebase";

export default function({ stageId, roomId, officeId, visitId, email, patient }) {

    return firebase.functions().httpsCallable("allFunctions")({
        funcRole: "patientTrackingUser",
        funcName: "emailMessages",
        data: {
            officeId,
            visitId,
            email,
            stageId, 
            roomId,
            patient
        }
    });
}
