import React, { useContext, useEffect } from "react";
import { CardContent, CardHeader, Card, Grid, Typography, Button } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import GlobalContext from "../../../hooks/GlobalContext";
import OfficesList from "./OfficesList";

const useStyles = makeStyles({
    button: {
        margin: 5,
        fontSize: 12,
        backgroundColor: '#f7f7fb',
        color: 'black',
        padding: '10px 12px',
        // border: '1px solid #dcd9d9',
        borderRadius: 5,
        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
    },
});

export default function (props) {

    const {
        authCtx
    } = useContext(GlobalContext);
    const classes = useStyles();

    return (
        <Grid
            container
            align="center"
            justifyContent="center"
            style={{ marginTop: "10px" }}
        >
            {authCtx.isSuperAdmin && (
                <Grid item xs={12} style={{ margin: 20}}>
                    <Typography
                        variant="contained"
                        className={classes.button}
                    >
                        SUPERADMIN
                    </Typography>
                </Grid>
            )}

            <Grid item xs={12} md={6} lg={6}>
                <Card align="center">
                    <CardHeader title="Offices" />
                    <CardContent>
                        <OfficesList />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}