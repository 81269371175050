import React, { useContext, useEffect, useRef } from "react";
import { format } from "date-fns";

import { makeStyles } from '@material-ui/core/styles';
import { Card, IconButton, Tooltip } from "@material-ui/core";

import CheckIcon from "@material-ui/icons/Check";
import PersonIcon from '@material-ui/icons/Person';

import MessagingContext from "../MessagingContext";
import markAsRead from "../../../../firebase/httpsCallable/markAsRead";
import GlobalContext from "../../../../hooks/GlobalContext";


const useStyles = makeStyles({
    root: {

    },
    titleMessage: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    contentMessage: {
        fontSize: 13,
        fontWeight: 300,
        color: '#3A3E4B',
    },

});

export default function (props) {
    const { message: myMessage } = props;
    const messageState = useMessageState(myMessage);
    const {
        senderName,
        text,
        time,
        date,
        isUnread,
        isFromMe,
        isLatestMessage,
        tags,
        markAsReadHandler
    } = messageState;

    const classes = useStyles();
    const scrollRef = useRef(null);

    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollIntoView({ behavior: "smooth", block: 'end' });
        }

        return () => {
            scrollRef.current.scrollIntoView({ behavior: "smooth", block: 'start' });
        }
    }, []);

    const fromStyle = (() => {
        if (isFromMe) {
            return {
                float: "right",
                backgroundColor: "rgba(171, 171, 171, 0.11)",
                color: "#3A3E4B",
                fontStyle: 'normal',
                fontWeight: 300,
                margin: 5
                // fontFamily: 'SF Pro Display',
                // fontSize: 13,
                // lineHeight:' 110.6%',
            };
        } else {
            return {
                float: "left",
                backgroundColor: "#fff",
                color: "#3A3E4B",
                fontStyle: 'normal',
                fontWeight: 300,
                margin: 5
            };
        }
    })();

    const readStyle = (() => {
        return { border: isUnread ? "1px solid red" : "1px solid transparent" };
    })();

    const tagsStyle = (() => {
        if (!tags || tags.length < 1) {
            return {};
        }

        if (tags.includes("addParticipant")) {
            return { backgroundColor: "#5bff64", color: "black" };
        }
        if (tags.includes("removeParticipant")) {
            return { backgroundColor: "#ff5b5b", color: "black" };
        }
        return {};
    })();

    return (
        <div>
            <Card
                style={{
                    minWidth: 340,
                    padding: 15,
                    background: 'rgba(171, 171, 171, 0.11)',
                    order: 1,
                    borderTopLeftRadius: 0,
                    boxShadow: '-1px 2px 0px #d4d4d4',
                    borderRadius: 5,

                    ...fromStyle,
                    ...tagsStyle
                    // ...readStyle,
                }}
            >
                <div className={classes.titleMessage}>
                    <div
                        style={{
                            // gridRow: "1/2",
                            // gridColumn: "1/2",
                            fontSize: 12,
                            fontWeight: 500,
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <div><PersonIcon style={{ color: isFromMe ? '#1784D3' : '#FFA858', marginRight: 3 }} /></div>
                        <div>{senderName}</div>
                    </div>
                    <div
                        style={{
                            // gridRow: "1/2",
                            // gridColumn: "3/4",
                            color: "#3A3E4B",
                            fontSize: 10,
                        }}
                    >
                        {date} {time}
                    </div>
                </div>
                <div className={classes.contentMessage}>
                    {text}
                </div>
                <div
                    style={{
                        gridRow: "3/4",
                        fontSize: "12px"
                    }}
                >
                    {isLatestMessage && isUnread && (
                        <Tooltip title="mark as read" style={{ display: 'none' }}>
                            <IconButton
                                size="small"
                                onClick={markAsReadHandler()}
                            >
                                <CheckIcon></CheckIcon>
                            </IconButton>
                        </Tooltip>
                    )}
                </div>
            </Card>
            <div ref={scrollRef} style={{ clear: "both" }}></div>
        </div>
    );
}

function useMessageState(message) {
    const messaging = useContext(MessagingContext);
    const { mainCtx } = useContext(GlobalContext);
    const conversation = messaging.getConversationById(
        messaging.selectedConversationId
    );

    let senderName = "";
    let text = "";
    let time = "";
    let date = "";
    let isUnread = "";
    let isFromMe = "";
    let isLatestMessage = "";
    let tags = [];
    let markAsReadHandler = () => { };

    if (!conversation) {
        return {
            senderName,
            text,
            time,
            date,
            isUnread,
            isFromMe,
            isLatestMessage,
            tags,
            markAsReadHandler
        };
    }

    const user = messaging.getUserById(message.senderId);

    senderName = user && user.name ? user.name : message.senderId;
    text = message.message;
    isFromMe = messaging.selectedUserId === message.senderId;

    try {
        time = format(new Date(message.time), "hh:mma");
        date = format(new Date(message.time), "MM/dd/yy");
    } catch (err) {
        console.log(time);
        console.log(err);
    }

    const lastReadTime = (() => {
        return (
            (conversation.participants &&
                conversation.participants[messaging.selectedUserId] &&
                conversation.participants[messaging.selectedUserId]
                    .lastMessage &&
                conversation.participants[messaging.selectedUserId].lastMessage
                    .time) ||
            0
        );
    })();

    isUnread = (() => {
        if (parseInt(message.time) > parseInt(lastReadTime)) {
            return true;
        }
        return false;
    })();

    isLatestMessage = message.id === conversation.latestMessage.id;

    markAsReadHandler = () => {
        const req = {
            officeId: mainCtx.officeId,
            conversationId: messaging.selectedConversationId,
            messageId: message.id,
            userId: messaging.selectedUserId
        };
        markAsRead(req);
    };

    tags = message.tags;

    return {
        senderName,
        text,
        time,
        date,
        isUnread,
        isFromMe,
        isLatestMessage,
        tags,
        markAsReadHandler
    };
}
