import { createContext } from "react";

export default createContext({
    visits: [],
    stages: [],
    rooms: [],
    columnLayout: [],
    patientTrackingDoc: {},
    sortData: [],
    visitLate: [],
    roomsPaging: [],
});
