import React, { useContext } from "react";
import { makeStyles } from '@material-ui/core/styles';

import PatientTrackingContext from "../PatientTrackingContext";

const useStyles = makeStyles((theme) => ({
    root: {
    },
    hiddenStage: {
        display: 'none'
    },
    textHeader : {
        overflowX: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        userSelect: "none",
    }
}));

export default function(props) {
    const { columnLayout } = useContext(PatientTrackingContext);
    const classes = useStyles();

    const cellDictionary = {
        Name: (
            <div key={"Name"} className={classes.textHeader} style={{borderRight: "1px solid #ffff", padding: 6 }}>
                Patient/Status
            </div>
        ),
        Room: (
            <div key={"Room"} style={{borderRight: "1px solid #ffff", padding: 6 }}>
                Room
            </div>
        ),
        Stage: (
            <div key={"Stage"} style={{borderRight: "1px solid #ffff", padding: 6 }}>
                Stage
            </div>
        ),
        ArrivalTime: (
            <div key={"ArrivalTime"} style={{borderRight: "1px solid #ffff", padding: 6 }}>
                Arrival
            </div>
        ),
        AppointmentTime: (
            <div key={"AppointmentTime"} style={{borderRight: "1px solid #ffff", padding: 6 }}>
                Appt
            </div>
        ),
        TimeElapsed: (
            <div key={"TimeElapsed"} style={{borderRight: "1px solid #ffff", padding: 6 }}>
                Total
            </div>
        ),
        TimeRemaining: (
            <div key={"TimeRemaining"} style={{borderRight: "1px solid #ffff", padding: 6 }}>
                Remn
            </div>
        ),
        TimeOnStage: (
            <div key={"TimeOnStage"} style={{borderRight: "1px solid #ffff", padding: 6 }}>
                Stage
            </div>
        )
    };

    return (
        <div
            style={{
                display: 'flex',
                textAlign: "center",
                borderTop: "1px solid #ffff",
                borderBottom: "1px solid #197fe8",
                backgroundColor: '#197fe8',
                color: '#ffffff',
                fontSize: 10,
                width: '100%',
            }}
        >
            {columnLayout.map(element => {
                return (
                 <div key={element} style={{ width: element === 'Name' ? '32%' : columnLayout.length === 7 ? '11.33%' :  columnLayout.length === 6 ?  '13.6%' : '17%' }}>
                      {cellDictionary[element]}
                </div>);
            })}
        </div>
    );
}
