import React, { useState, useContext } from "react";

import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button, ButtonBase } from "@material-ui/core";

import SendIcon from "@material-ui/icons/Send";
import PersonIcon from '@material-ui/icons/Person';

import createMessage from "../../../../firebase/httpsCallable/createMessage";

import GlobalContext from "../../../../hooks/GlobalContext";
import MessagingContext from "../MessagingContext";



const useStyles = makeStyles({
    root: {

    },
    boxSend: {
        width: '100%',
    },
    personSend: {
        padding: 20,
        color: '#000',
        width: '100%',
        borderTop: '1px solid rgba(0, 0, 0, 0.23)',
        borderBottom: '1px solid rgba(0, 0, 0, 0.23)',
        fontSize: 12,
        fontWeight: 500,
        display: 'flex',
        alignItems: 'center',
    },
    inputSend: {
        display: 'flex',
        border: 'none',
        outline: 'none',
        padding: '1em',
        color: '#666',
        fontWeight: 400,

        '& .inputMessage': {
            width: '100%',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: 51,
            justifyContent: 'center',
            padding: '0 2%',
            cursor: "pointer",
            marginRight: '0.5%',
        },
        '& .MuiInput-underline::after, .MuiInput-underline::before': {
            border: 0,
            position: 'relative',
            content: 'none',
        },
        '& .iconMessage': {
            padding: 14,
            background: '#FFA858',
            borderRadius: 51,

            '& svg': {
                color: '#fff',
            }
        }
    }
});



export default function (props) {

    const { userPrincipal } = props;
    const [message, setMessage] = useState("");
    const { mainCtx } = useContext(GlobalContext);
    const { officeId } = mainCtx;
    const { selectedConversationId, selectedUserId } = useContext(
        MessagingContext
    );
    const classes = useStyles();
    const [sending, setSending] = useState(false);

    const sendMessage = () => {
        if (message.length < 1) {
            return;
        }
        setSending(true);

        createMessage({
            officeId: officeId,
            senderId: selectedUserId,
            conversationId: selectedConversationId,
            message: message
        }).then(result => {
            setMessage("");
            setSending(false);
        });
    };
    return (
        <div className={classes.boxSend}>

            <div className={classes.personSend}>
                <PersonIcon style={{ color: '#1784D3', marginRight: 3 }} />
                {userPrincipal ? userPrincipal.name : 'Unnamed'}
            </div>
            <div className={classes.inputSend}>

                <TextField
                    className="inputMessage"
                    type="text"
                    value={message}
                    placeholder="Type a message"
                    onKeyPress={e => {
                        if (e.key === "Enter" && !sending) {
                            sendMessage();
                        }
                    }}
                    onChange={e => {
                        setMessage(e.target.value);
                    }}
                ></TextField>

                <ButtonBase
                    disabled={message.length < 1 || sending}
                    size="small"
                    variant="contained"
                    className="iconMessage"
                    onClick={sendMessage}
                    style={{ cursor: "pointer" }}
                >
                    <SendIcon></SendIcon>
                </ButtonBase>
            </div>
        </div>
    );
}
