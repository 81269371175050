import React from 'react';
import { Router, Route } from 'react-router-dom';
import mainRouterHistory from './routers/mainRouterHistory';
import { CssBaseline } from '@material-ui/core';

import SignIn from './components/Pages/SignIn/SignIn';
import Offices from './components/Pages/Offices/Offices';
import HeaderBar from './components/HeaderBar';
import GlobalContext, { useGlobalContext } from './hooks/GlobalContext';
import OfficeContainer from './components/Pages/Office/OfficeContainer';
import { ThemeProvider } from '@material-ui/styles';
import { theme } from './theme/theme';

function App() {
  const globalCtx = useGlobalContext();

  return (
    <React.Fragment>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <GlobalContext.Provider value={globalCtx}>
          <React.Fragment>
            <HeaderBar />
            <Router history={mainRouterHistory}>
              <Route exact path="/signin" component={SignIn} />
              <Route exact path="/offices" component={Offices} />
              <Route path="/office" component={OfficeContainer} />
            </Router>
          </React.Fragment>
        </GlobalContext.Provider>
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
