import React, { useContext, useState, useEffect } from "react";
import { isEmpty, isNil, view } from 'ramda';
import moment from "moment";

import GlobalContext from "../../../../../hooks/GlobalContext";
import PatientTrackingContext from "../../PatientTrackingContext";
import { VisitContext } from "../../VisitContext";

export default function (props) {
    const { roomIds, stageIds, style, textComponent } = props;
    // ----------------------------------------------------------
    const { mainCtx } = useContext(GlobalContext);
    const { rooms, stages } = useContext(PatientTrackingContext);
    const { officeId } = mainCtx;
    const visit = useContext(VisitContext);
    // ----------------------------------------------------------
    var now = moment(new Date());//now
    // ----------------------------------------------------------
    var elapsed = moment(new Date(parseInt(visit.timeOnStage)))
    var elapsed2 = moment(now, "DD/MM/YYYY HH:mm:ss").diff(moment(elapsed, "DD/MM/YYYY HH:mm:ss"));
    var elapsed3 = moment.duration(elapsed2);
    var minutesElapsed = Math.floor(elapsed3.asHours()) === 0 ?  moment.utc(elapsed2).format(":mm") : Math.floor(elapsed3.asHours()) + moment.utc(elapsed2).format(":mm");

    return (
        <React.Fragment>
            <div style={style}>
                { isNil(visit.timeOnStage) ? '-' : minutesElapsed }
            </div>
        </React.Fragment>
    );
}
