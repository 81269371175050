import React from "react";
import AssignmentIcon from "@material-ui/icons/Assignment";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import LocalAirportIcon from "@material-ui/icons/LocalAirport";
import VisibilityIcon from "@material-ui/icons/Visibility";
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import AccessibleIcon from "@material-ui/icons/Accessible";
import GradeIcon from "@material-ui/icons/Grade";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import SearchIcon from "@material-ui/icons/Search";
import BuildIcon from "@material-ui/icons/Build";
import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";
import FaceIcon from "@material-ui/icons/Face";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import PanToolIcon from "@material-ui/icons/PanTool";

const icons = {
    none: null,
    Assignment: <AssignmentIcon style={{ color: 'white' }} />,
    DoneOutline: <DoneOutlineIcon style={{ color: 'white' }} />,
    FastFood: <FastfoodIcon style={{ color: 'white' }} />,
    LocalAirport: <LocalAirportIcon style={{ color: 'white' }} />,
    Visibility: <VisibilityIcon style={{ color: 'white' }} />,
    WorkOutline: <WorkOutlineIcon style={{ color: 'white' }} />,
    WatchLater: <WatchLaterIcon style={{ color: 'white' }} />,
    Accessible: <AccessibleIcon style={{ color: 'white' }} />,
    Grade: <GradeIcon style={{ color: 'white' }} />,
    HelpOutline: <HelpOutlineIcon style={{ color: 'white' }} />,
    Search: <SearchIcon style={{ color: 'white' }} />,
    Build: <BuildIcon style={{ color: 'white' }} />,
    AssignmentLate: <AssignmentLateIcon style={{ color: 'white' }} />,
    Face: <FaceIcon style={{ color: 'white' }} />,
    HourglassEmpty: <HourglassEmptyIcon style={{ color: 'white' }} />,
    PanTool: <PanToolIcon style={{ color: 'white' }} />
};

export default function(props) {
    const { iconId } = props;
    if (!icons[iconId]) {
        return icons["none"];
    } else {
        return icons[iconId];
    }
}
