import { createContext, useState, useEffect } from 'react';
import firebase from '../firebase/firebase';
import { useDispatch } from 'react-redux';
import { init } from '../reducers/firebase/firebaseSlice';

export const authContext = createContext({
  user: null,
  claims: null,
  initializing: true,
  error: null,
  isSuperAdmin: false,
  authTime: null
});

export default function (mainRouter) {
  const [user, setUser] = useState(null);
  const [initializing, setInitializing] = useState(true);
  const [claims, setClaims] = useState(null);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [authTime, setAuthTime] = useState(null);
  const [guestUser, setGuestUser] = useState(false);
  const dispatch = useDispatch();

  async function onChange(fbuser) {
    if (fbuser) {
      const tokenResult = await firebase
        .auth()
        .currentUser.getIdTokenResult(true);

      setUser(fbuser);
      setClaims(tokenResult.claims);
      setAuthTime(new Date(tokenResult.authTime).valueOf() / 1000);
      if (
        tokenResult.claims &&
        tokenResult.claims.globalRoles &&
        tokenResult.claims.globalRoles.includes('superAdmin')
      ) {
        setIsSuperAdmin(true);
      } else {
        setIsSuperAdmin(false);
      }

      // Wait for next tick
      setTimeout(() => {
        // Init firebase state
        dispatch(init());
      }, 1);

      if (
        tokenResult.claims &&
        tokenResult.claims.officeRoles &&
        Object.keys(tokenResult.claims.officeRoles).length === 1
      ) {
        setGuestUser(true)
      } else {
        setGuestUser(false)
      }

      mainRouter.push('/offices');
    } else {
      setUser(null);
      setClaims(null);
      setGuestUser('');
      mainRouter.push('/signin');
    }
    setInitializing(false);
  }

  /*eslint-disable*/
  useEffect(() => {
    const unsub = firebase.auth().onAuthStateChanged(onChange);
    return () => unsub();
  }, []);
  /*eslint-enable*/
  return { user, claims, initializing, isSuperAdmin, authTime, guestUser };
}
