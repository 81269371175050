import React, { useContext } from "react";
import RoomContext from "./RoomContext";
import { ButtonBase, Tooltip } from "@material-ui/core";
import unpagePersonFromRoom from "../../../../firebase/httpsCallable/unpagePersonFromRoom";
import GlobalContext from "../../../../hooks/GlobalContext";

export default function(props) {
    const { persons, setIndex, room } = useContext(RoomContext);
    const { mainCtx, pagingState, pagingDoc } = useContext(GlobalContext);
    const { persons: allPersons } = pagingDoc;
    const { setSelectedPagingPersonId, selectedPagingPersonId } = pagingState;
    return (
        <div
            className="tabsContainer"
            style={{
                display: "grid",
                gridTemplateColumns: `repeat(${
                    Object.keys(persons).length
                }, minmax(0,1fr))`,
                minHeight: 0,
                minWidth: 0
            }}
        >
            {Object.keys(persons).map((personId, index) => {
                const timePassed = Math.floor(
                    (Date.now().valueOf() -
                        parseInt(persons[personId].pagedSince)) /
                        1000 /
                        60
                );
                return (
                    <Tooltip
                        key={personId}
                        title={
                            selectedPagingPersonId && allPersons[personId] && allPersons[personId].name
                                ? allPersons[personId].name
                                : ""
                                // `${allPersons[personId].name}`
                        }
                    >
                        <ButtonBase
                            onMouseEnter={() => {
                                setIndex(index);
                            }}
                            onClick={() => {
                                if (!selectedPagingPersonId) {
                                    unpagePersonFromRoom({
                                        officeId: mainCtx.officeId,
                                        personId: personId,
                                        roomId: room.roomId
                                    });
                                    setSelectedPagingPersonId(null);
                                }
                            }}
                            style={{
                                minWidth: 0,
                                minHeight: 0,
                                textAlign: "center",
                                // overflowX: "hidden",
                                overflow: "hidden",
                                backgroundColor: persons[personId].bgColor,
                                color: persons[personId].textColor
                            }}
                        >
                            {timePassed}
                        </ButtonBase>
                    </Tooltip>
                );
            })}
        </div>
    );
}
