import { useState, useEffect, useContext } from "react";
import { useQueryListener } from "./queryListener";
import GlobalContext from "../../../hooks/GlobalContext";

export default function(props) {
    const { mainCtx } = useContext(GlobalContext);
    const { officeId } = mainCtx;
    const visitQueryListener = useQueryListener();

    useEffect(() => {
        visitQueryListener.subscribe(
            `/offices/${officeId}/app/patientTracking/visits`,
            [["where", "completed", "==", false]]
        );
    }, []);

    return {
        visits: visitQueryListener.data,
        loading: visitQueryListener.loading
    };
}
