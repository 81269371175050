import {createMuiTheme, createTheme} from "@material-ui/core/styles";

const insyncColors = ["#0276fd", "#97cc04", "#ebebeb", "#f4d01", "#8f9491"];

export const theme = createTheme({
    // typography: {
    //     fontFamily: 'Roboto'
    // },
    palette: {
        // type: 'light',
        primary: {
            light: "#757ce8",
            main: insyncColors[0],
            dark: "#002884",
            contrastText: "#fff"
        },
        // secondary: {
        //     light: "#757ce8",
        //     main: insyncColors[3],
        //     dark: "#002884",
        //     contrastText: "#fff"
        // }

        // --------------------------------
        // --------------------------------
        // primary: {
        //     main: '#007bff', // primary
        //     dark: '#1C275A',
        //     light: '#188AB6',
        //     contrastText: '#FFC93D',
        // },
        // secondary: {
        //     // main: '#333333',
        //     main: '#6c757d', // secondary
        //     dark: '#747B87',
        //     light: '#D1D9EB',
        //     contrastText: '#E4E7F8',
        // },
        success: {
            main: '#4CAF50',
            // main: '#28a745', // success
        },
        background: {
            default: '#FFFFFF',
            paper: '#F7F7FB',
        },
        error: {
            main: '#FF4848',
            // main: '#dc3545', // danger
        },
        warning: {
            main: '#ffc107',
        },
        info: {
            main: '#17a2b8',
        },
        dark: {
            main: '#343a40',
        },
        light: {
            main: '#f8f9fa',
        },
        white: {
            main: '#fff',
        },
        mute: {
            main: '#6c757d',
        },
        // action: {
        //     active: '#1535F2',
        //     hover: '#1330D9',
        //     disabled: '#F1F3FF',
        //     disabledBackground: '#F4F5F5',
        //     focus: '#D8DBEB',
        // },
    }
});

export const colors  = {
    general: {
        hover: '#DFE2F2',
        text: '#B7BECD',
        buttonBg: '#EFF1FF',
        contrastText: '#F0B622',
        contrastBgP: '#FFEEDA',
        contrastBgS: '#C6FFFF',
        bggradient: '#4BDCDC',
        bgBtngradient: '#41CAFC',
        black: '#000000',
        textPrimary: '#112BC4',
        textSecondary: '#FFFFFF',
        textHint: '#188AB6',
        hoverSidebar: '#0E24A6'
    }
  }