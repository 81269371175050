import React, { useContext } from "react";
import { Resizable } from "re-resizable";
import { isEmpty, descend, prop, sortWith, ascend, concat } from "ramda";
import { makeStyles } from '@material-ui/core/styles';

import GlobalContext from "../../../../hooks/GlobalContext";

import styled from "styled-components";
import Masonry from "react-masonry-component";

import Room from "./Room";

const Content = styled(Masonry)`
  width: 100%;

  & #MasonryId {
    width: ${props => (100 - 2 * props.columns) / props.columns}%;
    // margin: 5px;
  }
`;

const useStyles = makeStyles((theme) => ({
    root: {
    },
}));
export default function (props) {
    const rooms = props.rooms;
    const { localStore } = useContext(GlobalContext);
    const classes = useStyles();

    return (
        <div
            style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <Content columns={getSortedRoomIds1(rooms).length <= 4 ? 1 : 2}>
                {getSortedRoomIds1(rooms).map(({ id, ...roomId }, index) => {
                    return (
                        <Room
                            key={index}
                            room={{ ...roomId, roomId: id }}
                            cantRooms={getSortedRoomIds1(rooms).length}
                            index={index}
                        />
                    );
                })}
            </Content>
        </div>
    );
}

function getSortedRoomIds1(rooms) {
    let newArray = []
    const ageNameSort = sortWith([
        descend(prop('cant')),
    ]);
    Object.keys(rooms).filter(item => !isEmpty(rooms[item].persons)).map((key, index) => {
        newArray = newArray.concat({ ...rooms[key], id: key, cant: Object.keys(rooms[key].persons).length })
    });
    // return ageNameSort(newArray);
    return newArray;
}
function getSortedRoomIds(rooms) {
    return Object.keys(rooms)
        .filter(roomId => {
            return Object.keys(rooms).includes(roomId);
        })
        .sort((a, b) => {
            if (rooms[a].name > rooms[b].name) {
                return 1;
            }
            if (rooms[a].name < rooms[b].name) {
                return -1;
            }
            return 0;
        })
        .sort((a, b) => {
            const roomA = { ...rooms[a] };
            const roomB = { ...rooms[b] };
            roomA.listPosition = roomA.listPosition ? roomA.listPosition : 0;
            roomB.listPosition = roomB.listPosition ? roomB.listPosition : 0;

            return roomA.listPosition - roomB.listPosition;
        }).filter(key => !isEmpty(rooms[key].persons));
}
