import firebase from "../firebase";

export default function({ officeId, conversationId, name }) {
    return firebase.functions().httpsCallable("allFunctions")({
        funcRole: "messagingUser",
        funcName: "setConversationName",
        data: {
            officeId,
            conversationId,
            name
        }
    });
}
