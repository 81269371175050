import React, { useContext, useState, useEffect } from "react";
import { isEmpty } from 'ramda';

import {
    makeStyles, Toolbar, Button, IconButton, Tooltip, Menu, MenuItem, List, ListItem, ListItemText, ListItemIcon,
    ListSubheader, Collapse, Checkbox, FormControlLabel, FormGroup
} from "@material-ui/core";

import AddIcon from '@material-ui/icons/Add';
import ListIcon from '@material-ui/icons/List';
import MenuIcon from '@material-ui/icons/Menu';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import GlobalContext from "../../../hooks/GlobalContext";
import PatientTrackingContext from "./PatientTrackingContext";

const useStyles = makeStyles((theme) => ({
    root: {
    },
    boxTool: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
        paddingLeft: 5,
        paddingRight: 20,
        backgroundColor: '#197fe8',
        borderTopLeftRadius: 6,
        borderTopRightRadius: 6,

        '& p': {
            fontSize: 12,
            color: '#fff',
            margin: 0
        }
    },
    addCustom: {
        // color: '#197FE8',
        minWidth: 0,
        padding: 0,
        textTransform: 'none'
    },

    rootList: {
        width: '100%',

        '& .MuiListItem-button': {
            padding: 0
        }
    },
    menuItemCustom: {
        fontSize: 12,
        minHeight: 25,
        padding: '0 32px',

        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    nested: {

        '& .MuiListItemText-root span': {
            fontSize: 12
        },
        '& .MuiListItemIcon-root': {
            minWidth: 40
        }
    },
    nestedForm: {

        '& .MuiFormControlLabel-label': {
            fontSize: 12
        },
    },

}));

export default function (props) {
    const { mainCtx, localStore } = useContext(GlobalContext);
    const { toggleVisit } = props;
    const classes = useStyles();
    // ----------------------------------------------------------
    const { stages } = useContext(PatientTrackingContext);
    // ----------------------------------------------------------
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [openHeader, setOpenHeader] = useState(false);
    const [checked, setChecked] = useState([]);
    const [checkedAll, setCheckedAll] = useState(false);
    const [checkedHeader, setCheckedHeader] = useState({
        all: false,
        active: false
    });
    // ----------------------------------------------------------
    useEffect(() => {
        if (anchorEl) {
            document.body.style = "overflowX: hidden;";
        } else {
            document.body.style = "";
        }
    }, [anchorEl])

    useEffect(() => {
        if (!isEmpty(localStore.data.patientTrackingfilter)) {
            setChecked(localStore.data.patientTrackingfilter);
        } else {
            setChecked([]);
        }

        if (!isEmpty(localStore.data.showPatientTrackingStageHeaders)) {
            const data = localStore.data.showPatientTrackingStageHeaders
            setCheckedHeader({ all: data === 1 ? true : false, active: data === 2 ? true : false });
        } else {
            setCheckedHeader({ all: false, active: false });
        }
    }, [anchorEl])
    // ----------------------------------------------------------
    const handleClick = () => {
        setOpen(!open);
    };
    const handleHeaderClick = () => {
        setOpenHeader(!openHeader);
    };

    const handleChange = (event) => {

        if (event.target.checked) {
            const resul = stages.map(e => e.id);
            localStore.update(
                localStore.storageKeys.patientTrackingfilter,
                resul
            );
            setChecked(resul);
        } else {

            localStore.update(
                localStore.storageKeys.patientTrackingfilter,
                []
            );
            setChecked([]);
        }
        setCheckedAll(event.target.checked);

    };

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        localStore.update(
            localStore.storageKeys.patientTrackingfilter,
            newChecked
        );
        setCheckedAll(false);
        setChecked(newChecked);
    };

    const handleToggleHeader = (value) => (event) => {

        if (value === 'showAll') {
            setCheckedHeader({ all: event.target.checked, active: false })
            if (event.target.checked) {
                localStore.update(localStore.storageKeys.showPatientTrackingStageHeaders,
                    1
                );
            } else {
                localStore.update(localStore.storageKeys.showPatientTrackingStageHeaders,
                    0
                );
            }
        } else {
            // showActive
            setCheckedHeader({ all: false, active: event.target.checked })
            if (event.target.checked) {
                localStore.update(localStore.storageKeys.showPatientTrackingStageHeaders,
                    2
                );
            } else {
                localStore.update(localStore.storageKeys.showPatientTrackingStageHeaders,
                    0
                );
            }

        }
    };
    // ----------------------------------------------------------

    return (
        <Toolbar disableGutters style={{ minHeight: "0px" }}>

            <div className={classes.boxTool}>

                <Button className={classes.addCustom} onClick={toggleVisit}>
                    <AddIcon style={{ color: '#fff' }} />
                </Button>

                <IconButton
                    style={{ padding: 0 }}
                    onClick={e => {
                        setAnchorEl(e.currentTarget);
                    }}
                >
                    <MenuIcon style={{ color: '#ffffff' }} />
                </IconButton>

                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={() => {
                        setAnchorEl(null);
                        setOpen(false)
                        setOpenHeader(false)
                    }}
                >
                    <MenuItem className={classes.menuItemCustom}>
                        <List
                            component="nav"
                            aria-labelledby="nested-list-subheader"
                            className={classes.rootList}
                        >
                            <ListItem button onClick={handleClick} className={classes.nested}>
                                {/* <ListItemIcon></ListItemIcon> */}
                                <ListItemText primary="Filter Stage" />
                                {open ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>

                            <Collapse in={open} timeout="auto" unmountOnExit>
                                <FormControlLabel
                                    className={classes.nestedForm}
                                    control={<Checkbox color="primary" checked={checkedAll} onChange={handleChange} name="select" />}
                                    label="Select/Deselect All"
                                />
                                <List component="div" disablePadding>
                                    {stages.sort((idA, idB) => {
                                        return idA && idA.name &&  idA.name.localeCompare(
                                            idB.name
                                        );
                                    }).map((stage, index) => {
                                        const labelId = `checkbox-list-label-${stage.id}`;
                                        return (
                                            <ListItem dense button role={undefined} className={classes.nested} key={index} onClick={handleToggle(stage.id)}>
                                                <ListItemIcon>
                                                    <Checkbox
                                                        color="primary"
                                                        edge="start"
                                                        checked={checked.indexOf(stage.id) !== -1}
                                                        tabIndex={-1}
                                                        disableRipple
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText id={labelId} primary={`${stage.name}`} />
                                            </ListItem>
                                        )
                                    })
                                    }
                                </List>
                            </Collapse>

                        </List>
                    </MenuItem>
                    <MenuItem className={classes.menuItemCustom}>

                        <List
                            component="nav"
                            aria-labelledby="nested-list-toogleheader"
                            className={classes.rootList}
                        >

                            <ListItem button onClick={handleHeaderClick} className={classes.nested}>
                                {/* <ListItemIcon></ListItemIcon> */}
                                <ListItemText primary="Toggle headers" />
                                {openHeader ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>

                            <Collapse in={openHeader} timeout="auto" unmountOnExit>
                                <FormGroup>
                                    <FormControlLabel
                                        className={classes.nestedForm}
                                        control={<Checkbox color="primary" checked={checkedHeader.all} onChange={handleToggleHeader('showAll')} name="all" />}
                                        label="Show All"
                                    />
                                    <FormControlLabel
                                        className={classes.nestedForm}
                                        control={<Checkbox color="primary" checked={checkedHeader.active} onChange={handleToggleHeader('showActive')} name="active" />}
                                        label="Show Active"
                                    />
                                </FormGroup>
                            </Collapse>

                        </List>

                    </MenuItem>
                </Menu>

            </div>

        </Toolbar >
    );
}
