import React, { useContext } from 'react';
import { format, differenceInDays } from "date-fns";
import moment from "moment"
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';

import Tabs from './Tabs'
import MeaningIcons from './MeaningIcons'
import RoomContext from './RoomContext'
import GlobalContext from '../../../../hooks/GlobalContext';
import unpageRoomFromPerson from "../../../../firebase/httpsCallable/unpageRoomFromPerson";


const useStyles = makeStyles({
    root: {
        minHeight: props => props.cantPerson < 1 ? 30 : 33,
        width: '100%',
        backgroundColor: (props) => props.rooms.bgColor,
        borderRadius: 4,
        display: 'flex',
        justifyContent: (props) => props.rooms.meaningButtonId !== 'none' ? 'space-between' : 'center',
        alignItems: 'center',
        cursor: 'pointer',
        paddingRight: 10,
        paddingLeft: 10,
        marginTop: 2,
        marginBottom: 2,
    },
    boxInfo: {
        color: 'white',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',

        '& p': {
            margin: 0,
            padding: 0,
            textAlign: 'center',
        },
        '& .p-name': {
            fontWeight: 'bold',
            fontSize: 14,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            marginLeft: 10,
            marginRigth: 10,
        },
        '& .p-time': {
            fontSize: 14,
        },
        '& .p-icon': {
            display: 'flex',

            '& svg': {
                fontSize: 18,
            }
        },
    },
    boxIconTwo: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',

        '& .boxIconInt': {
            borderRadius: 15,
            width: 23,
            height: 23,
            backgroundColor: '#fff',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '-19px',

            '& svg': {
                // color: (props) => `${props.person.bgColor} !important`,
                color: '#fff',
                fontSize: 14
            }
        },
    },
});

function getWidth(p, i) {
    if (p <= 3) {
        switch (p) {
            case 1: return '100%'
            case 2: return '49.7%'
            case 3: return '33%'
            default: return '100%'
        }
    } else {
        if (p === 4) {
            switch (i) {
                case 0: return '33%'
                case 1: return '33%'
                case 2: return '33%'
                case 3: return '100%'
                default: return '100%'
            }
        } else if (p === 5) {
            switch (i) {
                case 0: return '33%'
                case 1: return '33%'
                case 2: return '33%'
                case 3: return '49.7%'
                case 4: return '49.7%'
                default: return '100%'
            }
        } else if (p === 6) {
            return '33%'
        } else if (p === 7) {
            switch (i) {
                case 0: return '33%'
                case 1: return '33%'
                case 2: return '33%'
                case 3: return '24.7%'
                case 4: return '24.7%'
                case 5: return '24.7%'
                case 6: return '24.7%'
                default: return '100%'
            }
        } else if (p === 8) {
            return '24.7%'
        } else if (p === 9) {
            switch (i) {
                case 0: return '59%'
                case 4: return '59%'
                default:
                    return '9%'
            }
        } else if (p === 10) {
            switch (i) {
                case 0: return '59%'
                case 5: return '59%'
                default:
                    return '9%'
            }
        } else if (p === 11) {
            switch (i) {
                case 0: return '59%'
                case 5: return '59%'
                default:
                    return '5%'
            }
        } else if (p === 12) {
            switch (i) {
                case 0: return '59%'
                case 7: return '59%'
                default:
                    return '9%'
            }
        }
    }

}

export default (props) => {
    const { rooms, cantPerson } = props;
    const { mainCtx, pagingDoc, pagingState } = useContext(GlobalContext);
    const { officeId } = mainCtx;
    const { selectedPagingPersonId, setSelectedPagingPersonId } = pagingState;
    const { displayedPersonId, persons, room } = useContext(RoomContext);
    const displayedPerson = persons[displayedPersonId];
    const { meaningButtons } = pagingDoc;
    const classes = useStyles(props);

    var now = moment(new Date());//now
    var end = moment(new Date(parseInt(room[rooms.item].time)));
    var ms2 = moment(now, "DD/MM/YYYY HH:mm:ss").diff(moment(end, "DD/MM/YYYY HH:mm:ss"));
    var d2 = moment.duration(ms2 > 0 ? ms2 : 0);
    var elapsedTime = Math.floor(d2.asHours()) + moment.utc(ms2 > 0 ? ms2 : 0).format(":mm");
    // ------------------------------------------------------------------------------------------

    return (
        <div
            className={classes.root}
            onClick={() => {
                if (!selectedPagingPersonId) {

                    // console.log('rooms selected', rooms.item)
                    // console.log('person selected', persons.personId)
                    unpageRoomFromPerson({
                        officeId,
                        userId: persons.userId,
                        roomId: rooms.item,
                    });
                    setSelectedPagingPersonId(null);
                }
            }}
        >

            <div className={classes.boxInfo}>
                <p className='p-time'>{elapsedTime}</p>
                <p className='p-name'>
                    <Tooltip
                        disabled
                        disableFocusListener
                        key={rooms.name}
                        title={rooms.name}
                    >
                        <span>{rooms.name}</span>
                    </Tooltip>
                </p>
                {meaningButtons[rooms.meaningButtonId] && meaningButtons[rooms.meaningButtonId].iconId ?
                    <Tooltip
                        disabled
                        disableFocusListener
                        key={meaningButtons[rooms.meaningButtonId].name}
                        title={meaningButtons[rooms.meaningButtonId].name}
                    >
                        <p className='p-icon'>
                            <MeaningIcons
                                iconId={meaningButtons[rooms.meaningButtonId].iconId}
                            />
                        </p>
                    </Tooltip>
                    : <p></p>}
            </div>
        </div>
    );
}