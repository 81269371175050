import React, { createRef, useEffect, useState, useContext } from 'react';
import Select2 from 'react-select';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';
import { isEmpty, isNil, view, update } from 'ramda';
import clsx from 'clsx';
import MomentUtils from "@date-io/moment";
import esLocale from "date-fns/locale/es";
import DateFnsUtils from '@date-io/date-fns';

import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from "@material-ui/pickers";
import { makeStyles, createStyles, Theme, useTheme } from '@material-ui/core/styles';
import {
  Button, IconButton, Snackbar, useMediaQuery, Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions, CircularProgress,
  TextField, Grid, NoSsr, MenuItem, Typography, Paper, Slide 
} from '@material-ui/core';
import { theme, colors } from '../../../../theme/theme';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import GlobalContext from "../../../../hooks/GlobalContext";
import MessagingContext from "../../Messaging/MessagingContext";

import finger from '../../../../assets/finger.png'
import CustomTextField from '../../../CustomTextField';


const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    dialog: {
      '& .MuiDialog-container .MuiDialog-paperWidthSm': {
        borderRadius: 20,
        // overflowY: 'auto',
        // overflowX: 'hidden',
        maxWidth: 544,
        width: '100%',
        background: '#fff',
        height: '35%'
        // padding: '2.5%',
      },
      '& .MuiDialog-container .MuiDialog-paperWidthSm .MuiDialogContent-root': {
        overflowY: 'auto',
        overflowX: 'hidden',
      },
    },
    typeModal: {
      background: '#F86F46',
      color: '#fff',
      padding: '7px 24px',
      textAlign: 'center'
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(0),
      padding: '4px',

      '& svg': {
        width: '1.5em',
        height: '1.5em',
      }
    },
    text: {
      margin: 0,
      padding: 0,
      color: '#030303',
      textAlign: 'left',
      fontSize: 12,
    },
    btnAction: {
      padding: '8px 24px',
      border: '1px solid #cecaca',

      '& button': {

      },
      '& .btnSave': {
        background: '#197FE8',
        color: '#fff',
        width: '100%'
      }
    },

    boxTitle: {
      display: 'flex',
      width: '98%',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: 20,
      marginBottom: 10,
    },
    textSub: {
      fontWeight: 400,
      fontSize: 12,
      margin: 0,
      padding: 0,
      color: '#3A3E4B'
    },
    textTitle: {
      fontWeight: 400,
      fontSize: 19,
      margin: 0,
      padding: 0,
      color: '#3A3E4B',
      marginLeft: 20
    },

    customTextField : {
      width: '100%',
      '& .MuiOutlinedInput-root': {
        borderRadius: 6,
        height: 56,
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.info.main,
          borderWidth: 1,
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.dark.main,
          // borderColor: '#DDE6EA',
          borderWidth: 1,
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.primary.main,
          borderWidth: 1,
        },
        '&.Mui-error .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.error.main,
          borderWidth: 1,
        },
        '&.Mui-disabled': {
          background: theme.palette.mute.main,
          color: colors.general.text,
        },
  
      },
      '& .MuiOutlinedInput-input': {
        fontWeight: 400,
        fontSize: 16,
        '&.Mui-disabled': {
          color: colors.general.text
        }
      },
      '& .MuiOutlinedInput-root': {
        backgroundColor: 'white',
      },

      '& .MuiFormHelperText-root': {
        color: theme.palette.error.main,
        marginTop: 4,
        fontWeight: 500,
        textAlign: 'right',
      },
      '& .MuiFormLabel-root': {
        color: colors.general.text,
        fontWeight: 400,
        fontSize: 16,
        '&.Mui-focused': {
          color: theme.palette.primary.main,
          fontWeight: 500,
        },
        '&.Mui-error': {
          color: theme.palette.error.main,
          fontWeight: 500,
        },
      },
      '&:hover .MuiFormLabel-root': {
        color: theme.palette.dark.main
      },
  },

  })
);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function (props) {

  const { visible, toggle, person, handleSavePassword, form, formError, onChangeText, loading, stateVerify } = props;
  const { mainCtx } = useContext(GlobalContext);
  // ----------------------------------------------------------
  const classes = useStyles();
  let inputEl = createRef();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  // ----------------------------------------------------------

  return (

    <Dialog
      fullScreen={fullScreen}
      open={visible}
      keepMounted
      // onClose={toggle}
      className={classes.dialog}
      // disableBackdropClick
      // disableEscapeKeyDown
      aria-labelledby="responsive-dialog-title"
      TransitionComponent={Transition}
    >
      <DialogTitle id="responsive-dialog-title" className={classes.typeModal}>{person.name} CONVERSATION
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={toggle}
          className={classes.closeButton}
        >
          <HighlightOffIcon />
        </IconButton>

      </DialogTitle>
      <DialogContent ref={(e) => (inputEl = e)}>
        {/* <DialogContentText> </DialogContentText> */}
        <div className={classes.boxTitle}>
          <div style={{ display: 'flex', alignItems: 'center'}}>
              <img src={finger} style={{ width: 33, height: 40 }} />
              <p className={classes.textTitle}>Please enter the password</p>
          </div>
        </div>

        <div style={{ width: '100%' }}>
          <CustomTextField
              // label="Write a name"
              // style={{ backgroundColor: 'white'}}
              placeholder="Password"
              variant="outlined" // outlined, filled, 
              className={classes.customTextField}
              mt={10}
              mb={10}
              mr={5}
              name="password"
              type="password"
              value={form.password}
              error={Boolean((formError.password) || stateVerify)}
              helperText={   formError.password ? "required" : stateVerify ? 'Password do not match' : null} 
              // required={true}
              onChangeText={onChangeText}
              onKeyPress={ev => {
                if (ev.key === "Enter") {
                  // ev.preventDefault();
                  document.getElementById("send").click();
                }
              }}
          />
        </div>

      </DialogContent>
      <DialogActions className={classes.btnAction}>
        {/* <Button onClick={toggle}>
          CANCEL
        </Button> */}
        {!loading && (
          <Button onClick={handleSavePassword(person)} className="btnSave" id="send">
            SEND
          </Button>
        )}
        {loading && (
          <CircularProgress></CircularProgress>
        )}
      </DialogActions>
    </Dialog>

  );
}

