import firebase from "../firebase";

export default function({
    officeId,
    keepPeople,
    hostname,
    user,
}) {
    return firebase.functions().httpsCallable("allFunctions")({
        funcRole: "officeUser",
        funcName: "keepPeopleDevice",
        data: {
            officeId,
            keepPeople,
            hostname,
            user,
        }
    });
}
