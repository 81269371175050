import React, { useContext, forwardRef, useState, useEffect } from "react";
import {
    Card,
    List,
    ListItem,
    ListItemText,
    CardHeader,
    CardContent,
    TextField,
    IconButton,
    ListItemSecondaryAction,
    Select,
    MenuItem,
    ListItemIcon
} from "@material-ui/core";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import AddIcon from "@material-ui/icons/Add";
import MessagingContext from "../MessagingContext";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import setConversationName from "../../../../../../firebase/httpsCallable/setConversationName";
import GlobalContext from "../../../../../../hooks/GlobalContext";
import addParticipantToConversation from "../../../../../../firebase/httpsCallable/addParticipantToConversation";
import removeParticipantFromConversation from "../../../../../../firebase/httpsCallable/removeParticipantFromConversation";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";

export default forwardRef((props, ref) => {
    const { mainCtx } = useContext(GlobalContext);
    const { officeId } = mainCtx;
    const messaging = useContext(MessagingContext);
    const conversation = messaging.getConversationById(
        messaging.selectedConversationId
    );
    const [name, setName] = useState(conversation.name || "");
    const [userIdToAdd, setUserIdToAdd] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setName(conversation.name);
    }, [conversation.name]);

    return (
        <Card style={{ margin: "auto" }}>
            <CardHeader title={"Conversation Details"}></CardHeader>
            <CardContent>
                <TextField
                    placeholder={conversation.name}
                    label="Name"
                    value={name}
                    onChange={e => {
                        setName(e.target.value);
                    }}
                ></TextField>
                <IconButton
                    onClick={() => {
                        setConversationName({
                            officeId,
                            conversationId: conversation.id,
                            name
                        });
                    }}
                    size={"small"}
                    disabled={conversation.name === name}
                >
                    <CloudUploadIcon></CloudUploadIcon>
                </IconButton>
                <List dense>
                    {conversation.userIds
                        .sort((a, b) => {
                            const userA = messaging.getUserById(a);
                            const userB = messaging.getUserById(b);
                            const aname = (userA && userA.name) || a;
                            const bname = (userB && userB.name) || b;
                            return aname.localeCompare(bname);
                        })
                        .map(userId => {
                            const user = messaging.getUserById(userId);
                            return (
                                <ListItem
                                    divider
                                    key={(user && user.id) || userId}
                                >
                                    <ListItemIcon>
                                        {user.passwordProtected ? (
                                            <LockIcon></LockIcon>
                                        ) : ""
                                        // (
                                        //     <LockOpenIcon></LockOpenIcon>
                                        
                                        // )
                                        }
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={(user && user.name) || userId}
                                    ></ListItemText>
                                    <ListItemSecondaryAction>
                                        <IconButton
                                            onClick={() => {
                                                setLoading(true);
                                                const req = {
                                                    officeId,
                                                    senderId:
                                                        messaging.selectedUserId,
                                                    userId: user.id,
                                                    conversationId:
                                                        conversation.id
                                                };
                                                removeParticipantFromConversation(
                                                    req
                                                ).then(() => {
                                                    setLoading(false);
                                                });
                                            }}
                                            size="small"
                                        >
                                            <RemoveCircleIcon></RemoveCircleIcon>
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            );
                        })}
                    <ListItem>
                        <Select
                            value={userIdToAdd || ""}
                            onChange={e => {
                                setUserIdToAdd(e.target.value);
                            }}
                        >
                            {messaging.users
                                .filter(user => {
                                    return !conversation.userIds.includes(
                                        user.id
                                    );
                                })
                                .map(user => {
                                    return (
                                        <MenuItem key={user.id} value={user.id}>
                                            {user.passwordProtected ? (
                                                <LockIcon
                                                    style={{
                                                        height: "20px",
                                                        width: "20px",
                                                        padding: "2px"
                                                    }}
                                                ></LockIcon>
                                            ) : (
                                                <LockOpenIcon
                                                    style={{
                                                        height: "20px",
                                                        width: "20px",
                                                        padding: "2px"
                                                    }}
                                                ></LockOpenIcon>
                                            )}
                                            {user.name}
                                        </MenuItem>
                                    );
                                })}
                        </Select>
                        <IconButton
                            size="small"
                            onClick={() => {
                                if (!userIdToAdd) {
                                    return;
                                }
                                setLoading(true);
                                const req = {
                                    officeId: officeId,
                                    senderId: messaging.selectedUserId,
                                    userId: userIdToAdd,
                                    conversationId: conversation.id
                                };
                                addParticipantToConversation(req).then(() => {
                                    setUserIdToAdd(null);
                                    setLoading(false);
                                });
                            }}
                        >
                            <AddIcon></AddIcon>
                        </IconButton>
                    </ListItem>
                </List>
            </CardContent>
        </Card>
    );
});
