import React, { useContext, useState, useEffect, useRef } from "react";

import firebase from "../../../../firebase/firebase";
import MessagingContext from "../MessagingContext";
import { useQueryListener } from "../queryListener";
import GlobalContext from "../../../../hooks/GlobalContext";
import useMessaging from "../useMessaging";

export default function(props) {
    const { mainCtx } = useContext(GlobalContext);
    const messaging = useContext(MessagingContext);
    const messagingG = useMessaging();
    const messagesListener = useQueryListener();
    const messagesRef = useRef([]);
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        if(messagingG.stateMessaging){
            // disable sound messaging
            messagingG.setStateMessaging(false);
        }
    }, [messagingG.stateMessaging])

    function upsertMessages(freshMessages) {
        const completeMessages = [...messagesRef.current];
        freshMessages.forEach(fm => {
            const exists = messagesRef.current.some(message => {
                return message.id === fm.id;
            });

            if (!exists) {
          
                if(fm.senderId == messaging.selectedUserId){
                    console.log("new message from me")
                }
                else{
                    console.log("new message from someone")
                }
                completeMessages.push(fm);
            }
        });

        completeMessages.sort((a, b) => {
            return parseInt(a.time) < parseInt(b.time);
        });

        messagesRef.current = completeMessages;
        setMessages(messagesRef.current);
    }

    function getMessages(time) {
        const earliestMessageTime = messagesRef.current.reduce(
            (acc, message) => {
                if (message.time < acc) {
                    return message.time;
                }
                return acc;
            },
            99999999999999999
        );

        firebase
            .firestore()
            .collection(
                `/offices/${mainCtx.officeId}/app/messaging/conversations/${messaging.selectedConversationId}/messages`
            )
            .where("time", "<", earliestMessageTime)
            .orderBy("time", "desc")
            .limit(10)
            .get()
            .then(snapshot => {
                const msgs = [];
                snapshot.forEach(doc => {
                    msgs.push(doc.data());
                });

                upsertMessages(msgs);
            });
    }

    useEffect(() => {
        upsertMessages(messagesListener.data);
    }, [messagesListener.data]);

    useEffect(() => {
        if (!messaging.selectedConversationId || !messaging.selectedUserId) {
            messagesListener.unsubscribe();
            return;
        }

        messagesListener.subscribe(
            `/offices/${mainCtx.officeId}/app/messaging/conversations/${messaging.selectedConversationId}/messages`,
            [
                ["orderBy", "time", "desc"],
                ["limit", 10]
            ]
        );
    }, [messaging.selectedConversationId, messaging.selectedUserId]);

    return {
        messagesListener,
        messages,
        getMessages
    };
}
