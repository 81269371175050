import React, { createRef, useEffect, useState, useContext } from 'react';
import Select2 from 'react-select';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';
import { isEmpty, isNil, view } from 'ramda';
import clsx from 'clsx';
import MomentUtils from "@date-io/moment";
import esLocale from "date-fns/locale/es";
import DateFnsUtils from '@date-io/date-fns';

import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from "@material-ui/pickers";
import { makeStyles, createStyles, Theme, useTheme } from '@material-ui/core/styles';
import {
  Button, IconButton, Snackbar, useMediaQuery, Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions, CircularProgress,
  TextField, Grid, NoSsr, MenuItem, Typography, Paper
} from '@material-ui/core';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import { theme, colors } from '../../../../../theme/theme';
import CustomTextField from '../../../../CustomTextField';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    dialog: {
      '& .MuiDialog-container .MuiDialog-paperWidthSm': {
        borderRadius: 20,
        // overflowY: 'auto',
        // overflowX: 'hidden',
        maxWidth: 544,
        width: '100%',
        background: '#fff',
        height: 'auto'
        // padding: '2.5%',
      },
      '& .MuiDialog-container .MuiDialog-paperWidthSm .MuiDialogContent-root': {
        overflowY: 'auto',
        overflowX: 'hidden',
        padding: '24px 37px',
      },
    },
    typeModal: {
      background: '#197FE8',
      color: '#fff',
      padding: '7px 24px',
      textAlign: 'center'
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(0),
      padding: '4px',

      '& svg': {
        width: '1.5em',
        height: '1.5em',
      }
    },
    boxTextField: {
      width: '100%',
      display: 'flex',
      flexFlow: 'row wrap'
    },
    btnAction: {
      justifyContent: 'space-between',
      padding: '8px 24px',
      border: '1px solid #cecaca',

      '& button': {
        fontSize: 15
      },
      '& .btnSave': {
        color: '#ED6F49',
        fontWeight: 600
      }
    },
    text: {
      margin: 0,
      padding: 0,
      color: '#030303',
      textAlign: 'left',
      fontSize: 12,
    },
    textP: {
      margin: 0,
      padding: 0,
      color: '#030303',
      textAlign: 'left',
      fontSize: 15,
    },

  })
);

export default function DialogVisit(props) {

  // ---------------------------------------
  const { visible, toggle, handleSave, loading, setLoading, form } = props;
  // ----------------------------------------------------------
  const classes = useStyles();
  let inputEl = createRef();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  // ----------------------------------------------------------
  useEffect(() => {
    if (visible) {
      document.body.style = "padding:0; overflow-y: hidden;";
      setLoading(false)
    } else {
      document.body.style = "";
    }
  }, [visible])
  // ----------------------------------------------------------

  return (

    <Dialog
      fullScreen={fullScreen}
      open={visible}
      onClose={toggle}
      className={classes.dialog}
      // disableBackdropClick
      // disableEscapeKeyDown
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title" className={classes.typeModal}>UPDATE
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={toggle}
          className={classes.closeButton}
        >
          <HighlightOffIcon />
        </IconButton>

      </DialogTitle>
      <DialogContent ref={(e) => (inputEl = e)}>
        {/* <DialogContentText>Let Google help apps determine location.</DialogContentText> */}

        <Grid className={clsx(classes.boxTextField, '')} container spacing={1}>
          <div style={{marginBottom: 15}}>
            <p className={classes.text}>NAME</p>
            <p className={classes.textP}>{form.patientName} {form.lastName}</p>
          </div>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.btnAction}>
        <Button onClick={toggle}>
          CANCEL
        </Button>
        {!loading && (
          <Button onClick={handleSave} className="btnSave">
            END VISIT
          </Button>
        )}
        {loading && (
          <CircularProgress></CircularProgress>
        )}
      </DialogActions>
    </Dialog>

  );
}

