import firebase from "../firebase";

export default function({ 
    bgColor,
    name,
    officeId,
    userId,
    phoneNumber,
    soundId,
    textColor,
    textOnPage,
    updatedAt,
    rooms,
    initials,
    lastName,
    type,
    
 }) {
    const data = { 
        bgColor,
        name,
        officeId,
        userId,
        phoneNumber,
        soundId,
        textColor,
        textOnPage,
        updatedAt,
        rooms,
        initials,
        lastName,
        type,
        
    };

    return firebase.functions().httpsCallable("allFunctions")({
        funcRole: "messagingAdmin",
        funcName: "updatePeopleApp",
        data: data
    });
}
