import React, { useState, useEffect } from "react";
import { setMasterGain, volumeTest } from "../audio/audio";

export default function({ localStore }) {
    const [volume, setVolume] = useState(localStore.data.volume);



    useEffect(() => {
        setMasterGain(volume);
    }, []);

    function updateVolume(val) {
        localStore.update(localStore.storageKeys.volume, val);
        setVolume(val);
        setMasterGain(val);
    }

    function testVolume() {
        volumeTest();
    }

    return { volume, updateVolume, testVolume };
}
