import React, { useEffect, useContext, useState } from "react";
import { Router, Route, useLocation, useHistory } from "react-router-dom";
import { isEmpty, isNil, update } from 'ramda';

import { makeStyles } from '@material-ui/core/styles';
import { Button, useMediaQuery } from '@material-ui/core';

import { compareHash } from "../../Hash";
import GlobalContext from "../../../hooks/GlobalContext";
import useMessaging from "./useMessaging";
import MessagingContext from "./MessagingContext";

import createConversation from "../../../firebase/httpsCallable/createConversation";
import setConversationName from "../../../firebase/httpsCallable/setConversationName";

import DialogPassword from "./DialogPassword";
import DialogUser from "./AllDialog/DialogUser";
import DialogTalk from "./AllDialog/DialogTalk";
import DialogMembers from "./AllDialog/DialogMembers";
import DialogCreateGroup from "./AllDialog/DialogCreateGroup";
import DialogChat from "./AllDialog/DialogChat";
import DialogConversation from "./AllDialog/DialogConversation";

const useStyles = makeStyles({
    root: {

    }
});

export default function (props) {

    const classes = useStyles();
    // const location = useLocation();
    // const history = useHistory();
    // ----------------------------------------
    // ----------------------------------------
    const { localStore, viewHeader, navigation, mainCtx } = useContext(GlobalContext);
    const messaging = useMessaging();
    const { selectedUserId, selectConversationId } = useContext(MessagingContext);
    // ----------------------------------------
    // DialogPassword
    const [form, setForm] = useState({ password: '' });
    const [formError, setFormError] = useState({ password: false });
    const [loading, setLoading] = useState(false);
    const [stateVerify, setStateVerify] = useState(false);
    const [personSelected, setPersonSelected] = useState([]);
    // ----------------------------------------
    // ----------------------------------------
    const [applyStyle2, setApplyStyle2] = useState(false)
    const [dialogVisit, setDialogVisit] = useState(false)
    const [dialogToTalk, setDialogToTalk] = useState(false)
    const [dialogMembers, setDialogMembers] = useState(false)
    const [dialogCreateGroup, setDialogCreateGroup] = useState(false)
    const [dialogChat, setDialogChat] = useState(false)
    const [dialogConversation, setDialogConversation] = useState(false)
    // ----------------------------------------
    // ----------------------------------------
    const [disabledButtom, setDisabledButtom] = useState(false)
    const [disabledButtomGroup, setDisabledButtomGroup] = useState(false)
    const [dialogPassword, setDialogPassword] = useState(false);
    const [userSelectOne, setUserSelectOne] = useState({})
    const [userSelectTwo, setUserSelectTwo] = useState({})
    const [usersGroup, setUsersGroup] = useState({})
    const [groupIdCreate, setGroupIdCreate] = useState('')
    const [groupIdName, setGroupIdName] = useState('')
    // ----------------------------------------
    // ----------------------------------------
    const [formGroup, setFormGroup] = useState({ name: '' });
    const [formGroupError, setFormGroupError] = useState({ name: false });
    const [stateExist, setStateExist] = useState(false);
    const [listGroup, setListGroup] = useState([]);
    // ----------------------------------------

    useEffect(() => {
        if(messaging.stateMessaging){
            // disable sound messaging
            messaging.setStateMessaging(false);
        }
    }, [messaging.stateMessaging])

    useEffect(() => {
        viewHeader.setViewHeader(true)
    }, [])

    useEffect(() => {

        async function fetch() {
            if (localStore.data.hasOwnProperty('selectedMessagingChat') && !isEmpty(localStore.data.selectedMessagingChat)) {
                const datosStore = localStore.data.selectedMessagingChat;
                if(datosStore.type === 'direct'){
                    if (datosStore.typeConver === 1) {
                        // individual
                        messaging.selectConversationId(datosStore.converId);
                        messaging.selectUserId(datosStore.userTransmitter.id);
                        setUserSelectOne(datosStore.userTransmitter)
                        setUserSelectTwo(datosStore.hasOwnProperty('userReceiver') ? datosStore.userReceiver : {})
                        setUsersGroup({})
                        setDialogChat(true)
                    } else {
                        // Grupal
                        messaging.selectConversationId(datosStore.converId);
                        messaging.selectUserId(datosStore.userTransmitter.id);
                        setUserSelectOne(datosStore.userTransmitter)
                        setUsersGroup(datosStore.groupReceiver)
                        setUserSelectTwo({})
                        setDialogChat(true)
                    }

                }else{
                    // Other
                    messaging.selectUserId(datosStore.userTransmitter.id);
                    setUserSelectOne(datosStore.userTransmitter)
                    setUserSelectTwo({})
                    setUsersGroup({})
                    setDialogConversation(true)
                }
            }
        }
        fetch()

    }, [localStore.data.selectedMessagingChat]);
    
    useEffect(() => {
        async function fetchTwo() {
            if(localStore.data.messageVisitEnabled){
                setDialogVisit(true)
            }
        }
        fetchTwo()
    }, [localStore.data.messageVisitEnabled]);


    // ----------------------------------------
    // ----------------------------------------
    // DialogPassword
    const onChangeText = (event) => setForm({ ...form, [event.target.name]: event.target.value });

    const toggleDialogPass = () => {
        setDialogPassword(!dialogPassword)
        setFormError({ password: false });
        setForm({ password: '' });
        setStateVerify(false)
    };

    /**
    * @author Randall Medina
    * @description Method that the user object receives
    * @param item
    */
    const handleSavePassword = (item) => async () => {
        if (isEmpty(form.password)) {
            setFormError({ password: !formError.password });
        } else {
            setLoading(true);
            setFormError({ password: false });

            const resulHash = await compareHash(form.password, item.password)
            if ( resulHash ) {

                messaging.selectUserId(item.id);
                localStore.update(
                    localStore.storageKeys.selectedMessagingUserId,
                    item.id
                );

                setStateVerify(false)
                setLoading(false);
                toggleDialogPass()
                setDialogToTalk(true)
            } else {
                setStateVerify(true)
                setLoading(false);
            }
        }
    }
    // ----------------------------------------
    // ----------------------------------------
    // DialogUser
    const toggleVisit = () => {
        localStore.update(
            localStore.storageKeys.messageVisitEnabled,
            false
        );
        setDialogVisit(!dialogVisit);
    }

    /**
     * @author Randall Medina
     * @description Method that receives the selected user object
     * @param item
     */
    const handleSelectedUser = (item) => () => {

        if (item.passwordProtected) {
            setUserSelectOne(item)
            toggleVisit()
            setDialogPassword(true);
        } else {
            messaging.selectUserId(item.id);
            localStore.update(
                localStore.storageKeys.selectedMessagingUserId,
                item.id
            );
            setUserSelectOne(item)
            toggleVisit()
            setDialogToTalk(true)
        }
    }
    // ----------------------------------------
    // ----------------------------------------
    // DialogTalk
    const toggleToTalk = () => {
        setDialogToTalk(!dialogToTalk)
    };

    const backActionTalk = () => {
        setUserSelectOne({})
        setDialogToTalk(false)
        toggleVisit()
    }

    const arraysEqual = (a, b) => {
        if (a.length !== b.length) {
            return false;
        }
        for (var i = 0; i < a.length; i++) {
            if (a[i] !== b[i]) {
                return false;
            }
        }
        return true;
    }

    const getSortedUserIds = (userIds) => {
        return Array.from(new Set(userIds)).sort();
    }

    /**
     * @author Randall Medina
     * @description Method that receives the selected user object
     * @param item
     */
    const handleSelectedUserTwo = (item) => async () => {

        setDisabledButtom(true);
        const existingConversations = await messaging.conversations.filter(
            conversation => {
                return arraysEqual(
                    getSortedUserIds(conversation.userIds),
                    getSortedUserIds([item.id, userSelectOne.id])
                );
            }
        );
        const sortRecentConversation = await existingConversations.sort((a, b) => {
            const atime = parseInt(a.latestMessage.time);
            const btime = parseInt(b.latestMessage.time);
            return btime - atime;
        });

        if (sortRecentConversation.length > 0) {
            messaging.selectConversationId(sortRecentConversation[0].id);
            if (sortRecentConversation[0].id) {
                // show an existing conversation in individual or group chat modal
                setUserSelectTwo(item)
                toggleToTalk()
                setDisabledButtom(false);
                setDialogChat(true)
            }

        } else {
            const body = {
                officeId: mainCtx.officeId,
                userIds: [userSelectOne.id, item.id],
                usersMessage: [userSelectOne.id],
            }
            createConversation(body).then(result => {
                messaging.selectConversationId(result.data.id);
                // show a new conversation in modal individual chat
                setUserSelectTwo(item)
                toggleToTalk()
                setDisabledButtom(false);
                setDialogChat(true)
            }).catch(error => {
                setDisabledButtom(false);
                console.error('Error creating conversation:', error);
            });;
        }

    }

    /**
     * @author Randall Medina
     * @description Method that receives the selected group object
     * @param item
     */
    const handleSelectedGroup = (item) => () => {
        messaging.selectConversationId(item.id);
        if (item.id) {
            setUsersGroup(item)
            toggleToTalk()
            setDialogChat(true)
        }
    }

    /**
     * @author Randall Medina
     * @description Method that receives the array of the existing groups from my user so as not to repeat the creation of duplicates
     * @param item
     */
    const handleCreateGroup = (groupData) => () => {
        setListGroup(groupData)
        setDialogToTalk(false)
        setDialogMembers(true)
    }
    // ----------------------------------------
    // ----------------------------------------
    // DialogMembers
    const togglegMembers = () => setDialogMembers(!dialogMembers);

    const backActionMembers = () => {
        setDialogMembers(false)
        toggleToTalk()
    }

    /**
    * @author Randall Medina
    * @description Method that receives the matrix of the selected users for previous creation of a new group
    * @param item
    */
    const membersGroup = (item) => () => {
        setPersonSelected(item)
        togglegMembers()
        setDialogCreateGroup(true)
    }

    // ----------------------------------------
    // ----------------------------------------
    // DialogCreateGroup
    const togglegCreateGroup = () => {
        setFormGroupError({ name: false });
        setFormGroup({ name: '' });
        setDialogCreateGroup(!dialogCreateGroup);
    }

    const backActionCreateGroup = () => {

        setFormGroupError({ name: false });
        setFormGroup({ name: '' });
        setDialogCreateGroup(false)
        togglegMembers()
    }
    const onChangeTextGroup = (event) => setFormGroup({ ...formGroup, [event.target.name]: event.target.value });

    /**
    * @author Randall Medina
    * @description Method for the creation of the new group
    */
    const createMerchantAccount = () => {
        
        if (isEmpty(formGroup.name)) {
            setFormGroupError({ name: !formGroup.name });
        } else {

            setFormGroupError({ name: false });

            const existConver = listGroup.filter(e => e.name === formGroup.name);
            if (!isEmpty(existConver) && existConver.length > 0) {
                setStateExist(true)
            } else {

                setDisabledButtomGroup(true)

                setStateExist(false)
                const selectedUser = personSelected.filter(e => e.selected === true).map(e => e.id);
                const participants = getSortedUserIds([
                    ...selectedUser,
                    messaging.selectedUserId
                ]);
                const req = {
                    officeId: mainCtx.officeId,
                    userIds: participants
                };

                createConversation(req).then(result => {
                    selectConversationId(result.data.id);
                    setGroupIdCreate(result.data.id)
                    setGroupIdName(formGroup.name)

                    setConversationName({
                        officeId: mainCtx.officeId,
                        conversationId: result.data.id,
                        name: formGroup.name
                    }).then(result => {
                        // show group chat modal
                        setDisabledButtomGroup(false)
                        togglegCreateGroup()

                        setFormGroupError({ name: false });
                        setFormGroup({ name: '' });
                        
                        setUsersGroup({})
                        setUserSelectTwo({})
                        setDialogChat(true)
                        // -------------------------------------------

                    }).catch(error => {
                        setDisabledButtomGroup(false);
                        console.error('Error creating setConversationName:', error);
                    });
                }).catch(error => {
                    setDisabledButtomGroup(false);
                    console.error('Error creating conversation:', error);
                });;
            }

        }
    }
    // ----------------------------------------
    // ----------------------------------------
    // DialogChat
    const togglegChat = () => {
        setUsersGroup({})
        // setUserSelectTwo({})
        setDialogChat(!dialogChat)
    };

    const backActionChat = () => {
        setGroupIdCreate('')
        setGroupIdName('')
        togglegChat()
        setUsersGroup({})
        setUserSelectTwo({})
        setDialogConversation(true)
    }
    // ----------------------------------------
    // ----------------------------------------
    // DialogConversation
    const toggleConversation = () => setDialogConversation(!dialogConversation);

    const backActionConversation = () => {
    }

    /**
    * @author Randall Medina
    * @description Method to select an existing group
    * @param item
    */
    const handleSelectedConversationGroup = (item) => () => {

        messaging.selectConversationId(item.id);
        if (item.id) {
            setUsersGroup(item)
            toggleConversation()
            setDialogChat(true)
        }
    }

    /**
     * @author Randall Medina
     * @description Method to select an individual existing chat
     * @param item
     */
    const handleSelectedConversationPerson = (item) => () => {

        if (!isNil(item)) {
            const res =  messaging.getUserById(item.userIds[0]);
            if ( !isNil(res) && !isEmpty(res) ) {
                messaging.selectConversationId(item.id);
                if (item.id) {
                    setUserSelectTwo(res)
                    toggleConversation()
                    setDialogChat(true)
                }
            }
        }
    }
    // ----------------------------------------
    // ----------------------------------------

    return (
        <MessagingContext.Provider value={messaging}>

            {/* <Button variant='contained' className={classes.buttonM} onClick={toggleVisit} onMouseLeave={() => setApplyStyle2(false)} onMouseEnter={() => setApplyStyle2(true)}>
                <div className="box">
                    <div className="box-img">
                        <img src={applyStyle2 ? messaginWhite : messagingAdd} style={{ marginRight: 10 }} />
                    </div>
                    <div className="box-text">MESSAGES</div>
                </div>
            </Button> */}

            {/* ------------------------------------ */}
            {/* ------------------------------------ */}
            {dialogPassword && (
                <DialogPassword
                    visible={dialogPassword}
                    toggle={toggleDialogPass}
                    handleSavePassword={handleSavePassword}
                    onChangeText={onChangeText}
                    person={userSelectOne}
                    form={form}
                    formError={formError}
                    loading={loading}
                    stateVerify={stateVerify}
                />
            )}
            {/* ------------------------------------ */}
            {/* ------------------------------------ */}
            {dialogVisit && (
                <DialogUser
                    visible={dialogVisit}
                    toggle={toggleVisit}
                    users={messaging.users}
                    handleSelectedUser={handleSelectedUser}
                />
            )}
            {dialogToTalk && (
                <DialogTalk
                    visible={dialogToTalk}
                    toggle={toggleToTalk}
                    back={backActionTalk}
                    handleCreateGroup={handleCreateGroup}
                    handleSelectedUserTwo={handleSelectedUserTwo}
                    handleSelectedGroup={handleSelectedGroup}
                    userSelectOne={userSelectOne}
                    disabledButtom={disabledButtom}
                />
            )}
            {dialogMembers && (
                <DialogMembers
                    visible={dialogMembers}
                    toggle={togglegMembers}
                    back={backActionMembers}
                    userSelectOne={userSelectOne}
                    membersGroup={membersGroup}
                />
            )}
            {dialogCreateGroup && (
                <DialogCreateGroup
                    visible={dialogCreateGroup}
                    toggle={togglegCreateGroup}
                    back={backActionCreateGroup}
                    userSelectOne={userSelectOne}
                    formGroup={formGroup}
                    formGroupError={formGroupError}
                    stateExist={stateExist}
                    onChangeTextGroup={onChangeTextGroup}
                    createMerchantAccount={createMerchantAccount}
                    disabledButtomGroup={disabledButtomGroup}
                />
            )}
            {dialogChat && (
                <DialogChat
                    visible={dialogChat}
                    toggle={togglegChat}
                    back={backActionChat}
                    userSelectOne={userSelectOne}
                    userSelectTwo={userSelectTwo}
                    usersGroup={usersGroup}
                    groupIdCreate={groupIdCreate}
                    groupIdName={groupIdName}
                />
            )}
            {dialogConversation && (
                <DialogConversation
                    visible={dialogConversation}
                    toggle={toggleConversation}
                    back={backActionConversation}
                    userSelectOne={userSelectOne}
                    handleSelectedConversationGroup={handleSelectedConversationGroup}
                    handleSelectedConversationPerson={handleSelectedConversationPerson}
                />
            )}
            {/* ------------------------------------ */}

        </MessagingContext.Provider>
    );
}