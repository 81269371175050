import { createContext, useState, useRef } from "react";
import firebase from "../firebase";

export const licensesDocContext = createContext({
    licenses: {},
    sessions: {},
    subscribe: () => {},
    unsubscribe: () => {}
});

export const useLicensesDoc = () => {
    const [licenses, setLicenses] = useState({});
    const [sessions, setSessions] = useState({});
    const [listenError, setListenError] = useState(null);
    const [initializing, setInitializing] = useState(true);
    const unsubscribeRef = useRef(null);

    const subscribe = officeId => {
        setListenError(null);
        unsubscribe();
        setLicenses({})
        unsubscribeRef.current = firebase
            .firestore()
            .doc(`/offices/${officeId}/admin/licenses`)
            .onSnapshot(
                doc => {
                    const data = doc.data();
                    data.licenses
                        ? setLicenses(data.licenses)
                        : setLicenses({});
                    data.sessions
                        ? setSessions(data.sessions)
                        : setSessions({});
                },
                err => {
                    setListenError(err);
                }
            );
    };

    const unsubscribe = () => {
        unsubscribeRef.current && unsubscribeRef.current();
    };

    return {
        licenses,
        sessions,
        subscribe,
        unsubscribe,
        listenError,
        initializing,
        setInitializing
    };
};
