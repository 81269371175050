import React, { useContext, useEffect, useState } from "react";
import MessagingContext from "./MessagingContext";

import {
    Menu,
    Dialog,
    TextField,
    DialogContent,
    ListItem,
    InputLabel,
    ButtonBase,
    DialogTitle,
    ListItemText
} from "@material-ui/core";
import GlobalContext from "../../../../../hooks/GlobalContext";
import messagingRouterHistory from "./messagingRouterHistory";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";

export default function(props) {
    const { localStore } = useContext(GlobalContext);
    const messaging = useContext(MessagingContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const [label, setLabel] = useState("");

    function closeMenu() {
        setAnchorEl(null);
    }

    useEffect(() => {
        const name = (() => {
            const user = messaging.getUserById(messaging.selectedUserId);

            if (user) {
                if (user.name) {
                    return user.name;
                }
                if (user.id) {
                    return user.id;
                }
            }
            return "Select User";
        })();
        setLabel(name);
    }, [messaging.selectedUserId]);

    return (
        <React.Fragment>
            <ButtonBase
                style={{
                    display: "flex",
                    height: "100%",
                    fontSize: "12px",
                    padding: "1px",
                    textOverflow: "ellipsis",
                    backgroundColor: "#d8d8d8",
                    color: "black",
                    borderRadius: "5px",
                    overflow: "hidden",
                    justifyContent: "left",
                    alignContent: "center",
                    minWidth: 0
                }}
                size="small"
                label={label}
                onClick={e => {
                    setAnchorEl(e.currentTarget);
                }}
            >
                {label}
            </ButtonBase>
            <Menu
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={closeMenu}
                disableScrollLock
            >
                <ListItem
                    dense
                    button
                    onClick={() => {
                        messaging.selectUserId(null);
                        localStore.update(
                            localStore.storageKeys.selectedMessagingUserId,
                            null
                        );
                        closeMenu();
                    }}
                >
                    --none--
                </ListItem>
                <UserList closeMenu={closeMenu}></UserList>
            </Menu>
        </React.Fragment>
    );
}

const UserList = React.forwardRef((props, ref) => {
    const { closeMenu } = props;
    const messaging = useContext(MessagingContext);

    return messaging.users
        .sort((a, b) => {
            const aname = a.name ? a.name : a.id;
            const bname = b.name ? b.name : b.id;
            return aname.localeCompare(bname);
        })
        .map(user => {
            return (
                <UserListItem
                    key={user.id}
                    user={user}
                    closeMenu={closeMenu}
                ></UserListItem>
            );
        });
});

const UserListItem = React.forwardRef((props, ref) => {
    const { localStore } = useContext(GlobalContext);
    const messaging = useContext(MessagingContext);
    const { user, closeMenu } = props;
    const [dialogOpen, setDialogOpen] = useState(false);
    const [pwInput, setPwInput] = useState("");

    function selectUser() {
        messaging.selectUserId(user.id);
        localStore.update(
            localStore.storageKeys.selectedMessagingUserId,
            user.id
        );
        messagingRouterHistory.push("/conversationList");
        setDialogOpen(false);
        closeMenu();
    }
    return (
        <React.Fragment>
            <ListItem
                dense
                button
                style={{ fontSize: "12px" }}
                value={user.id}
                onClick={() => {
                    if (user.passwordProtected) {
                        setDialogOpen(true);
                    } else {
                        selectUser();
                    }
                }}
            >
                {user.passwordProtected ? (
                    <LockIcon
                        style={{
                            height: "20px",
                            width: "20px",
                            padding: "2px"
                        }}
                    ></LockIcon>
                ) : ""
                // (
                //     <LockOpenIcon
                //         style={{
                //             height: "20px",
                //             width: "20px",
                //             padding: "2px"
                //         }}
                //     ></LockOpenIcon>
                // )
                }

                <ListItemText disableTypography style={{ fontSize: "12px" }}>
                    {user.name}
                </ListItemText>
            </ListItem>
            <Dialog
                onKeyDown={e => {
                    if (e.key === "Escape") {
                        setDialogOpen(false);
                    }
                    e.stopPropagation();
                }}
                open={dialogOpen}
                onClose={() => {
                    setDialogOpen(false);
                }}
            >
                <DialogTitle>Password</DialogTitle>
                <DialogContent>
                    <InputLabel></InputLabel>
                    <TextField
                        type="password"
                        label="password"
                        value={pwInput}
                        onChange={e => {
                            setPwInput(e.target.value);
                            if (e.target.value === user.password) {
                                selectUser();
                            }
                        }}
                    ></TextField>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
});
