import React, { useState, useContext } from "react";

import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Drawer, ListItem, ListItemText, Divider, Button, MenuItem, Menu, Slider } from "@material-ui/core";

import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import VolumeUpRoundedIcon from '@material-ui/icons/VolumeUpRounded';
import FolderSharedRoundedIcon from '@material-ui/icons/FolderSharedRounded';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';

import firebase from "../../firebase/firebase";
import GlobalContext from "../../hooks/GlobalContext";
import sessionDevice from "../../firebase/httpsCallable/sessionDevice";

import logo from '../../assets/logo.png';
import personAdd from '../../assets/personAdd.png';
import personWhite from '../../assets/personWhite.png';

import messaginWhite from '../../assets/messaginWhite.png';
import messagingAdd from '../../assets/messagingAdd.png';

import { isEmpty, isNil } from "ramda";

const useStyles = makeStyles({
    root: {
        backgroundColor: 'white',
        display: 'flex',
        borderBottom: '1px solid rgb(187, 187, 187)',
    },
    drawer: {
        '& .MuiDrawer-paper': {
            background: 'white'
        }
    },
    list: {
        width: 300,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '2%',

    },
    fullList: {
        width: 'auto',
    },
    boxButtons: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 20,
    },
    button: {
        margin: 5,
        fontSize: 12,
        backgroundColor: '#F6F6F8',
        color: 'black',
    },
    boxBtnLock: {
        width: 30,
        height: 20,
        borderRadius: 2,
        display: 'flex',
        alignItems: 'center',
    },
    btnLock: {
        backgroundColor: 'white',
        height: 16,
        width: 15,
        borderRadius: 2,
    },


    boxButtonPrincipal: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    IconButtonCustom: {
        width: '100%',
        padding: '12px 0',

        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    closeButton: {
        position: 'absolute',
        right: 10,
        top: 5,
        padding: 5,

        '& svg': {
            width: '1.1em',
            height: '1.1em',
        }
    },

    buttonP: {
        height: 32,
        backgroundColor: 'white',
        color: '#3A3E4B',
        width: '100%',
        fontWeight: 'bold',
        borderRadius: 8,
        cursor: 'pointer',
        marginRight: 8,

        '&:hover': {
            background: 'linear-gradient(180deg, #AFDC43 0%, #9DCD2A 100%)',
            '& .box .box-text': {
                color: '#fff'
            }
        },

        '& .box': {
            display: 'flex',
            width: '100%',

            '& .box-img': {
                alignItems: 'center',
                display: 'flex',
            },
            '& .box-text': {
                width: '100%',
                fontSize: 10
            }
        }
    },
    buttonM: {
        height: 32,
        backgroundColor: 'white',
        width: '100%',
        color: '#3A3E4B',
        fontWeight: 'bold',
        borderRadius: 8,
        cursor: 'pointer',

        '&:hover': {
            background: 'linear-gradient(180deg, #FB793C 0%, #F06422 100%);',
            '& .box .box-text': {
                color: '#fff'
            }
        },
        '& .box': {
            display: 'flex',
            width: '100%',

            '& .box-img': {
                alignItems: 'center',
                display: 'flex',
            },
            '& .box-text': {
                width: '100%',
                fontSize: 10
            }
        }
    },

});
const Index = () => {
    const { viewHeader, volumeControl, navigation, mainCtx, authCtx, localStore } = useContext(
        GlobalContext
    );

    const { officeId } = mainCtx;
    const { user } = authCtx;
    const classes = useStyles();
    const officesLinkVisible = Boolean(user);
    const licensesLinkVisible = Boolean(officeId);

    const [tempVolume, setTempVolume] = useState(volumeControl.volume);
    const [anchorEl, setAnchorEl] = useState(null);
    const [changeModeLock, setChangeModeLock] = useState('normal');
    const beacon = window.Beacon;
    // ----------------------------------------------------------
    const [open, setOpen] = useState(false)
    const [applyStyle, setApplyStyle] = useState(false);
    // ----------------------------------------------------------
    const [applyStyle2, setApplyStyle2] = useState(false)
    // ----------------------------------------------------------

    const toggleDrawer = () => setOpen(!open)

    const logOutDevice = () => {

        if (!isNil(window.device) && !isEmpty(window.device)) {
            const body = {
                officeId,
                hostname: window.device.hostname,
                user: window.device.user,
            }
            sessionDevice(body).then(result => {
                console.log('result logOutDevice ==========', result)
            }).catch(error => {
                console.error('Error logOutDevice:', error);
            });
        }

    }

    const cleanLocalStore = async () => {
        // remove from localstorage the office id
        localStore.update(
            localStore.storageKeys.selectedOfficeId,
            null
        );
        // remove license id from localstorage
        localStore.update(
            localStore.storageKeys.messageLicense,
            ''
        );
        await logOutDevice()
        firebase.auth().signOut();
        mainCtx.setOfficeId(null);
        setAnchorEl(null);
        beacon('logout')
        navigation.toSignIn();
    }

    const toggleVisitUser = () => {
        localStore.update(
            localStore.storageKeys.messageVisitEnabled,
            true
        );
    }

    const list = () => (
        <div
            role="presentation"
            onClick={toggleDrawer}
            onKeyDown={toggleDrawer}
        >
            <div className={classes.boxButtons}>
                <Button
                    variant="contained"
                    className={classes.button}
                    fullWidth
                    startIcon={<FolderSharedRoundedIcon style={{ color: '#4984C2' }} />}
                    onClick={() => {
                        window.open(process.env.REACT_APP_PORTAL_WEBSITE_URL);
                    }}
                >
                    PORTAL
                </Button>
            </div>
            {officesLinkVisible && (
                <>
                    <ListItem
                        button
                        style={{ marginTop: 20 }}
                        onClick={() => {
                            localStore.update(
                                localStore.storageKeys.messageLicense,
                                ''
                            );
                            navigation.toOfficeSelect(mainCtx.officeId);
                            setAnchorEl(null);
                        }}
                    >
                        <ListItemText primary='Office List' />
                    </ListItem>
                    <Divider style={{ height: 1 }} />
                    <ListItem
                        button
                        onClick={cleanLocalStore}
                    >
                        <ListItemText primary='Sign Out' />
                    </ListItem>
                </>
            )}
        </div>
    );

    if (!viewHeader.viewHeader) {
        return null;
    }

    return (
        <div className={classes.root}>
            <Drawer
                anchor='left'
                open={open}
                onClose={toggleDrawer}
                className={classes.drawer}
            >
                <div className={classes.list}>
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={toggleDrawer}
                        className={classes.closeButton}
                    >
                        <HighlightOffRoundedIcon />
                    </IconButton>
                    <img src={logo} style={{ height: 59, width: 187, alignSelf: 'center', marginTop: 10 }} />
                    <IconButton
                        onClick={e => {
                            setAnchorEl(e.currentTarget);
                        }}
                        style={{
                            width: '70%',
                            borderRadius: 15,
                            padding: 8,
                            alignSelf: 'center',
                            margin: 10
                        }}
                    >
                        <VolumeUpRoundedIcon />
                    </IconButton>
                    <Divider />
                    {list()}
                </div>
            </Drawer>
            <div style={{ display: 'flex', width: '100%' }}>
                <IconButton onClick={toggleDrawer}>
                    <MenuRoundedIcon />
                </IconButton>

                {licensesLinkVisible ?
                    <div className={classes.boxButtonPrincipal} style={{ width: '70%' }}>
                        {/* <IconButton className={classes.IconButtonCustom}> */}
                        <Button variant='contained' className={classes.buttonP} onClick={() => { setApplyStyle(false); navigation.toPaging(); }} onMouseLeave={() => setApplyStyle(false)} onMouseEnter={() => setApplyStyle(true)}>
                            <div className="box">
                                <div className="box-img" >
                                    <img src={applyStyle ? personWhite : personAdd} style={{ marginRight: 10 }} />
                                </div>
                                <div className="box-text">PAGING</div>
                            </div>
                        </Button>
                        <Button variant='contained' className={classes.buttonM} onClick={toggleVisitUser} onMouseLeave={() => setApplyStyle2(false)} onMouseEnter={() => setApplyStyle2(true)}>
                            <div className="box">
                                <div className="box-img">
                                    <img src={applyStyle2 ? messaginWhite : messagingAdd} style={{ marginRight: 10 }} />
                                </div>
                                <div className="box-text">MESSAGES</div>
                            </div>
                        </Button>
                        {/* </IconButton> */}
                    </div>
                    : null}
            </div>
            <Menu
                disableScrollLock
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => {
                    setAnchorEl(null);
                }}
            >
                <MenuItem style={{ paddingTop: 20 }}>
                    <Slider
                        style={{ minHeight: "15vh", }}
                        orientation="vertical"
                        defaultValue={volumeControl.volume}
                        aria-labelledby="volume-slider"
                        valueLabelDisplay="auto"
                        value={tempVolume}
                        onChange={(e, v) => {
                            setTempVolume(v);
                        }}
                        step={1}
                        max={100}
                        min={0}
                        onChangeCommitted={(e, v) => {
                            volumeControl.updateVolume(v);
                            setAnchorEl(null);
                            volumeControl.testVolume();
                        }}
                    />
                </MenuItem>
            </Menu>
            {/* <div>
                {changeModeLock === 'normal' && (    
                    <IconButton onClick={moveRight}>
                        <div className={classes.boxBtnLock} style={{ backgroundColor: changeModeLock === 'normal' ? 'black' : 'rgba(0, 0, 0, 0.5)' }}>
                            <div className={classes.btnLock} style={{ marginLeft: 2 }} />
                        </div>
                    </IconButton>
                )}
                {changeModeLock === 'normal' && (
                    <IconButton onClick={moveLeft}>
                        <div className={classes.boxBtnLock} style={{ backgroundColor: changeModeLock === 'normal' ? 'black' : 'rgba(0, 0, 0, 0.5)', justifyContent: 'flex-end' }}>
                            <div className={classes.btnLock} style={{ marginRight: 2 }} />
                        </div>
                    </IconButton>
                )}
                {changeModeLock !== 'normal' && (
                    <IconButton onClick={moveNormal} style={{ color: 'black' }}>
                        <LaunchIcon />
                    </IconButton>
                )}
                {changeModeLock !== 'normal' && (
                    <IconButton onClick={moveNormal}>
                        <LockRoundedIcon style={{ color: 'black' }}/>
                    </IconButton>
                )}
                <IconButton >
                    <HighlightOffRoundedIcon style={{ color: 'black' }} />
                </IconButton>
            </div> */}
        </div>
    )
}

export default Index;