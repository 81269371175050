import firebase from "../firebase";

export default function({
    officeId,
    patientName,
    lastName,
    arrivalTime,
    appointmentTime,
    dueOutTime,
    lateColor
}) {
    return firebase.functions().httpsCallable("allFunctions")({
        funcRole: "patientTrackingUser",
        funcName: "createVisit",
        data: {
            officeId,
            patientName,
            lastName,
            arrivalTime,
            appointmentTime,
            dueOutTime,
            lateColor
        }
    });
}
