import { createSlice } from '@reduxjs/toolkit';

export const firebaseSlice = createSlice({
  name: 'firebase',
  initialState: {
    initialized: false,
    connected: true
  },
  reducers: {
    init: (state) => {
      console.log('init.');
      state.connected = true;
      state.initialized = true;
    },
    offline: (state) => {
      if (state.initialized) {
        if (state.connected) {
          console.log('offline');
          state.connected = false;
        }
      }
    },
    online: (state) => {
      if (state.initialized) {
        if (!state.connected) {
          console.log('online');
          state.connected = true;
        }
      }
    }
  }
});

export const { init, offline, online } = firebaseSlice.actions;

export default firebaseSlice.reducer;
