import firebase from "../firebase";

export default function({
    officeId,
    visitId,
    arrivalTime,
    appointmentTime,
    dueOutTime,
    lateColor,
    contactHistory
}) {
    const data = { officeId, visitId, arrivalTime, appointmentTime, dueOutTime, lateColor, contactHistory };

    return firebase.functions().httpsCallable("allFunctions")({
        funcRole: "patientTrackingUser",
        funcName: "updateVisitTimes",
        data: data
    });
}
