import { createContext, useState, useRef } from "react";
import firebase from "../firebase";

export const officeDocContext = createContext({
    name: "",
    subscribe: () => {},
    unsubscribe: () => {}
});

export const useOfficeDoc = () => {
    const [name, setName] = useState("");
    const [listenError, setListenError] = useState(null);
    const unsubscribeRef = useRef(null);

    const subscribe = officeId => {
        setListenError(null);
        unsubscribe();
        unsubscribeRef.current = firebase
            .firestore()
            .doc(`/offices/${officeId}`)
            .onSnapshot(
                doc => {
                    const data = doc.data();
                    data.name ? setName(data.name) : setName("");
                },
                err => {
                    setListenError(err);
                }
            );
    };

    const unsubscribe = () => {
        unsubscribeRef.current && unsubscribeRef.current();
    };

    return { name, subscribe, unsubscribe, listenError };
};
