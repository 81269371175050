import React, { useState, useContext } from "react";
import { Resizable } from "re-resizable";
import { makeStyles } from '@material-ui/core/styles';

import GlobalContext from "../../../../hooks/GlobalContext";

import MeaningButton from "./MeaningButton";

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
        // justifyContent: 'space-around',
        flexWrap: 'wrap',
        backgroundColor: '#E9EDF8',
        width: '98%',
        height: '100%',
        padding: 5,
        marginTop: 10,
        marginBottom: 10,
        borderRadius: 10,
        overflow: 'auto'
    },
});

export default function (props) {
    const { localStore, pagingDoc } = useContext(GlobalContext);
    const { meaningButtons } = pagingDoc;
    const classes = useStyles();

    return (
        <Resizable
            style={{ display: "flex", justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}
            size={{
                width: '98%',
                height: Object.keys(meaningButtons).length <= 7 ? 150 : 250,
            }}
            onResizeStop={(event, direction, refToElement, delta) => {
                const newHeight = parseInt(refToElement.style.height);
                localStore.update(
                    localStore.storageKeys.meaningButtonsHeight,
                    newHeight
                );
            }}
            enable={{ bottom: true }}
        >
            <div className={classes.root}>
                {getSortedMeaningButtonIds(meaningButtons).map((mbId) => {
                    return (
                        <MeaningButton
                            key={mbId}
                            id={mbId}
                            name={meaningButtons[mbId].name}
                            iconId={meaningButtons[mbId].iconId}
                        />
                    );
                })}
            </div>
        </Resizable>
    );
}

function getSortedMeaningButtonIds(meaningButtons) {
    const sorted = Object.keys(meaningButtons)
        .sort((a, b) => {
            if (meaningButtons[a].name > meaningButtons[b].name) {
                return 1;
            }
            if (meaningButtons[a].name < meaningButtons[b].name) {
                return -1;
            }
            return 0;
        })
        .sort((a, b) => {
            const meaningButtonA = { ...meaningButtons[a] };
            const meaningButtonB = { ...meaningButtons[b] };
            meaningButtonA.listPosition = meaningButtonA.listPosition
                ? meaningButtonA.listPosition
                : 0;
            meaningButtonB.listPosition = meaningButtonB.listPosition
                ? meaningButtonB.listPosition
                : 0;
            return meaningButtonA.listPosition - meaningButtonB.listPosition;
        });

    return sorted;
}
