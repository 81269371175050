import React, { useContext } from "react";
import { Resizable } from "re-resizable";
import { isEmpty, isNil, descend, prop, sortWith, ascend, concat } from "ramda";
import { makeStyles } from '@material-ui/core/styles';

import GlobalContext from "../../../../hooks/GlobalContext";

import styled from "styled-components";
import Masonry from "react-masonry-component";

import Room from "./Room";

const Content = styled(Masonry)`
  width: 100%;

  & #MasonryId {
    width: ${props => (100 - 2 * props.columns) / props.columns}%;
    // margin: 5px;
  }
`;

const useStyles = makeStyles((theme) => ({
    root: {
    },
}));
export default function (props) {
    const persons = props.persons;

    const { localStore } = useContext(GlobalContext);
    const classes = useStyles();

    return (
        <div
            style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <Content columns={2}>
                {getSortedRoomIds1(persons).map(({ id, pagingId, ...row }, index) => {

                    return (
                        <Room
                            key={index}
                            person={{ ...row, personId: pagingId, userId: id }}
                            cantRooms={getSortedRoomIds1(persons).length}
                            index={index}
                        />
                    );
                })}
            </Content>
        </div>
    );
}

function getSortedRoomIds1(persons) {
    let newArray = []
    const ageNameSort = sortWith([
        ascend(prop('updatedAt')),
    ]);
    Object.keys(persons).filter(item => !isNil(persons[item].rooms) && !isEmpty(persons[item].rooms)).map((key, index) => {
        newArray = newArray.concat({ ...persons[key], pagingId: key, cant: Object.keys(persons[key].rooms).length })
    });
    return ageNameSort(newArray);
    // return newArray;
}