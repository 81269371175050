import React, { createRef, useEffect, useState, useContext } from 'react';
import Select2 from 'react-select';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';
import { isEmpty, isNil, view, update } from 'ramda';
import clsx from 'clsx';
import MomentUtils from "@date-io/moment";
import esLocale from "date-fns/locale/es";
import DateFnsUtils from '@date-io/date-fns';

import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from "@material-ui/pickers";
import { makeStyles, createStyles, Theme, useTheme } from '@material-ui/core/styles';
import {
  Button, IconButton, Snackbar, useMediaQuery, Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions, CircularProgress,
  TextField, Grid, NoSsr, MenuItem, Typography, Paper, Slide, FormControlLabel, Checkbox
} from '@material-ui/core';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import GlobalContext from "../../../../hooks/GlobalContext";
import MessagingContext from "../../Messaging/MessagingContext";

import check from '../../../../assets/check.png'
import PersonList from './PersonList'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    dialog: {
      '& .MuiDialog-container .MuiDialog-paperWidthSm': {
        borderRadius: 20,
        // overflowY: 'auto',
        // overflowX: 'hidden',
        maxWidth: 544,
        width: '100%',
        background: '#fff',
        maxHeight: '80%'
        // padding: '2.5%',
      },
      '& .MuiDialog-container .MuiDialog-paperWidthSm .MuiDialogContent-root': {
        overflowY: 'auto',
        overflowX: 'hidden',
        padding: '8px 8px',
      },
    },
    typeModal: {
      background: '#F86F46',
      color: '#fff',
      padding: '7px 24px',
      textAlign: 'center'
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(0),
      padding: '4px',

      '& svg': {
        width: '1.5em',
        height: '1.5em',
      }
    },
    text: {
      margin: 0,
      padding: 0,
      color: '#030303',
      textAlign: 'left',
      fontSize: 12,
    },
    btnAction: {
      padding: '8px 24px',
      border: '1px solid #cecaca',

      '& button': {

      },
      '& .btnSave': {
        background: '#F86F46',
        color: '#fff',
      }
    },

    boxTitle: {
      display: 'flex',
      width: '98%',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'column',
      // marginTop: 20,
      marginBottom: 10,
    },
    textSub: {
      fontWeight: 400,
      fontSize: 12,
      margin: 0,
      padding: 0,
      color: '#3A3E4B'
    },
    textTitle: {
      fontWeight: 400,
      fontSize: 19,
      margin: 0,
      padding: 0,
      color: '#3A3E4B'
    },
    scrollCustom: {

      background: '#fff',
      overflowY: 'scroll',

      '&::-webkit-scrollbar': {
          backgroundColor: '#fff',
          width: 12,     /* Tamaño del scroll en vertical */
          height: 12,    /* Tamaño del scroll en horizontal */
          // display: 'none',  /* Ocultar scroll */
      },

      /* background of the scrollbar except button or resizer */
      '&::-webkit-scrollbar-track': { backgroundColor: '#fff' },

      '&::-webkit-scrollbar-track:hover': { backgroundColor: '#f4f4f4' },

      /* scrollbar itself */
      '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#babac0',
          borderRadius: 16,
          border: '4px solid #fff',
      },
      '&::-webkit-scrollbar-thumb:hover': {
          backgroundColor: '#a0a0a5',
          border: '4px solid #f4f4f4'
      },

      /* set button(top and bottom of the scrollbar) */
      '&::-webkit-scrollbar-button': { display: 'none' },
  }

  })
);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function (props) {

  const { visible, toggle, users, handleSave, peopleKeep } = props;
  const { mainCtx, localStore } = useContext(GlobalContext);
  const { officeId } = mainCtx;
  // ----------------------------------------------------------
  const classes = useStyles();
  let inputEl = createRef();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  // ----------------------------------------------------------
  const [loading, setLoading] = useState(false);
  const [userList, setUserList] = useState([])
  const [buttonAction, setButtonAction] = useState(false)
  const [checked, setChecked] = useState(false);
  // ----------------------------------------------------------
  useEffect(() => {
    if (!isEmpty(peopleKeep) && peopleKeep.length > 0) {
      const tt = peopleKeep.map(t => t.id)
      const newOption = users.map(resp => ({ ...resp, selected: tt.includes(resp.id) }))
      setUserList(newOption)
    } else {
      setUserList(users)
    }
  }, [peopleKeep, users]);
  // ----------------------------------------------------------
  const selectUser = (item) => () => {

    const indexArray = userList.findIndex((t) => t.id === item.id);
    let newDataArray = { ...item, selected: !item.selected };
    const newAdd = update(indexArray, newDataArray, userList)
    setChecked(false)
    setUserList(newAdd)
    // ---------------------------------------------------
    const resul = newAdd.filter((x) => x.selected === true).length;
    // Al menos un elemento del array cumple con la condición
    // const even = (element) => element.selected;
    // if (newAdd.some(even) && resul > 0) {
    //   setButtonAction(false)
    // } else setButtonAction(true);

  }
  // ----------------------------------------------------------
  const handleChange = (event) => {

    if(event.target.checked){
      const userSelect = userList.map( e => ({ ...e, selected: true }))
      setUserList(userSelect)  
    }else{
      const userSelect = userList.map( e => ({ ...e, selected: false }))
      setUserList(userSelect)
    }
    setChecked(event.target.checked);
  };

  // ----------------------------------------------------------

  return (

    <Dialog
      fullScreen={fullScreen}
      open={visible}
      keepMounted
      onClose={toggle}
      className={classes.dialog}
      // disableBackdropClick
      // disableEscapeKeyDown
      aria-labelledby="responsive-dialog-title"
      TransitionComponent={Transition}
    >
      <DialogTitle id="responsive-dialog-title" className={classes.typeModal}>FAVORITE MESSAGING
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={toggle}
          className={classes.closeButton}
        >
          <HighlightOffIcon />
        </IconButton>

      </DialogTitle>
      <DialogContent ref={(e) => (inputEl = e)} className={clsx(classes.scrollCustom, '')}>
        {/* <DialogContentText> </DialogContentText> */}
        <div className={classes.boxTitle}>
          <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            {/* <img src={pencil} style={{ width: 33, height: 40 }} /> */}
            <img src={check} style={{ width: 'auto', height: 65 }} />
            <div>
              <div className={classes.textSub}>PINNED CHATS</div>
              <p className={classes.textTitle}>Select people to keep at home</p>
            </div>
          </div>
          <div style={{ width: '100%', textAlign: 'end', cursor: 'pointer' }}>
              {/* <p className='' style={{ marginTop: 0}}>
                <span onClick={handleChange('select')}>Select</span>/
                <span onClick={handleChange('deselect')}>Deselect </span>All
              </p> */}
            <FormControlLabel
              value="start"
              control={<Checkbox color="primary" checked={checked} onChange={handleChange} />}
              label={"Select all"}
              labelPlacement="start"
              style={{ margin: 0, height: 20 }}
            />
          </div>
        </div>

        <div style={{ width: '100%' }}>
          {userList.map(user => {
            return (
              <PersonList
                key={user.id}
                user={user}
                selectUser={selectUser}
              />
            );
          })}
        </div>

      </DialogContent>
      <DialogActions className={classes.btnAction}>
        <Button onClick={toggle}>
          CANCEL
        </Button>
        {!loading && (
          <Button onClick={handleSave(userList)} className="btnSave" disabled={buttonAction}>
            SAVE
          </Button>
        )}
        {loading && (
          <CircularProgress></CircularProgress>
        )}
      </DialogActions>
    </Dialog>

  );
}

