import React from "react";
import Paging from "./Paging/Paging";
import PatientTrackerModule from "./PatientTracker/PatientTrackerModule";
import moment from "moment";
import MessagingModule from "./Messaging/MessagingModule";

window.moment = moment;
export default function(props) {
    return (
        <div style={{ width: "100%" }}>
            <Paging />
            <MessagingModule></MessagingModule>
            <PatientTrackerModule></PatientTrackerModule>
        </div>
    );
}
