import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery } from "@material-ui/core";
import { sortWith, ascend, prop } from "ramda";

import RoomContext from "./RoomContext";
import { useTimer } from "./TimerContext";
import Person from './Person';

function getWidth(cant, i) {
    if (cant < 6) {
        return '100%'
    } else {
        if (cant === 6) {
            switch (i) {
                case 0: return '49.7%'
                case 1: return '49.7%'
                default: return '100%'
            }
        } else if (cant === 7) {
            switch (i) {
                case 0: return '49.7%'
                case 1: return '49.7%'
                case 2: return '49.7%'
                case 3: return '49.7%'
                default: return '100%'
            }
        } else if (cant === 8) {
            switch (i) {
                case 0: return '49.7%'
                case 1: return '49.7%'
                case 2: return '49.7%'
                case 3: return '49.7%'
                case 4: return '49.7%'
                case 5: return '49.7%'
                default: return '100%'
            }
        } else if (cant === 9) {
            switch (i) {
                case 0: return '49.7%'
                case 1: return '49.7%'
                case 2: return '49.7%'
                case 3: return '49.7%'
                case 4: return '49.7%'
                case 5: return '49.7%'
                case 6: return '49.7%'
                case 7: return '49.7%'
                default: return '100%'
            }
        } else if (cant >= 10) {
            return '49.7%'
        }
    }
}

const useStyles = makeStyles({
    root: {
        width: (props) => getWidth(props.cantRooms, props.index),
        backgroundColor: '#197FE8',
        borderRadius: 5,
        padding: 2,
    },
    boxTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        color: 'white',
        padding: '2px 0 0 10px',
        '& p': {
            margin: 0, padding: 0,
            fontSize: 12,
        },
    },
});

export default (props) => {
    const { person, cantRooms } = props;
    // console.log("🚀 ~ file: Room.js ~ line 77 ~ person", person)
    const rooms = person.rooms;
    const classes = useStyles(props);
    // ---------------------------------------
    const smScreen = useMediaQuery("(max-width:600px)");
    const mdScreen = useMediaQuery("(max-width:960px)");
    const lgScreen = useMediaQuery('(max-width:1280px)');
    const xlScreen = useMediaQuery("(max-width:1920px)");
    // ---------------------------------------
    const { pause, unpause, onTick } = useTimer();
    const displayedPersonIndexRef = useRef(0);
    const [displayedPersonIndex, setDisplayedPersonIndex] = useState(0);

    const setIndex = index => {
        displayedPersonIndexRef.current = index;
        setDisplayedPersonIndex(index);
    };
    useEffect(() => {
        onTick(() => {
            setIndex(displayedPersonIndexRef.current + 1);
        });

        return () => {
            onTick(() => { });
        };
    }, []);

    const getWrap = (cant) => {
        if (Object.keys(person.rooms).length < 5) {
            return classes.boxPersons
        } else {

        }

    }

    const displayedPersonId = Object.keys(rooms)[
        displayedPersonIndexRef.current % Object.keys(rooms).length
    ];

    return (
        <RoomContext.Provider
            value={{
                room: rooms,
                persons: person,
                displayedPersonId,
                setIndex
            }}
        >
            <div
                onMouseEnter={() => {
                    pause();
                }}
                onMouseLeave={() => {
                    unpause();
                }}
                className={classes.root}
                id="MasonryId"
                style={{ margin: smScreen ? '1%' : 5 }}
            >
                <div className={classes.boxTitle}>
                    <p>{person.name}</p>
                    {/* <p>{Object.keys(person.rooms).length}</p> */}
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    width: '100%',
                    backgroundColor: 'white',
                    borderRadius: 3,
                    paddingRight: 2,
                    paddingLeft: 2,
                    flexWrap: Object.keys(person.rooms).length < 4 ? 'inherit' : 'wrap'
                }}>
                    {getSortedPersonIds(person.rooms).map((item, index) => {
                        return (
                            <Person
                                key={item}
                                rooms={{ ...person.rooms[item], item }}
                                cantPerson={Object.keys(person.rooms).length}
                                index={index}
                            />
                            // person.rooms[item].name
                        );
                    })}
                </div>
            </div>
        </RoomContext.Provider>
    )
}

function getSortedPersonIds(persons) {
    const sorted = Object.keys(persons)
        // .sort((a, b) => {
        //     if (persons[a].name > persons[b].name) {
        //         return 1;
        //     }
        //     if (persons[a].name < persons[b].name) {
        //         return -1;
        //     }
        //     return 0;
        // })
        // .sort((a, b) => {
        //     const personA = { ...persons[a] };
        //     const personB = { ...persons[b] };
        //     personA.position = personA.position
        //         ? personA.position
        //         : 0;
        //     personB.position = personB.position
        //         ? personB.position
        //         : 0;
        //     return personA.position - personB.position;
        // });
        .sort((a, b) => {
            const atime = parseInt(persons[a].time);
            const btime = parseInt(persons[b].time);
            return atime - btime;
        })
    return sorted;
}