import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Resizable } from "re-resizable";
import { makeStyles } from '@material-ui/core/styles';

import GlobalContext from "../../../../hooks/GlobalContext";

import Person from './Person';

import face from '../../../../assets/face.png'

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
        // justifyContent: 'space-around',
        flexWrap: 'wrap',
        backgroundColor: '#E9EDF8',
        width: '98%',
        height: '100%',
        padding: 5,
        marginTop: 10,
        marginBottom: 10,
        borderRadius: 10,
        overflow: 'auto'
    },
    boxTitle: {
        display: 'flex',
        width: '98%',
        alignItems: 'center',
        marginTop: 20,
        marginBottom: 10,
    },
    text: {
        fontWeight: 700,
        fontSize: 22,
        margin: 0,
        padding: 0,
        marginLeft: 20,
        color: '#3A3E4B'
    },
    scrollCustom: {

        background: '#fff',
        overflowY: 'scroll',

        '&::-webkit-scrollbar': {
            backgroundColor: '#fff',
            width: 12,     /* Tamaño del scroll en vertical */
            height: 12,    /* Tamaño del scroll en horizontal */
            // display: 'none',  /* Ocultar scroll */
        },

        /* background of the scrollbar except button or resizer */
        '&::-webkit-scrollbar-track': { backgroundColor: '#fff' },

        '&::-webkit-scrollbar-track:hover': { backgroundColor: '#f4f4f4' },

        /* scrollbar itself */
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#babac0',
            borderRadius: 16,
            border: '4px solid #fff',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#a0a0a5',
            border: '4px solid #f4f4f4'
        },

        /* set button(top and bottom of the scrollbar) */
        '&::-webkit-scrollbar-button': { display: 'none' },
    }
});

export default (props) => {
    const { persons, setSelected } = props;
    const {
        localStore,
    } = useContext(GlobalContext);

    const [data, setData] = useState([])

    useEffect(() => {
        let newArray = []
            .sort((a, b) => {
                if (a.name > b.name) {
                    return 1;
                }
                if (a.name < b.name) {
                    return -1;
                }
                return 0;
            })
            .sort((a, b) => {
                const personA = { ...a };
                const personB = { ...b };
                personA.listPosition = personA.listPosition
                    ? personA.listPosition
                    : 0;
                personB.listPosition = personB.listPosition
                    ? personB.listPosition
                    : 0;
                return personA.listPosition - personB.listPosition;
            }).map((key, index) => {
                newArray.push({ ...persons[key], id: key, selected: false })
            });
        setData(newArray)
    }, [persons])

    const classes = useStyles();

    const onSelect = (row) => () => {
        const newData = data.map((e) => {
            if (e.id === row.id) {
                return { ...e, selected: e.selected === true ? false : true }
            }
            return { ...e, selected: false }
        })
        const selected = data.filter((e) => e.id === row.id)
        setData(newData)
        if (!row.selected) {
            setSelected(selected[0])
        } else {
            setSelected({})
        }
    }

    return (
        <Resizable
            style={{ display: "flex", justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}
            // defaultSize={{ height: 350 }}
            enable={{ bottom: true }}
            onResizeStop={(event, direction, refToElement, delta) => {
                const newHeight = parseInt(refToElement.style.height);
                localStore.update(
                    localStore.storageKeys.roomsHeight,
                    newHeight
                );
            }}
        >
            <div className={classes.boxTitle}>
                <img src={face} /*style={{ width: 33, height: 40}}*/ />
                <p className={classes.text}>Who do you want to Page?</p>
            </div>
            <div className={clsx(classes.root, '')}>
                {getSortedPersonIds(persons).map(per => {
                    return (
                        <Person
                            key={per.id}
                            person={{ ...per, personId: per.id }}

                        />
                    );
                })}
            </div>
        </Resizable>
    );
}

function getSortedPersonIds(persons) {
    const sorted = persons
        .sort((a, b) => {
            if (a.name > b.name) {
                return 1;
            }
            if (a.name < b.name) {
                return -1;
            }
            return 0;
        })
        .sort((a, b) => {
            const personA = { ...a };
            const personB = { ...b };
            personA.listPosition = personA.listPosition
                ? personA.listPosition
                : 0;
            personB.listPosition = personB.listPosition
                ? personB.listPosition
                : 0;
            return personA.listPosition - personB.listPosition;
        })


    return sorted;
}