import React, { useState, useEffect, useContext } from "react";
import { isEmpty, isNil, view } from 'ramda';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import GlobalContext from "../../../../hooks/GlobalContext";
import PatientTrackingContext from "../PatientTrackingContext";
import { VisitContext } from "../VisitContext";
import TickerContext from "../TickerContext";

import endVisit from "../../../../firebase/httpsCallable/endVisit";
import moveToStage from "../../../../firebase/httpsCallable/moveToStage";
import moveToRoom from "../../../../firebase/httpsCallable/moveToRoom";
import updateVisitTimes from "../../../../firebase/httpsCallable/updateVisitTimes";
import updatePatientName from "../../../../firebase/httpsCallable/updatePatientName";
import smsMessages from "../../../../firebase/httpsCallable/smsMessages";
import emailMessages from "../../../../firebase/httpsCallable/emailMessages";

import Room from "./Cells/Room";
import Time from "./Cells/Time";
import EndVisit from "./Cells/EndVisit";
import TimeOnStage from "./Cells/TimeOnStage";
import CustomPopper from "./CustomPopper";

import DialogEndVisit from "./Modals/DialogEndVisit";
import DialogRoom from "./Modals/DialogRoom";
import DialogTime from "./Modals/DialogTime";

const cellStyle = {
    fontWeight: "bold",
    overflowX: "hidden",
    whiteSpace: "nowrap",
    cursor: "pointer",
    textOverflow: "ellipsis",
    textAlign: "center",
    userSelect: "none",
    cursor: 'pointer',
    width: '100%',
    height: 40,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
};

const useStyles = makeStyles((theme) => ({
    root: {
    },
}));

export default function (props) {

    const { localStore, mainCtx } = useContext(GlobalContext);
    const { officeId } = mainCtx;
    const { stages, columnLayout, rooms, visitLate } = useContext(PatientTrackingContext);
    const ticks = useContext(TickerContext);
    const visit = useContext(VisitContext);

    const classes = useStyles();
    let timestampNow = moment(new Date()).format("MM-DD-YYYY");
    const [invalidDate, setInvalidDate] = useState(false);
    // ----------------------------------------------------------
    const [latestRoom, setLatestRoom] = useState({});
    const [latestStage, setLatestStage] = useState({});

    // ----------------------------------------------------------
    // ----------------------------------------------------------
    // DialogSMS
    const [modalSms, setModalSms] = useState(false);
    const [loadingSms, setLoadingSms] = useState(false);

    // ----------------------------------------------------------
    // ----------------------------------------------------------
    // DialogEmail
    const [modalEmail, setModalEmail] = useState(false);
    const [loadingEmail, setLoadingEmail] = useState(false);

    // ----------------------------------------------------------
    // ----------------------------------------------------------
    // DialogEndVisit
    const [modalEndVisit, setModalEndVisit] = useState(false);
    const [loadingEndVisit, setLoadingEndVisit] = useState(false);
    const [formEndVisit, setFormEndVisit] = useState({
        patientName: visit.name,
        lastName: visit.lastName,
    });
    // ----------------------------------------------------------
    // ----------------------------------------------------------
    // DialogRoom
    const [modalRoom, setModalRoom] = useState(false);
    const [loadingRoom, setLoadingRoom] = useState(false);
    const [formRoom, setFormRoom] = useState({
        patientName: '',
        lastName: '',
        stage: '',
        room: '',
    });
    const [formRoomError, setFormRoomError] = useState({
        stage: false,
        room: false,
    });

    const latestStageDetail = visit.stageHistory[visit.stageHistory.length - 1];
    const stageId = latestStageDetail.stageId;
    const currentStage =
        stages && stages.length> 0
            ? stages.find(t => t.id === `${stageId}`)
            : {
                id: "??",
                name: "??",
                backgroundColor: "grey",
                textColor: "white"
            };

    const latestRoomDetail = visit.roomHistory[visit.roomHistory.length - 1];
    const roomId = latestRoomDetail.roomId;
    const currentroom =
        rooms && rooms.length > 0
            ? rooms.find(t => t.id === `${roomId}`)
            : {
                id: "??",
                name: "??"
            };
    // ----------------------------------------------------------   
    // ----------------------------------------------------------
    // DialogTime
    const [validateEmail, setValidateEmail] = useState(false);
    const [modalTime, setModalTime] = useState(false);
    const [loadingTime, setLoadingTime] = useState(false);
    const [formTime, setFormTime] = useState({
        patientName: '',
        lastName: '',
        arrivalTime: '',
        appointmentTime: '',
        appointmentLength: '',

        information: false,
        contactFirstName: '',
        contactLastName: '',
        contactPhone: '',
        // contactEmail: '',
        // contactFavorite: ''
    });
    const [formTimeError, setFormTimeError] = useState({
        patientName: false,
        lastName: false,
        arrivalTime: false,
        appointmentTime: false,
        appointmentLength: false,

        contactFirstName: false,
        contactLastName: false,
        contactPhone: false,
        // contactEmail: false,
    });
    // ----------------------------------------------------------
    // ----------------------------------------------------------
    // Popper CustomPopper
    const [openSelected, setOpenSelected] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [placement, setPlacement] = useState();
    // ----------------------------------------------------------
    // ----------------------------------------------------------

    useEffect(() => {
        if (!isEmpty(currentroom) && !isEmpty(currentStage)) {
            setFormRoom({
                patientName: visit.name,
                lastName: visit.lastName,
                stage: currentStage.id,
                room: currentroom.id,
            })
        }
        if (!isEmpty(visit)) {
            setFormTime({
                patientName: visit.name,
                lastName: visit.lastName,
                arrivalTime: moment(visit.arrivalTime).format("MM-DD-YYYY hh:mm a"),
                appointmentTime: moment(visit.appointmentTime).format("MM-DD-YYYY hh:mm a"),
                appointmentLength: Math.floor((visit.dueOutTime - visit.appointmentTime) / 60000),

                information: !isEmpty(visit.contactHistory) ? true : false,
                contactFirstName: !isEmpty(visit.contactHistory) ? visit.contactHistory.firstName : '',
                contactLastName: !isEmpty(visit.contactHistory) ? visit.contactHistory.lastName : '',
                contactPhone: !isEmpty(visit.contactHistory) ? visit.contactHistory.phone : '',
                // contactEmail: !isEmpty(visit.contactHistory) ? visit.contactHistory.email : '',
                // contactFavorite: !isEmpty(visit.contactHistory) && visit.contactHistory.hasOwnProperty('favorite') ? visit.contactHistory.favorite : ''
            })
        }
    }, [visit])

    useEffect(() => {
        setLatestRoom(visit.roomHistory[visit.roomHistory.length - 1]);
        setLatestStage(visit.stageHistory[visit.stageHistory.length - 1]);
    }, [visit.stageHistory, visit.roomHistory]);

    // -------------------------------------------------------
    // -------------------------------------------------------
    /**
     * @author Randall Medina
     * @description Method to end the tracking query
     * @param officeId
     * @param visitId
     */
    // DialogEndVisit
    const handleSaveEndVisit = () => {

        setLoadingEndVisit(true);

        endVisit({
            officeId: officeId,
            visitId: visit.id
        })
            .then(result => {
                setLoadingEndVisit(false);
                toggleDialogEndVisit();
            })
            .catch(err => {
                setLoadingEndVisit(false);
            });
    }
    const toggleDialogEndVisit = () => {
        setModalEndVisit(!modalEndVisit);
    }
    // -------------------------------------------------------
    // -------------------------------------------------------
    // DialogRoom
    const onChangeTextRoom = (name) => (event) => {
        setFormRoom({ ...formRoom, [name]: `${event.target.value}` })
    };
    const toggleDialogRoom = () => {
        setFormRoom({ stage: currentStage.id, room: currentroom.id, patientName: visit.name, lastName: visit.lastName })
        setFormRoomError({ stage: false, room: false, })
        setModalRoom(!modalRoom);
    }

    const orderedStages = stages
        .sort((idA, idB) => {
            if (idA.listPosition > idB.listPosition) {
                return 1;
            }
            if (idA.listPosition < idB.listPosition) {
                return -1;
            }
            return 0;
        });

    const orderedrooms = rooms
        .sort((idA, idB) => {
            if (idA.listPosition > idB.listPosition) {
              return 1;
            }
            if (idA.listPosition < idB.listPosition) {
                return -1;
            }
            return 0;
        });

    /**
     * @author Randall Medina
     * @description Method to update the room or stage
     * @param formRoom
     * @param officeId
     * @param visitId
     */
    const handleSaveRoom = () => {

        if (isEmpty(formRoom.room) || isNil(formRoom.room) || isEmpty(formRoom.stage) || isNil(formRoom.stage)) {

            setFormRoomError({
                room: !formRoom.room,
                stage: !formRoom.stage,
            });

        } else {

            setFormRoomError({
                stage: false,
                room: false,
            });
            setLoadingRoom(true);

            if (formRoom.room !== currentroom.id && formRoom.stage === currentStage.id) {
                moveToRoom({
                    officeId: officeId,
                    visitId: visit.id,
                    roomId: formRoom.room
                })
                    .then(() => {
                        setLoadingRoom(false);
                        toggleDialogRoom();
                    })
                    .catch(err => {
                        setLoadingRoom(false);
                    });

            } else if (formRoom.room === currentroom.id && formRoom.stage !== currentStage.id) {

                moveToStage({
                    officeId: officeId,
                    visitId: visit.id,
                    stageId: formRoom.stage
                })
                    .then(() => {
                        setLoadingRoom(false);
                        toggleDialogRoom();
                    })
                    .catch(err => {
                        setLoadingRoom(false);
                    });

            } else {

                moveToRoom({
                    officeId: officeId,
                    visitId: visit.id,
                    roomId: formRoom.room
                })
                    .then(() => {
                        // --------------------------------
                        moveToStage({
                            officeId: officeId,
                            visitId: visit.id,
                            stageId: formRoom.stage
                        })
                            .then(() => {
                                setLoadingRoom(false);
                                toggleDialogRoom();
                            })
                            .catch(err => {
                                setLoadingRoom(false);
                            });
                        // --------------------------------
                    })
                    .catch(err => {
                        setLoadingRoom(false);
                    });

            }
        }
    }
    // -------------------------------------------------------
    // -------------------------------------------------------
    const onChangeTextTime = (event) => setFormTime({ ...formTime, [event.target.name]: event.target.value });
    const handleChangeDate = (name) => (event) => {

        if (name === 'arrivalTime') {
            if (moment(`${timestampNow} ${event.target.value}`, 'MM-DD-YYYY hh:mm a').valueOf() > Date.now()) {
                setInvalidDate(true)
                setFormTime({ ...formTime, [name]: moment(`${timestampNow} ${event.target.value}`).format('MM-DD-YYYY hh:mm a') });
            } else {
                setInvalidDate(false)
                setFormTime({ ...formTime, [name]: moment(`${timestampNow} ${event.target.value}`).format('MM-DD-YYYY hh:mm a') });
            }
        } else {
            setFormTime({ ...formTime, [name]: moment(`${timestampNow} ${event.target.value}`).format('MM-DD-YYYY hh:mm a') });
        }
    }
    const toggleDialogTime = () => {
        setFormTime({
            appointmentLength: Math.floor((visit.dueOutTime - visit.appointmentTime) / 60000),
            patientName: visit.name, lastName: visit.lastName,
            arrivalTime: moment(visit.arrivalTime).format("MM-DD-YYYY hh:mm a"),
            appointmentTime: moment(visit.appointmentTime).format("MM-DD-YYYY hh:mm a"),

            information: !isEmpty(visit.contactHistory) ? true : false,
            contactFirstName: !isEmpty(visit.contactHistory) ? visit.contactHistory.firstName : '',
            contactLastName: !isEmpty(visit.contactHistory) ? visit.contactHistory.lastName : '',
            contactPhone: !isEmpty(visit.contactHistory) ? visit.contactHistory.phone : '',
            // contactEmail: !isEmpty(visit.contactHistory) ? visit.contactHistory.email : '',
            // contactFavorite: !isEmpty(visit.contactHistory) && visit.contactHistory.hasOwnProperty('favorite') ? visit.contactHistory.favorite : ''
        })
        setFormTimeError({ arrivalTime: false, appointmentTime: false, appointmentLength: false, patientName: false, lastName: false })
        setInvalidDate(false);
        setModalTime(!modalTime);
    }
    const handleFavorite = (name) => (event) => {
        if (event.target.checked) {
            setFormTime({ ...formTime, contactFavorite: name })
        } else {
            setFormTime({ ...formTime, contactFavorite: '' })
        }
    }
    const onChangeTextSms = (event) => {
        setFormTime({ ...formTime, contactPhone: event })
    }
    // ----------------------------------------------------------
    /**
     * @author Randall Medina
     * @description Method to update the name, query time
     * @param formTime
     * @param officeId
     * @param visitId
     */
    const handleSaveTime = () => {

        if (isNil(formTime.arrivalTime) || isNil(formTime.appointmentTime) || isNil(formTime.appointmentLength)
            || isEmpty(formTime.appointmentLength) || isEmpty(formTime.patientName) || isEmpty(formTime.lastName) || invalidDate
            || (formTime.information && (
                isEmpty(formTime.contactFirstName) || isEmpty(formTime.contactLastName)
                || isEmpty(formTime.contactPhone) /*|| isEmpty(formTime.contactEmail)*/
            ))
        ) {
            setFormTimeError({
                patientName: !formTime.patientName,
                lastName: !formTime.lastName,
                arrivalTime: !formTime.arrivalTime,
                appointmentTime: !formTime.appointmentTime,
                appointmentLength: !formTime.appointmentLength,

                contactFirstName: !formTime.contactFirstName,
                contactLastName: !formTime.contactLastName,
                contactPhone: !formTime.contactPhone,
                // contactEmail: !formTime.contactEmail,
                // contactFavorite: !formTime.contactFavorite
            });

        } else {

            setFormTimeError({
                patientName: false,
                lastName: false,
                arrivalTime: false,
                appointmentTime: false,
                appointmentLength: false,

                contactFirstName: false,
                contactLastName: false,
                contactPhone: false,
                // contactEmail: false,
            });

            if (formTime.information) {

                setLoadingTime(true);

                let lateArrival = '';
                let lateColor = '';
                if (visitLate && visitLate.lateArrivals && visitLate.lateArrivals === 'yes') {

                    const time1 = moment(formTime.arrivalTime, 'MM-DD-YYYY hh:mm a').valueOf();
                    const time2 = moment(formTime.appointmentTime, 'MM-DD-YYYY hh:mm a').valueOf();
                    if (time1 > time2) {
                        lateArrival = time1
                        lateColor = '#df3c3c'
                    } else {
                        lateArrival = time2
                    }

                } else {
                    lateArrival = moment(formTime.appointmentTime, 'MM-DD-YYYY hh:mm a').valueOf();
                }

                const body = {
                    firstName: formTime.contactFirstName,
                    lastName: formTime.contactLastName,
                    phone: formTime.contactPhone,
                    // email: formTime.contactEmail,
                    // favorite: formTime.contactFavorite
                }
                updateVisitTimes({
                    ...formTime,
                    arrivalTime: moment(formTime.arrivalTime, 'MM-DD-YYYY hh:mm a').valueOf(),
                    appointmentTime: lateArrival,
                    officeId: officeId,
                    visitId: visit.id,
                    dueOutTime: lateArrival + formTime.appointmentLength * 60000,
                    lateColor,
                    contactHistory: body
                })
                    .then(result => {

                        updatePatientName({
                            officeId: officeId,
                            visitId: visit.id,
                            name: formTime.patientName,
                            lastName: formTime.lastName
                        })
                            .then(result => {
                                setLoadingTime(false);
                                toggleDialogTime();
                            })
                            .catch(err => {
                                setLoadingTime(false);
                            });

                    })
                    .catch(err => {
                        setLoadingTime(false);
                    });


            } else {

                setLoadingTime(true);

                let lateArrival = '';
                let lateColor = '';
                if (visitLate && visitLate.lateArrivals && visitLate.lateArrivals === 'yes') {

                    const time1 = moment(formTime.arrivalTime, 'MM-DD-YYYY hh:mm a').valueOf();
                    const time2 = moment(formTime.appointmentTime, 'MM-DD-YYYY hh:mm a').valueOf();
                    if (time1 > time2) {
                        lateArrival = time1
                        lateColor = '#df3c3c'
                    } else {
                        lateArrival = time2
                    }

                } else {
                    lateArrival = moment(formTime.appointmentTime, 'MM-DD-YYYY hh:mm a').valueOf();
                }

                updateVisitTimes({
                    ...formTime,
                    arrivalTime: moment(formTime.arrivalTime, 'MM-DD-YYYY hh:mm a').valueOf(),
                    appointmentTime: lateArrival,
                    officeId: officeId,
                    visitId: visit.id,
                    dueOutTime: lateArrival + formTime.appointmentLength * 60000,
                    lateColor,
                    contactHistory: {}
                })
                    .then(result => {

                        updatePatientName({
                            officeId: officeId,
                            visitId: visit.id,
                            name: formTime.patientName,
                            lastName: formTime.lastName
                        })
                            .then(result => {
                                setLoadingTime(false);
                                toggleDialogTime();
                            })
                            .catch(err => {
                                setLoadingTime(false);
                            });

                    })
                    .catch(err => {
                        setLoadingTime(false);
                    });

            }
        }

    }

    // -------------------------------------------------------
    // -------------------------------------------------------
    /**
     * @author Randall Medina
     * @description method to send sms by twilio
     * @param formTime
     * @param officeId
     * @param visitId
     */
    const handleSaveSms = () => {

        setLoadingSms(true);
        smsMessages({
            stageId,
            roomId,
            officeId: officeId,
            visitId: visit.id,
            phone: `${visit.contactHistory.phone}`,
            patient: `${visit.name} ${visit.lastName}`
        }).then(result => {

        }).catch(err => {
            setLoadingSms(false);
        });

    }
    // -------------------------------------------------------
    // -------------------------------------------------------
    /**
     * @author Randall Medina
     * @description method to send email by twilio
     * @param formTime
     * @param officeId
     * @param visitId
     */

    const handleSaveEmail = () => {

        setLoadingEmail(false);
        emailMessages({
            stageId,
            roomId,
            officeId: officeId,
            visitId: visit.id,
            email: visit.contactHistory.email,
            patient: `${visit.name} ${visit.lastName}`
        }).then(result => {

        }).catch(err => {
            setLoadingEmail(false);
        });

    }

    // -------------------------------------------------------
    // -------------------------------------------------------
    // Popper CustomPopper
    const handlePopoverOpen = newPlacement => event => {
        // setAnchorEl(anchorEl ? null : event.currentTarget);
        setAnchorEl(event.currentTarget);
        setOpenSelected(prev => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };
    /**
     * @author Randall Medina
     * @description Method of executing type-dependent modals
     * @param type
     */
    const handleClose = type => (event) => {
        if (type === 'next') {
            setOpenSelected(false);
            setModalRoom(true);
        } else if (type === 'edit') {
            setOpenSelected(false);
            setModalTime(true);
        } else if (type === 'Finalize') {
            setOpenSelected(false);
            setModalEndVisit(true);
        } else if (type === 'email') {
            setOpenSelected(false);
            handleSaveEmail()
            setModalEmail(true)
        } else if (type === 'sms') {
            setOpenSelected(false);
            handleSaveSms()
            setModalSms(true)
        } else {
            // close
            setOpenSelected(false);
        }
    }

    // -------------------------------------------------------
    // -------------------------------------------------------

    const minutesRemaining = Math.floor(
        (parseInt(visit.dueOutTime) - Date.now()) / 60000
    );

    const stageBackgroundColor = (() => {
        const defaultColor = "#ffffff"
        if (minutesRemaining < 1 && ticks % 2 == 0) {
            return '#df3c3c'
        }
        return defaultColor
    })();

    const stageTextColor = (() => {
        const defaultColor = "#197fe8"
        if (minutesRemaining < 1 && ticks % 2 == 0) {
            return '#ffffff'
        }
        return defaultColor
    })();

    const stageBubbleColor = (() => {
        const defaultColor = "#838383"
        if ( latestStage && latestStage.stageId && stages.length> 0) {
            const findItem = stages.find(t => t.id === `${latestStage.stageId}`)
            return findItem.backgroundColor
        }
        return defaultColor
    })();

    const cellDictionary = {
        Name: (
            <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FiberManualRecordIcon style={{ color: stageBubbleColor, height: '0.8em', width: '0.8em' }} />
                    <br />
                    <Time key={"Name"} style={{ ...cellStyle, borderRight: "1px solid #197fe8", color: '#197fe8' }} textComponent="Name" roomIds={latestRoom.roomId} />
                </div>
            </div>
        ),
        Room: (
            <Room
                key={"Room"}
                style={{ ...cellStyle, borderRight: "1px solid #197fe8", color: '#197fe8' }}
                roomIds={latestRoom.roomId}
                stageIds={latestStage.stageId}
                textComponent="Room"
            />
        ),
        Stage: (
            <Room
                key={"Stage"}
                style={{ ...cellStyle, borderRight: "1px solid #197fe8", color: '#197fe8' }}
                roomIds={latestRoom.roomId}
                stageIds={latestStage.stageId}
                textComponent="Stage"
            />
        ),
        ArrivalTime: (
            <Time key={"ArrivalTime"} style={{ ...cellStyle, borderRight: "1px solid #197fe8", color: '#197fe8', fontSize: 15 }} textComponent="ArrivalTime" />
        ),
        AppointmentTime: (
            <Time key={"AppointmentTime"} style={{ ...cellStyle, borderRight: "1px solid #197fe8", color: visit.lateColor && !isEmpty(visit.lateColor) ? '#fff' : '#197fe8', fontSize: 15, backgroundColor: visit.lateColor && !isEmpty(visit.lateColor) ? visit.lateColor : '' }} textComponent="AppointmentTime" />
        ),
        TimeElapsed: (
            <EndVisit key={"TimeElapsed"} style={{ ...cellStyle, borderRight: "1px solid #197fe8", color: '#197fe8', fontSize: 15 }} textComponent="TimeElapsed" />
        ),
        TimeRemaining: (
            <EndVisit
                key={"TimeRemaining"}
                textComponent="TimeRemaining"
                style={{ ...cellStyle, backgroundColor: stageBackgroundColor, color: stageTextColor, textAlign: 'center', fontSize: 15, borderRight: "1px solid #197fe8" }}
            />
        ),
        TimeOnStage: (
            <TimeOnStage
                key={"TimeOnStage"}
                textComponent="TimeOnStage"
                style={{ ...cellStyle, borderRight: "1px solid #197fe8", color: '#197fe8', fontSize: 15 }}
            />
        ),
    };

    return (
        <div
            style={{
                display: 'flex',
                borderBottom: "1px solid #197fe8",
                alignItems: 'center'
            }}
        >
            {columnLayout.map((element) => {
                return (
                    <div key={element} onClick={handlePopoverOpen(element.id)} style={{ width: element === 'Name' ? '32%' : columnLayout.length === 7 ? '11.33%' : columnLayout.length === 6 ? '13.6%' : '17%' }}>
                        {cellDictionary[element]}
                    </div>)
            })}

            {openSelected && (
                <CustomPopper
                    open={openSelected}
                    placement={placement}
                    anchorEl={anchorEl}
                    handleClose={handleClose}
                    visit={visit}
                />
            )}

            {modalEndVisit && (
                <DialogEndVisit
                    visible={modalEndVisit}
                    toggle={toggleDialogEndVisit}
                    handleSave={handleSaveEndVisit}
                    loading={loadingEndVisit}
                    setLoading={setLoadingEndVisit}
                    form={formEndVisit}
                />
            )}

            {modalRoom && (
                <DialogRoom
                    visible={modalRoom}
                    toggle={toggleDialogRoom}
                    handleSave={handleSaveRoom}
                    loading={loadingRoom}
                    setLoading={setLoadingRoom}
                    form={formRoom}
                    formError={formRoomError}
                    onChangeText={onChangeTextRoom}
                    orderedrooms={orderedrooms}
                    orderedStages={orderedStages}
                />
            )}

            {modalTime && (
                <DialogTime
                    visible={modalTime}
                    toggle={toggleDialogTime}
                    handleSave={handleSaveTime}
                    loading={loadingTime}
                    setLoading={setLoadingTime}
                    form={formTime}
                    formError={formTimeError}
                    setForm={setFormTime}
                    onChangeText={onChangeTextTime}
                    handleChangeDate={handleChangeDate}
                    timestampNow={timestampNow}
                    invalidDate={invalidDate}
                    handleFavorite={handleFavorite}
                    onChangeTextSms={onChangeTextSms}
                    validateEmail={validateEmail}
                />
            )}
        </div>
    );
}

function invertColor(hex) {
    if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
        throw new Error('Invalid HEX color.');
    }
    // invert color components
    var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
        g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
        b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
    // pad each with zeros and return
    return '#' + padZero(r) + padZero(g) + padZero(b);
}

function padZero(str, len) {
    len = len || 2;
    var zeros = new Array(len).join('0');
    return (zeros + str).slice(-len);
}