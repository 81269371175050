import React, { useContext, useState, useEffect } from 'react';
import { Resizable } from "re-resizable";
import { isEmpty, isNil, descend, prop, sortWith } from "ramda";
import { makeStyles } from '@material-ui/core/styles';
import { Button, useMediaQuery } from '@material-ui/core';

import firebase from "../../../firebase/firebase";
import GlobalContext from "../../../hooks/GlobalContext";
import useMessaging from "../Messaging/useMessaging";
import MessagingContext from "../Messaging/MessagingContext";
import usePatientTracking from "../Tracker/usePatientTracking";

import createKeepPeople from "../../../firebase/httpsCallable/createKeepPeople";
import keepPeopleDevice from '../../../firebase/httpsCallable/keepPeopleDevice';

// import UserList from "./UserList";
import UserMessages from "./UserMessages";
import PagingEventHandler from "./PagingEventHandler";
import Rooms from "./Rooms";
import Tracker from "../Tracker";
import DialogFavorite from "./Favorite/DialogFavorite";
import Messaging from "../Messaging";
import RoomsPerson from "./RoomsPerson";

import personAdd from '../../../assets/personAdd.png';
import pagingHome from '../../../assets/pagingHome.png';
import personWhite from '../../../assets/personWhite.png';
import userNot from '../../../assets/userNot.png';

const useStyles = makeStyles({
    root: {
        backgroundColor: 'white',
        // height: '100vh',
        marginBottom: '6%',
        display: 'flex',
        flexDirection: 'column',
    },
    containerHeader: {
        backgroundColor: '#EBEFF9',
        padding: '14px 17px',
    },
    button: {
        height: 50,
        backgroundColor: 'white',
        color: 'black',
        fontWeight: 'bold',
    },
    boxButton: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    buttonP: {
        height: 52,
        backgroundColor: 'white',
        color: '#3A3E4B',
        fontWeight: 'bold',
        width: '100%',
        borderRadius: 8,

        '&:hover': {
            background: 'linear-gradient(180deg, #AFDC43 0%, #9DCD2A 100%)',
            '& .box .box-text': {
                color: '#fff'
            }
        },

        '& .box': {
            display: 'flex',
            width: '100%',

            '& .box-img': {
                alignItems: 'center',
                display: 'flex',
            },
            '& .box-text': {
                width: '100%',
                fontSize: 15
            }
        }
    },
    buttonM: {
        height: 52,
        backgroundColor: 'white',
        color: '#3A3E4B',
        fontWeight: 'bold',
        width: '100%',
        borderRadius: 8,

        '&:hover': {
            background: 'linear-gradient(180deg, #FB793C 0%, #F06422 100%);',
            '& .box .box-text': {
                color: '#fff'
            }
        },
        '& .box': {
            display: 'flex',
            width: '100%',

            '& .box-img': {
                alignItems: 'center',
                display: 'flex',
            },
            '& .box-text': {
                width: '100%',
            }
        }
    },
    boxContentPaging: {
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '2%',
        paddingBottom: '2%',
        '& p': {
            alignSelf: 'flex-start',
            margin: 0,
            padding: 0,
            marginLeft: '20%',
            fontWeight: 'bold',
            color: '#3A3E4B',
        },
        '& .p-title': {
            fontSize: 14,
        },
        '& .p-sub': {
            fontSize: 20,
        },
    },
    boxContentUser: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0.6% 0',
        borderBottom: '1px solid #bdbdbd',

        '& p': {
            fontSize: 12,
        },
    },
    boxContentAll: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: 10,
        marginBottom: 5,
        marginLeft: '2.5%',

        '& .p-view': {
            color: '#3A3E4B',
            fontSize: 12,
            marginRight: 10,
            margin: 0
        },
        '& .p-edit': {
            color: '#3A3E4B',
            fontSize: 12,
            margin: 0,
            cursor: 'pointer',
        },
    },
    boxContentMessage: {

    },
    scrollCustom: {

        background: '#fff',
        overflowY: 'scroll',

        '&::-webkit-scrollbar': {
            backgroundColor: '#fff',
            width: 12,     /* Tamaño del scroll en vertical */
            height: 12,    /* Tamaño del scroll en horizontal */
            // display: 'none',  /* Ocultar scroll */
        },

        /* background of the scrollbar except button or resizer */
        '&::-webkit-scrollbar-track': { backgroundColor: '#fff' },

        '&::-webkit-scrollbar-track:hover': { backgroundColor: '#f4f4f4' },

        /* scrollbar itself */
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#babac0',
            borderRadius: 16,
            border: '4px solid #fff',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#a0a0a5',
            border: '4px solid #f4f4f4'
        },

        /* set button(top and bottom of the scrollbar) */
        '&::-webkit-scrollbar-button': { display: 'none' },
    }
});


export default () => {
    const { mainCtx, navigation, pagingDoc, localStore, viewHeader, licensesDoc, pagingState, deviceDoc, authCtx } = useContext(
        GlobalContext
    );
    const messaging = useMessaging();
    const { messagingDocListener } = useContext(MessagingContext);
    const patientTracking = usePatientTracking();
    const { visits } = patientTracking;
    const { statePaging, setStatePaging } = pagingState;

    const { officeId } = mainCtx;
    const persons = messaging.usersNewPaging;
    const rooms = pagingDoc.rooms;

    const users = messaging.users;
    const conversationsFull = messaging.conversationsFull;

    useEffect(() => {
        if (!statePaging) {
            setStatePaging(true)
        }
    }, [statePaging])

    PagingEventHandler({ ...pagingDoc, loadingOther: !statePaging ? false : true, users: persons });
    const classes = useStyles();
    const [view, setView] = useState(1)
    const [applyStyle, setApplyStyle] = useState(false);
    const [peopleKeep, setPeopleKeep] = useState([]);
    const [licenseTo, setLicenseTo] = useState('');
    // ----------------------------------------------------------
    const [dialogVisit, setDialogVisit] = useState(false)
    // ----------------------------------------------------------
    const smScreen = useMediaQuery("(max-width:600px)");
    const mdScreen = useMediaQuery("(max-width:960px)");
    const lgScreen = useMediaQuery('(max-width:1280px)');
    const xlScreen = useMediaQuery("(max-width:1920px)");
    // ----------------------------------------------------------

    useEffect(() => {
        viewHeader.setViewHeader(true)
    }, [])

    useEffect(() => {

        localStore.update(
            localStore.storageKeys.pagingSoundEnabled,
            true
        );
        localStore.update(
            localStore.storageKeys.messagingSoundEnabled,
            true
        );
        localStore.update(
            localStore.storageKeys.patientTrackingSoundEnabled,
            true
        );
    }, [])

    useEffect(() => {

        if (Object.keys(deviceDoc.devices).length > 0) {

            if (!isNil(window.device) && !isEmpty(window.device)) {
                const licenseMap = Object.keys(deviceDoc.devices).map(e => deviceDoc.devices[e]).find(t => t.user === window.device.user)
                if ( !isNil(licenseMap) && !isEmpty(licenseMap) && !licenseMap.session && !authCtx.isSuperAdmin) {
                    // remove from localstorage the office id
                    localStore.update(
                        localStore.storageKeys.selectedOfficeId,
                        null
                    );
                    // remove license id from localstorage
                    localStore.update(
                        localStore.storageKeys.messageLicense,
                        ''
                    );
                    firebase.auth().signOut();
                    mainCtx.setOfficeId(null);
                    navigation.toSignIn();
                }

                if (!isNil(licenseMap) && !isEmpty(licenseMap) && licenseMap.hasOwnProperty('selectedPeopleKeep')) {
                    setPeopleKeep(licenseMap.selectedPeopleKeep);
                } else {
                    setPeopleKeep([]);
                }
            }
        }

    }, [deviceDoc.devices])

    // useEffect(() => {

    //     if (Object.keys(licensesDoc.licenses).length > 0) {
    //         const licenseMap = Object.keys(licensesDoc.licenses).map(t => ({ ...licensesDoc.licenses[t], licenseId: t }));
    //         const resul = licenseMap.find(t => t.licenseId === localStore.data.messageLicense);
    //         setLicenseTo(resul && resul.licenseId ? resul.licenseId : '')
    //         if (!isNil(resul) && resul.hasOwnProperty('selectedPeopleKeep')) {
    //             setPeopleKeep(resul.selectedPeopleKeep);
    //         } else {
    //             setPeopleKeep([]);
    //         }
    //     }

    // }, [licensesDoc.licenses && Object.keys(licensesDoc.licenses).length > 0])

    // ----------------------------------------------------------
    const toggleVisit = () => setDialogVisit(!dialogVisit);
    // ----------------------------------------------------------
    const handleSave = (userList) => () => {
        if (!isNil(window.device) && !isEmpty(window.device)) {
            const resul = userList.filter((x) => x.selected === true).map(e => ({ id: e.id, name: e.name, lastName: e.lastName, selected: e.selected }));
            // --------------------------------
            keepPeopleDevice({
                officeId: officeId,
                keepPeople: resul,
                hostname: window.device.hostname,
                user: window.device.user
            })
                .then(() => {
                    setPeopleKeep(resul)
                    toggleVisit()
                })
                .catch(err => {
                });
        } else {
            console.log('No existe el id del dispostivo')
            toggleVisit()
        }


    }
    // ----------------------------------------------------------

    const renderHeader = () => {
        switch (view) {
            case 1:
                return (
                    <div className={classes.boxButton}>
                        <Button variant='contained' fullWidth className={classes.buttonP} style={{ marginRight: 8 }} onClick={navigation.toPaging} onMouseLeave={() => setApplyStyle(false)} onMouseEnter={() => setApplyStyle(true)}>
                            <div className="box">
                                <div className="box-img" >
                                    <img src={applyStyle ? personWhite : personAdd} style={{ marginRight: 10 }} />
                                </div>
                                <div className="box-text">PAGING</div>
                            </div>
                        </Button>
                        <Messaging />
                    </div>
                )
            default:
                return null;
        }
    }

    const verifyRoom = () => {
        const roomsFilter = Object.keys(rooms).filter(k => !isEmpty(rooms[k].persons))
        if (roomsFilter.length > 0) {
            return true
        }
        return false
    }
    const verifyRoomToPerson = () => {
        const personsFilter = Object.keys(persons).filter(k => !isNil(persons[k].rooms) && !isEmpty(persons[k].rooms))
        if (personsFilter.length > 0) {
            return true
        }
        return false
    }

    const renderView = () => {
        switch (view) {
            case 1:
                return (
                    <div>

                        <div>
                            <div className={classes.boxContentAll} style={{ marginRight: smScreen ? '5%' : '2.5%' }}>
                                <p className='p-edit' onClick={toggleVisit}>Edit</p>
                            </div>

                            <Messaging />

                            {/* list of messaging user bubbles */}
                            {users.length > 0 ?
                                <Resizable
                                    style={{ overflowY: "scroll", overflowX: "hidden", borderBottom: '1px solid #bdbdbd' }}
                                    defaultSize={{ height: `${localStore.data.messagesHeight}px` }}
                                    enable={{ bottom: true }}
                                    size={{ height: `${localStore.data.messagesHeight}px` }}
                                    onResizeStop={(event, direction, refToElement, delta) => {
                                        const newHeight = parseInt(refToElement.style.height);
                                        localStore.update(
                                            localStore.storageKeys.messagesHeight,
                                            newHeight
                                        );
                                    }}
                                    className={classes.scrollCustom}
                                >
                                    {getUsersNotification(users, conversationsFull, peopleKeep) ?
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'start',
                                            justifyItems: 'center',
                                            flexDirection: 'column',
                                            width: '95%',
                                            marginLeft: '4.5%',
                                            marginRight: '2.5%',
                                            marginBottom: 10,
                                        }}>
                                            <div>
                                                {messagingDocListener.loading && `LOADING...`}
                                                {getUsersNotification(users, conversationsFull, peopleKeep).map((item, index) => {
                                                    return (
                                                        <UserMessages key={index} index={index} usersList={item} />
                                                    );
                                                })}
                                            </div>
                                        </div>
                                        :
                                        null
                                    }
                                </Resizable>
                                :
                                <div className={classes.boxContentUser}>
                                    <img src={userNot} />
                                    <p className='p-sub'>There are no unread messages at this time.</p>
                                </div>
                            }
                        </div>

                        <Resizable
                            style={{ overflowY: "scroll", overflowX: "hidden", zIndex: 3, borderBottom: '1px solid #bdbdbd', marginBottom: '1%' }}
                            defaultSize={{ height: `${localStore.data.roomsHeight}px` }}
                            enable={{ bottom: true }}
                            size={{ height: `${localStore.data.roomsHeight}px` }}
                            onResizeStop={(event, direction, refToElement, delta) => {
                                const newHeight = parseInt(refToElement.style.height);
                                localStore.update(localStore.storageKeys.roomsHeight, newHeight);
                            }}
                            className={classes.scrollCustom}
                        >

                            {/* {!isEmpty(rooms) && verifyRoom() ?
                                <Rooms rooms={rooms} /> */}

                            {!isEmpty(persons) && verifyRoomToPerson() ?
                                <RoomsPerson persons={persons} />
                                :
                                <div className={classes.boxContentPaging}>
                                    <p className='p-title'>All offices are empty</p>
                                    <p className='p-sub'>To add a user to an</p>
                                    <p className='p-sub'>Office press " <img src={personAdd} /> PAGING"</p>
                                    {/* { Object.keys(visits).length > 0 ? null :  <img src={pagingHome} /> } */}
                                </div>
                            }
                        </Resizable>

                        <Tracker />

                    </div>
                )

            default:
                return null;
        }
    }

    return (
        <div className={classes.root}>
            {/* <div className={classes.containerHeader}>
                {renderHeader()}
            </div> */}
            {renderView()}

            {/* ------------------------------------ */}
            {dialogVisit && (
                <DialogFavorite
                    visible={dialogVisit}
                    toggle={toggleVisit}
                    users={messaging.users}
                    peopleKeep={peopleKeep}
                    handleSave={handleSave}
                />
            )}
            {/* ------------------------------------ */}
        </div>
    )
}

/**
 * @author Randall Medina
 * @description Method goes through the conversations and searches for each one if there is a user
 * @param users
 * @param conversation
 * @param peopleKeep
 */
function getUsersNotification(users, conver, peopleKeep) {

    const resultUser = users.map(e => {
        const tt = conver.length > 0 ? conver.map((resp) => {
            return { ...resp, resul: resp.userIds.filter(ids => ids === e.id) }
        }) : [];
        return { ...e, canales: tt.length > 0 ? tt.filter(j => j.resul[0] === e.id) : [] }
    });

    // let nuevoLista = [];
    const respon = resultUser.map((roomId) => {

        let nuevo = [];

        roomId.canales
            .sort((a, b) => {
                const atime = parseInt(a.latestMessage.time);
                const btime = parseInt(b.latestMessage.time);
                return btime - atime;
            })
            .map((item, index) => {
                const latesMessageTime = item.latestMessage.time;
                const hasUnread = (() => {
                    const lastReadTime = item.participants[roomId.id].lastMessage.time;
                    if (lastReadTime < latesMessageTime) {
                        return true;
                    }
                    return false;
                })();

                if (hasUnread) {
                    nuevo = nuevo.concat(item)
                    // nuevoLista = nuevoLista.concat(item)
                }
            });

        return { ...roomId, noti_count: nuevo.length, redirectChatId: nuevo.length === 1 ? nuevo[0] : '' }

    });
    // const filterNoti = respon.filter(e => e.noti_count > 0);

    // if (!isEmpty(localStore.data.selectedPeopleKeep) && localStore.data.selectedPeopleKeep.length > 0 ) {
    //     const tt = localStore.data.selectedPeopleKeep.map(t => t.id)
    //     return respon.filter(resp => tt.includes(resp.id));
    // } else {
    //     return respon
    // }
    if (!isEmpty(peopleKeep) && peopleKeep.length > 0) {
        const tt = peopleKeep.map(t => t.id)
        return respon.filter(resp => tt.includes(resp.id));
    } else {
        return respon
    }

}