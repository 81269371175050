import React, { createRef, useEffect, useState, useContext } from 'react';
import Select2 from 'react-select';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';
import { isEmpty, isNil, view, update } from 'ramda';
import clsx from 'clsx';
import MomentUtils from "@date-io/moment";
import esLocale from "date-fns/locale/es";
import DateFnsUtils from '@date-io/date-fns';

import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import {
  IconButton, useMediaQuery, Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions, Paper, Slide, ButtonBase, Button
} from '@material-ui/core';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import CheckIcon from '@material-ui/icons/Check';
import GroupIcon from '@material-ui/icons/Group';
import AddIcon from '@material-ui/icons/Add';
import check from '../../../../assets/check.png'

import CustomTextField from '../../../CustomTextField';

import MessagingContext from "../MessagingContext";


const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    dialog: {
      '& .MuiDialog-container .MuiDialog-paperWidthSm': {
        borderRadius: 20,
        // overflowY: 'auto',
        // overflowX: 'hidden',
        maxWidth: 544,
        width: '100%',
        background: '#fff',
        height: '35%'
        // padding: '2.5%',
      },
      '& .MuiDialog-container .MuiDialog-paperWidthSm .MuiDialogContent-root': {
        overflowY: 'auto',
        overflowX: 'hidden',
      },
    },
    typeModal: {
      background: '#F86F46',
      color: '#fff',
      padding: '7px 24px',
      textAlign: 'center'
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(0),
      padding: '4px',

      '& svg': {
        width: '1.5em',
        height: '1.5em',
      }
    },
    text: {
      margin: 0,
      padding: 0,
      color: '#030303',
      textAlign: 'left',
      fontSize: 12,
    },
    btnAction: {
      padding: '8px 24px',
      border: '1px solid #cecaca',

      '& button': {

      },
      '& .btnSave': {
        background: '#F86F46',
        color: '#fff',
      }
    },

    boxTitle: {
      display: 'flex',
      width: '98%',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: 20,
      marginBottom: 10,
    },
    textSub: {
      fontWeight: 400,
      fontSize: 12,
      margin: 0,
      padding: 0,
      color: '#3A3E4B'
    },
    textTitle: {
      fontWeight: 400,
      fontSize: 20,
      margin: 0,
      padding: 0,
      color: '#3A3E4B'
    },

    personStyle: {
      height: 50,
      color: 'black',
      backgroundColor: 'white',
      margin: 5,
      borderRadius: 10,
      padding: 2,

      '& p': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        margin: 0,
        padding: 0,
        width: '100%',
        textAlign: 'center'
      }
    },
    backButton: {
      position: 'absolute',
      left: theme.spacing(1),
      top: 8,
      padding: '4px',

      '& svg': {
        width: '1em',
        height: '1em',
      }
    },
    btnNext: {
      // backgroundColor: '#F46B2A',
      marginRight: 5,
      marginLeft: 5,
      color: '#F46B2A',
      borderRadius: 8,
      height: 45,
      border: `1px solid #F46B2A`,

      '&:hover': {
        backgroundColor: '#F46B2A',
        color: '#FFF',
      },

    },
    buttonCreate: {
      color: 'white',
      backgroundColor: '#f86f46',

      '&:hover' : {
          backgroundColor: '#f86f46',
      }
    },
    scrollCustom: {

      background: '#fff',
      overflowY: 'scroll',
  
      '&::-webkit-scrollbar': {
          backgroundColor: '#fff',
          width: 12,     /* Tamaño del scroll en vertical */
          height: 12,    /* Tamaño del scroll en horizontal */
          // display: 'none',  /* Ocultar scroll */
      },
  
      /* background of the scrollbar except button or resizer */
      '&::-webkit-scrollbar-track': { backgroundColor: '#fff' },
  
      '&::-webkit-scrollbar-track:hover': { backgroundColor: '#f4f4f4' },
  
      /* scrollbar itself */
      '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#babac0',
          borderRadius: 16,
          border: '4px solid #fff',
      },
      '&::-webkit-scrollbar-thumb:hover': {
          backgroundColor: '#a0a0a5',
          border: '4px solid #f4f4f4'
      },
  
      /* set button(top and bottom of the scrollbar) */
      '&::-webkit-scrollbar-button': { display: 'none' },
  }


  })
);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function (props) {

  const { visible, toggle, disabledButtomGroup, back, userSelectOne, formGroup, formGroupError, stateExist, onChangeTextGroup, createMerchantAccount } = props;
  // ----------------------------------------------------------
  const { conversations, selectedUserId, users } = useContext(MessagingContext);
  // ----------------------------------------------------------
  const classes = useStyles();
  let inputEl = createRef();
  // ----------------------------------------------------------
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const smScreen = useMediaQuery("(max-width:600px)");
  const mdScreen = useMediaQuery("(max-width:960px)");
  const lgScreen = useMediaQuery('(max-width:1280px)');
  const xlScreen = useMediaQuery("(max-width:1920px)");
  // ----------------------------------------------------------

  return (

    <Dialog
      fullScreen={fullScreen}
      open={visible}
      keepMounted
      // onClose={toggle}
      className={classes.dialog}
      // disableBackdropClick
      // disableEscapeKeyDown
      aria-labelledby="responsive-dialog-title"
      TransitionComponent={Transition}
    >
      <DialogTitle id="responsive-dialog-title" className={classes.typeModal}>
        <IconButton className={classes.backButton} onClick={back}>
          <ArrowBackIosRoundedIcon style={{ color: 'white' }} />
        </IconButton>
        <p style={{ margin: 0, fontWeight: !isEmpty(userSelectOne) ? 'normal' : 'bold' }}><span style={{ fontWeight: 'bold' }}>{!isEmpty(userSelectOne) ? userSelectOne.name : ''}</span> Conversation</p>
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={toggle}
          className={classes.closeButton}
        >
          <HighlightOffIcon />
        </IconButton>

      </DialogTitle>
      <DialogContent ref={(e) => (inputEl = e)} className={clsx(classes.scrollCustom, '')}>
        {/* <DialogContentText> </DialogContentText> */}
        <div className={classes.boxTitle}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={check} style={{ width: 60, height: 60 }} />
            <div style={{ marginLeft: 8 }}>
              <div className={classes.textSub}>NEW GROUP</div>
              <p className={classes.textTitle}>Name the new group</p>
            </div>
          </div>
        </div>
        <div style={{ width: '100%' }}>

          <div className={classes.boxTextField}>
            <CustomTextField
              // label="Write a name"
              // style={{ backgroundColor: 'white'}}
              placeholder="Write a name"
              variant="outlined" // outlined, filled, 
              className={classes.customTextField}
              mt={10}
              mb={10}
              mr={5}
              name="name"
              value={formGroup.name}
              error={Boolean((formGroupError.name) || stateExist)}
              helperText={   formGroupError.name ? "required" : stateExist ? 'This group already exists' : null} 

              // required={true}
              onChangeText={onChangeTextGroup}
            />
          </div>
          <div style={{ width: '100%', textAlign: 'right' }}>
            <Button
              onClick={createMerchantAccount}
              color="secondary"
              variant="contained" // contained, outlined
              className={classes.buttonCreate}
              style={{ width: 'auto' }}
              disabled={disabledButtomGroup}
            >
              Create
            </Button>
          </div>

        </div>

      </DialogContent>
      {/* <DialogActions className={classes.btnAction}></DialogActions> */}
    </Dialog>

  );
}

