import React, { useState, useEffect, useContext, useRef } from "react";
import getOfficesMeta from "../firebase/httpsCallable/getOfficesMeta";

export default function(props) {
    const [offices, setOffices] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [initializing, setInitializing] = useState(true);
    const destroyed = useRef(false);

    const getOffices = () => {
        setError(null);
        setLoading(true);
        setOffices([]);
        getOfficesMeta()
            .then(result => {
                // if (!destroyed.current) {
                    setLoading(false);
                    const officesMeta = result.data;
                    const formattedOffices = Object.keys(officesMeta).map(
                        officeId => {
                            return { ...officesMeta[officeId], officeId };
                        }
                    );
                    setOffices(formattedOffices);
                // }
            })
            .catch(err => {
                // if (!destroyed.current) {
                    setOffices([]);
                    setLoading(false);
                    setError(err.message);
                    console.error(err);
                // }
            });
    };

    return {
        offices,
        loading,
        error,
        getOffices,
        initializing,
        setInitializing
    };
}
