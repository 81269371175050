import React, { useState, useRef, useEffect } from "react";

export default function() {
    const [ticks, setTicks] = useState(0);
    const ticksRef = useRef(0);
    const tickerIntervalRef = useRef(null);

    useEffect(() => {
        tickerIntervalRef.current = setInterval(() => {
            ticksRef.current = ticksRef.current + 1;
            setTicks(ticksRef.current);
        }, 1000);
        return () => {
            clearInterval(tickerIntervalRef.current);
        };
    }, []);

    return { ticks };
}
