import React, { useContext, useState } from "react";
import { ButtonBase, Paper } from "@material-ui/core";
import GlobalContext from "../../../../hooks/GlobalContext";

import updatePeople from "../../../../firebase/httpsCallable/updatePeople";

export default function(props) {
    const person = props.person;
    const { mainCtx, pagingState, localStore } = useContext(GlobalContext);
    const { officeId } = mainCtx;
    const { selectedPagingPersonId, setSelectedPagingPersonId } = pagingState;
    const isSelected = selectedPagingPersonId === person.personId;

    const [applyStyle, setApplyStyle] = useState(false); 

    const personStyle = (() => {
        let style = {
            height: 48,
            width: '22%',
            // width: 100,
            border: applyStyle ? `3px solid ${person.bgColor}` :  `2px solid ${person.bgColor}`,
            color: isSelected ? 'white' : '#696F84',
            backgroundColor: isSelected ? person.bgColor : 'white',
            marginTop: 5,
            borderRadius: 10,
            marginLeft: '2%',
            padding: 2,
            fontSize: 12
        };

        return style;
    })();

    const handleUpdatePerson = (item) => {
        const body ={
            officeId,
            userId: item.personId,
            bgColor: item.bgColor,
            name: item.name,
            phoneNumber: item.phoneNumber,
            soundId: item.soundId,
            textColor: item.textColor,
            textOnPage: item.textOnPage,
            updatedAt: '',
            rooms: {},
            initials: item.initials ? item.initials : item.name.slice(0, 2),
            lastName: item.lastName,
            type: item.type
            
        }
        updatePeople(body).then(result => {
            setSelectedPagingPersonId(person.personId);
        }).catch(error => {
            console.error('Error updatePeople:', error);
        });
    }

    return (
        <ButtonBase
            onClick={() => {
                if (person.personId === selectedPagingPersonId) {
                    setSelectedPagingPersonId(null);
                } else {
                    if(person.hasOwnProperty('rooms') ){
                        setSelectedPagingPersonId(person.personId);
                    }else{
                        handleUpdatePerson(person)
                    }
                }
            }}
            component={Paper}
            style={personStyle}
            onMouseLeave={() => setApplyStyle(false)} 
            onMouseEnter ={() => setApplyStyle(true)}
        >
            <p style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                margin: 0,
                padding: 0
            }}>{person.name}</p>
        </ButtonBase>
    );
}