import React, { useContext, useState } from "react";
import { isEmpty, isNil, view } from 'ramda';
import moment from "moment";
// import { format, parseISO } from 'date-fns';

import { Tooltip } from "@material-ui/core";

import GlobalContext from "../../../../../hooks/GlobalContext";
import { VisitContext } from "../../VisitContext";
import PatientTrackingContext from "../../PatientTrackingContext";

export default function (props) {
    const { textComponent, style, roomIds } = props;
    // ----------------------------------------------------------
    const { mainCtx } = useContext(GlobalContext);
    const visit = useContext(VisitContext);
    const { officeId } = mainCtx;
    const { rooms, stages } = useContext(PatientTrackingContext);
    // ----------------------------------------------------------
    const timeArriString = moment(visit.arrivalTime).format("hh:mm");
    const timeAppoString = moment(visit.appointmentTime).format("hh:mm");
    // ----------------------------------------------------------
    // ----------------------------------------------------------
    const existingRoom = rooms.find(t => t.id === `${roomIds}`);
    const roomName = existingRoom ? existingRoom.name : "";
    // ----------------------------------------------------------

    return (
        <React.Fragment>
            <div
                style={style}
            >
                {textComponent === 'Name' ?

                    <div style={{ width: '100%', textAlign: 'left', marginLeft: 9 }} >
                        <Tooltip
                            disabled
                            disableFocusListener
                            key={visit.id}
                            title={`${visit.name} ${visit.hasOwnProperty('lastName') ? visit.lastName : '??' }`}
                        >
                            <div
                                style={{
                                    backgroundColor: 'transparent',
                                    fontSize: 12,
                                    width: '100%',
                                    height: 20,
                                    fontWeight: 700,
                                    color: '#197fe8',

                                    overflowX: "hidden",
                                    whiteSpace: "nowrap",
                                    cursor: "pointer",
                                    textOverflow: "ellipsis",
                                    userSelect: "none",
                                }}
                            >
                                {visit.name.slice(0, 2)} {visit.hasOwnProperty('lastName') ? visit.lastName : '??' }
                            </div>
                        </Tooltip>

                        <Tooltip
                            disabled
                            disableFocusListener
                            key={roomName}
                            title={roomName}
                        >
                            <div style={{
                                backgroundColor: 'transparent',
                                fontSize: 12,
                                width: '100%',
                                height: 20,
                                fontWeight: 700,
                                color: '#197fe8',

                                overflowX: "hidden",
                                whiteSpace: "nowrap",
                                cursor: "pointer",
                                textOverflow: "ellipsis",
                                userSelect: "none",
                            }}>
                                {roomName.toString()}
                            </div>
                        </Tooltip>
                    </div>
                    : textComponent === 'ArrivalTime' ? timeArriString : timeAppoString}
            </div>

        </React.Fragment>
    );
}
