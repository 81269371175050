import React, { createRef, useEffect, useState, useContext } from 'react';
import Select2 from 'react-select';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';
import { isEmpty, isNil, view, update } from 'ramda';
import clsx from 'clsx';
import MomentUtils from "@date-io/moment";
import esLocale from "date-fns/locale/es";
import DateFnsUtils from '@date-io/date-fns';

import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import {
    IconButton, useMediaQuery, Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions, Paper, Slide, ButtonBase, Button,
    Card,
    Tooltip
} from '@material-ui/core';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import GroupIcon from '@material-ui/icons/Group';
import PersonIcon from '@material-ui/icons/Person';

import GlobalContext from "../../../../hooks/GlobalContext";
import MessagingContext from "../MessagingContext";
import PersonConversation from "./PersonConversation";


const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        dialog: {
            '& .MuiDialog-container .MuiDialog-paperWidthSm': {
                borderRadius: 20,
                // overflowY: 'auto',
                // overflowX: 'hidden',
                maxWidth: 544,
                width: '100%',
                background: '#fff',
                maxHeight: '80%',
                minHeight: '70%',
                // padding: '2.5%',
            },
            '& .MuiDialog-container .MuiDialog-paperWidthSm .MuiDialogContent-root': {
                overflowY: 'auto',
                overflowX: 'hidden',
            },
        },
        typeModal: {
            background: '#F86F46',
            color: '#fff',
            padding: '7px 24px',
            textAlign: 'center'
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(0),
            padding: '4px',

            '& svg': {
                width: '1.5em',
                height: '1.5em',
            }
        },
        text: {
            margin: 0,
            padding: 0,
            color: '#030303',
            textAlign: 'left',
            fontSize: 12,
        },
        btnAction: {
            padding: '8px 24px',
            border: '1px solid #cecaca',

            '& button': {

            },
            '& .btnSave': {
                background: '#F86F46',
                color: '#fff',
            }
        },

        boxTitle: {
            display: 'flex',
            width: '98%',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 20,
            marginBottom: 10,
        },
        textSub: {
            fontWeight: 400,
            fontSize: 12,
            margin: 0,
            padding: 0,
            color: '#3A3E4B'
        },
        textTitle: {
            fontWeight: 400,
            fontSize: 20,
            margin: 0,
            padding: 0,
            color: '#3A3E4B'
        },


        boxConversation: {
            width: '100%',
            background: '#F7F7FB',
            padding: '1.5% 0',
            flexWrap: 'wrap',
            justifyContent: 'center',
        },
        boxCard: {
            cursor: "pointer",
            padding: 13,
            display: 'flex',
            margin: '6px auto',
            border: '1px solid #DDE6EA',
            borderRadius: 8,
        },
        boxNot: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '1%',
            '& p': {
                margin: 0,
                padding: 0,
                fontWeight: 'bold',
                color: '#3A3E4B',
                fontSize: 19,
            },
        },

        scrollCustom: {

            background: '#fff',
            overflowY: 'scroll',
    
            '&::-webkit-scrollbar': {
                backgroundColor: '#fff',
                width: 12,     /* Tamaño del scroll en vertical */
                height: 12,    /* Tamaño del scroll en horizontal */
                // display: 'none',  /* Ocultar scroll */
            },
    
            /* background of the scrollbar except button or resizer */
            '&::-webkit-scrollbar-track': { backgroundColor: '#fff' },
    
            '&::-webkit-scrollbar-track:hover': { backgroundColor: '#f4f4f4' },
    
            /* scrollbar itself */
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#babac0',
                borderRadius: 16,
                border: '4px solid #fff',
            },
            '&::-webkit-scrollbar-thumb:hover': {
                backgroundColor: '#a0a0a5',
                border: '4px solid #f4f4f4'
            },
    
            /* set button(top and bottom of the scrollbar) */
            '&::-webkit-scrollbar-button': { display: 'none' },
        }

    })
);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function (props) {

    const { visible, toggle, back, userSelectOne, handleSelectedConversationGroup, handleSelectedConversationPerson } = props;
    // ----------------------------------------------------------
    const { localStore } = useContext(GlobalContext);
    const messaging = useContext(MessagingContext);
    const { conversations, conversationQueryListener, selectedUserId, users } = useContext(MessagingContext);
    // ----------------------------------------------------------
    const classes = useStyles();
    let inputEl = createRef();
    // ----------------------------------------------------------
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const smScreen = useMediaQuery("(max-width:600px)");
    const mdScreen = useMediaQuery("(max-width:960px)");
    const lgScreen = useMediaQuery('(max-width:1280px)');
    const xlScreen = useMediaQuery("(max-width:1920px)");
    // ----------------------------------------------------------
      // ----------------------------------------------------------
    useEffect(() => {
        // clear the chat user data when launching the app
        localStore.update(
            localStore.storageKeys.selectedMessagingChat,
            {}
        );
    }, []);
  // ----------------------------------------------------------

    return (

        <Dialog
            fullScreen={fullScreen}
            open={visible}
            keepMounted
            // onClose={toggle}
            className={clsx(classes.dialog, '')}
            // disableBackdropClick
            // disableEscapeKeyDown
            aria-labelledby="responsive-dialog-title"
            TransitionComponent={Transition}
        >
            <DialogTitle id="responsive-dialog-title" className={classes.typeModal}>
                <p style={{ margin: 0, fontWeight: !isEmpty(userSelectOne) ? 'normal' : 'bold' }}><span style={{ fontWeight: 'bold' }}>{!isEmpty(userSelectOne) ? userSelectOne.name : ''}</span> Conversation</p>
                <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    onClick={toggle}
                    className={classes.closeButton}
                >
                    <HighlightOffIcon />
                </IconButton>

            </DialogTitle>
            <DialogContent ref={(e) => (inputEl = e)} style={{ padding: 0 }} className={clsx(classes.scrollCustom, '')}>
                {/* <DialogContentText> </DialogContentText> */}

                <div className={classes.boxConversation}>

                    {conversationQueryListener.loading && `LOADING...`}
                    {conversations.length > 0 ? conversations
                        .sort((a, b) => {
                            const atime = parseInt(a.latestMessage.time);
                            const btime = parseInt(b.latestMessage.time);
                            return btime - atime;
                        })
                        .map((item, index) => {

                            const new_conversation = {
                                ...item,
                                userIds: item.userIds.filter(userId => { return userId !== messaging.selectedUserId })
                            }

                            // ----------------------------------------------------------------
                            const latesMessageTime = item.latestMessage.time;
                            const hasUnread = (() => {
                                const lastReadTime = item.participants[messaging.selectedUserId].lastMessage.time;
                                if (lastReadTime < latesMessageTime) {
                                    return true;
                                }
                                return false;
                            })();
                            // ----------------------------------------------------------------
                            return (
                                <Card
                                    key={index}
                                    style={{ backgroundColor: '#ffffff', width: smScreen ? '90%' : '90%', }}
                                    className={classes.boxCard}
                                    onClick={ new_conversation.name || new_conversation.userIds.length > 1 ? handleSelectedConversationGroup(new_conversation) : handleSelectedConversationPerson(new_conversation) }
                                >

                                    {new_conversation.name || new_conversation.userIds.length > 1 ? (
                                        <GroupIcon style={{ color: '#aeb1bc' }} />
                                    ) : (
                                        <PersonIcon style={{ color: '#aeb1bc' }} />
                                    )}
                                    &nbsp;

                                    <div style={{ display: 'flex', width: '90%' }}>
                                        {new_conversation.name || new_conversation.userIds.length > 2 ? (
                                            <div style={{ width: '30%' }}>
                                                <Tooltip
                                                 key={index}
                                                 title={new_conversation.name ? new_conversation.name : 'Groups'}>
                                                    <p style={{
                                                        // width: '100%',
                                                        padding: 0,
                                                        margin: 0,
                                                        color: "#3A3E4B",
                                                        textTransform: 'capitalize',
                                                        fontWeight: '700',
                                                        fontSize: 15,
                                                        marginRight: 8,
                                                        overflowX: 'hidden',
                                                        whiteSpace: 'nowrap',
                                                        textOverflow: 'ellipsis',
                                                    }}>
                                                        {new_conversation.name ? new_conversation.name : 'Groups'}
                                                    </p>
                                                </Tooltip>
                                            </div>
                                        ) : null}
                                        <PersonConversation
                                            participantIds={new_conversation.userIds.filter(
                                                userId => {
                                                    return userId !== messaging.selectedUserId;
                                                }
                                            )}
                                            index={props.index}
                                            color='#3A3E4B'
                                            dynamicType={new_conversation.name || new_conversation.userIds.length > 2 ? 'group' : 'individual'}
                                        />
                                    </div>
                                    {hasUnread ?
                                        <FiberManualRecordIcon style={{ color: '#f86f46', height: '0.8em', width: '0.8em' }} />
                                        : null}
                                </Card>
                            );
                        }) :
                        <div className={classes.boxNot}>
                            <p className='p-sub'>No conversations at this time</p>
                        </div>
                    }

                </div>

            </DialogContent>
            {/* <DialogActions className={classes.btnAction}></DialogActions> */}
        </Dialog>

    );
}
