import React, { useContext } from "react";
import Room from "./Room/Room";

import GlobalContext from "../../../../hooks/GlobalContext";
import { Resizable } from "re-resizable";
export default function (props) {
    const rooms = props.rooms;
    const { localStore } = useContext(GlobalContext);
    return (
        <Resizable
            style={{
                display: "flex",
                backgroundColor: '#F3F5FA',
                borderRadius: 7,
                padding: 10,
                justifyContent: 'flex-start',
            }}
            enable={{ bottom: true }}
            size={{ height: '50%', width: '98%' }}
            onResizeStop={(event, direction, refToElement, delta) => {
                const newHeight = parseInt(refToElement.style.height);
                localStore.update(
                    localStore.storageKeys.roomsHeight,
                    newHeight
                );
            }}
        >
            <div
                style={{
                    display: "grid",
                    gridTemplateColumns: `repeat(2, minmax(0,1fr))`,
                    gridAutoRows: "1fr",
                    gridGap: "1",
                    overflowY: "auto",
                    overflowX: "hidden",
                    width: "100%"
                }}
            >
                {getSortedRoomIds(rooms).map(e => {
                    return (
                        <Room
                            key={e.id}
                            room={{ ...e, roomId: e.id }}
                            closePaging={props.closePaging}
                        />
                    );
                })}
            </div>
        </Resizable>
    );
}

function getSortedRoomIds(rooms) {
    const sorted = rooms
        // return Object.keys(rooms)
        //     .filter(roomId => {
        //         return Object.keys(rooms).includes(roomId);
        //     })
        .sort((a, b) => {
            if (a.name > b.name) {
                return 1;
            }
            if (a.name < b.name) {
                return -1;
            }
            return 0;
        })
        .sort((a, b) => {
            const roomA = { ...a };
            const roomB = { ...b };
            roomA.listPosition = roomA.listPosition ? roomA.listPosition : 0;
            roomB.listPosition = roomB.listPosition ? roomB.listPosition : 0;

            return roomA.listPosition - roomB.listPosition;
        });
    return sorted;
}
