import React, { useContext, useState, useEffect } from "react";
import { isEmpty, isNil, view } from 'ramda';

import GlobalContext from "../../../../../hooks/GlobalContext";
import PatientTrackingContext from "../../PatientTrackingContext";
import { VisitContext } from "../../VisitContext";

export default function (props) {
    const { roomIds, stageIds, style, textComponent } = props;
    // ----------------------------------------------------------
    const { mainCtx } = useContext(GlobalContext);
    const { rooms, stages } = useContext(PatientTrackingContext);
    const { officeId } = mainCtx;
    const visit = useContext(VisitContext);
    // ----------------------------------------------------------
    const existingRoom = rooms[roomIds];
    const roomName = existingRoom ? existingRoom.name : "??";
    // ----------------------------------------------------------
    const existingStage = stages[stageIds];
    const stageName = existingStage ? existingStage.name : "??";
    // ----------------------------------------------------------
    
    return (
        <React.Fragment>
            <div style={style}>
                {textComponent === 'Room' ? roomName.toString() : stageName.toString()}
            </div>
        </React.Fragment>
    );
}
